"use client";
import React from "react";
import Autoplay from "embla-carousel-autoplay";
import { MoveRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Separator } from "../../ui/Separator";
import { Carousel, CarouselContent, CarouselItem } from "../../ui/carousel";

const Competition = () => {
  const navigate = useNavigate();

  const images = [
    {
      img: "/Imagefolder/compe/fll-map.png",
    },
    {
      img: "/Imagefolder/compe/fll-bot.png",
    },
    {
      img: "/Imagefolder/compe/fll-lego.png",
    },
    {
      img: "/Imagefolder/compe/fll-pulley.png",
    },
    {
      img: "/Imagefolder/compe/vex-1.png",
    },
    {
      img: "/Imagefolder/compe/vex-2.png",
    },
    {
      img: "/Imagefolder/compe/vex-3.png",
    },
    {
      img: "/Imagefolder/compe/vex-4.png",
    },
  ];

  const points = [
    {
      title:
        "FIRST® LEGO® League Explore is an educational program for children aged 6-10.",
    },
    {
      title: "The program focuses on engineering, problem-solving, and coding.",
    },
    {
      title:
        "Teams of up to six children work together to complete a challenge based on a real-world topic.",
    },
    {
      title:
        "The program provides opportunities to develop STEM skills, teamwork, self-confidence, and presentation skills.",
    },
    {
      title: "The program includes 12 sessions and requires a meeting place.",
    },
    {
      title:
        "The program utilizes LEGO® Education WeDo 2.0, which is reusable, and an annual Inspire Set.",
    },
  ];

  const desc1 = [
    {
      points:
        "Teams can register to participate in multiple events for year-round learning and fun",
    },
    {
      points:
        "VEX IQ offers free and affordable programming software options, including Modkit for VEX and ROBOTIC for VEX Robotics, that are easy to learn",
    },
    {
      points:
        "Students can design virtual VEX IQ robots using free computer-aided design (CAD) software and create printable, shareable instructions for them",
    },
    {
      points:
        "With VEX IQ, students can develop valuable knowledge, skills, and experience while building and programming robots",
    },
    {
      points:
        "VEX IQ is Robotics Engineering Notebook documents the robot design process, providing an added level of learning and engagement",
    },
  ];

  const desc2 = [
    {
      points:
        "VEX IQ allows teams to solve exciting robot challenges using simple, flexible, and powerful tools",
    },
    {
      points:
        "Teams can easily drive and pre-program their robots using computer software",
    },
    {
      points:
        "Teams can collaborate to score points in Teamwork Matches and showcase their skills in individual Challenges",
    },
    {
      points:
        "VEX IQ offers a snap-together game field that can be assembled in minutes and re-used for all robot game challenges",
    },
    {
      points:
        "VEX IQ is tool-less robot kit options start at just $249.99 and come with a storage bin and tray included, and can be used for multiple seasons. The child also gets the chance to participate in the VEX IQ Competition.",
    },
    {
      points:
        "Competition fees for the  participants Registration costs $100 for the first team and $50 for additional teams in the same school or organization, and there’s no limit to the number of team members",
    },
  ];
  return (
    <React.Fragment>
    <main className="gap-12 grid lg:max-w-screen-2xl mx-auto p-6 bg-gray-50">
      {/* Section: Title */}
      <section className="text-center">
        <h1 className="text-center text-3xl md:text-4xl my-5 font-bold  text-secondary uppercase">Competitions</h1>
        <p className="text-lg text-gray-600">Join our exciting robotics competitions designed for different age groups and skill levels.</p>
      </section>

      {/* Section: Carousel */}
      <section className="relative   rounded-lg">
        <Carousel
        opts={{
          loop: true,
        }}
          plugins={[Autoplay({ delay: 3000 })]}
          className="rounded-lg overflow-hidden "
        >
          <CarouselContent>
            {images.map((item, index) => (
              <CarouselItem key={index} className="transition-transform md:basis-1/2 lg:basis-1/3 duration-700 ease-in-out">
                <img
                  className="w-full rounded-xl h-[30rem] object-cover"
                  src={item.img}
                  alt=""
                />
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
        <button className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full shadow-lg hover:bg-gray-600 transition-all">
          &#8249;
        </button>
        <button className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-800 text-white p-3 rounded-full shadow-lg hover:bg-gray-600 transition-all">
          &#8250;
        </button>
      </section>

      {/* Section: First Lego League */}
      <section className="grid grid-cols-1 lg:grid-cols-2 gap-10">
        <div className="flex justify-center items-center">
          <img
            className="max-w-xs rounded-lg shadow-lg"
            src="/Imagefolder/compe/FLL_Challenge.png"
            alt="First Lego League Logo"
          />
        </div>
        <div className="">
          <h2 className="text-3xl font-bold text-gray-800 mb-4 text-center text-primary "> First Lego League</h2>
          <Separator className="bg-secondary w-24 h-1 mx-auto " />
          <div className="bg-white p-6 rounded-lg ">
            <h3 className="text-xl font-semibold text-gray-700 text-tertiary mb-4">Important Points:</h3>
            <ul className="list-disc space-y-2 pl-6 text-gray-600">
              {points.map((item, index) => (
                <li key={index} className="leading-relaxed">
                  {item.title}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      <section className="grid grid-cols-1 lg:grid-cols-2 gap-10">
        <div>
          <h2 className="text-3xl font-bold text-gray-800 mb-4 text-center text-primary"> VEX IQ</h2>
          <Separator className="bg-secondary w-24 h-1 mx-auto " />
          <div className="bg-white p-6 rounded-lg">
            <ul className="list-disc space-y-2 pl-6 text-gray-600">
              {desc1.map((item, index) => (
                <li key={index} className="leading-relaxed">
                  {item.points}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <img
            className="max-w-xs h-full "
            src="/Imagefolder/compe/vex-logo.png"
            alt="VEX IQ Logo"
          />
        </div>
      </section>
      {/* Section: World Robot Olympiad */}
      <section className="grid grid-cols-1 lg:grid-cols-2 gap-10">
        <div className="flex justify-center items-center">
          <img
            className="max-w-md h-80 object-fit"
            src="/Imagefolder/compe/wro.png"
            alt="World Robot Olympiad"
          />
        </div>
        <div className=" p-5 rounded-xl">
        <h2 className="text-3xl font-bold text-gray-800 mb-4 text-center text-primary "> WRO</h2>
        <Separator className="bg-secondary w-24 h-1 my-5 mx-auto " />
          <p className="text-gray-700 leading-relaxed tracking-wide text-center">
            For the year 2023, the focus of WRO® will be on connecting the world. Teams will delve into the realms of shipping logistics and digital technology infrastructure to explore the potential of robots in enhancing safety, sustainability, and efficiency. Through this theme, participants will gain knowledge on how to bridge the gap between various domains and establish stronger connections.
          </p>
        </div>
      </section>
      <section className="my-10">
        <h2 className="text-center text-3xl text-primary font-bold mb-6 text-gray-800">
          Participate in World Robot Olympiad
        </h2>
        <Separator className="bg-secondary w-40 h-1 my-2 mx-auto " />
        <div className="rounded-lg overflow-hidden shadow-md shadow-tertiary p-5">
          <iframe
            className="w-full "
            height="500"
            src="https://www.youtube.com/embed/BnZ7pmDCBDs"
            title="WRO 2023 Theme | Connecting the World"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        </div>
      </section>
      <div
            onClick={() => navigate("/contact")}
            className="flex justify-center  items-center text-primary hover:text-secondary  cursor-pointer transition-all"
          >
            <h1 className="font-bold text-xl md:text-2xl">To Enroll Contact at</h1>
            <MoveRight size={30} className="ml-3" />
          </div>
    </main>
  </React.Fragment>
  );
};

export default Competition;