import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../../ui/form";
import AddTags from "./Addtags";
import { Input } from "../../../../../ui/Input";
import PopUp from "../../../../../Pages/PopUp";
import { Button } from "../../../../../ui/button";
import BASE_URL from "../../../../../../config/page";

const FormSchema = z.object({
  title: z.string().min(2, {
    message: "Title must be at least 2 characters.",
  }),
  publishedDate: z
  .string()
  .refine((value) => {
    const date = new Date(value);
    return !isNaN(date.getTime()); 
  }, {
    message: "A valid date of birth is required.",
  }),
  author: z.string().min(2, {
    message: "Author must be at least 2 characters.",
  }),
  category: z.string().min(2, {
    message: "Category must be at least 2 characters.",
  }),
  image: z.string().min(2, {
    message: "Image must be required .",
  }),
  sections: z.object({
    heading: z.string().min(2, {
      message: "Nested heading must be at least 2 characters.",
    }),
    content: z.string().min(2, {
      message: "Nested content must be at least 2 characters.",
    }),
  }),
});

type Props = {
  success: Boolean;
  fail: Boolean;
};

const AddBlogLayout = () => {
  const [Tags, setTags] = React.useState<string[]>([]);
  const [pop, setPop] = React.useState<Props>({
    success: false,
    fail: false,
  });
  
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      title: "",
      publishedDate: new Date().toISOString().split("T")[0],
      author: "",
      category: "",
      image: "",
      sections: {
        heading: "",
        content: ""
      },
    },
  })

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      const payload = {
        Tags,
        ...data
      }
      try {
        const res = await fetch(`${BASE_URL}/blog/add`, {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify(payload),
        })
        const data = await res.json()
        if (data.success === true) {
          form.reset();
          setPop({ success: true, fail: false });
        }
        return data
      } catch (error) {
        throw new Error("error fetching data")
      }
    } catch (error) {
    }
  }

  return (
    <React.Fragment>
      {pop.success && (
        <PopUp heading="Success" description="Blog Added Successfully" />
      )}
      {pop.fail && <PopUp heading="Error" description="Error Adding Blog" />}
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="bg-lightblue grid place-content-center md:block p-5 space-y-5">
            <div className="grid md:grid-cols-2 ">
            <div className="grid space-y-5 place-content-center lg:block">
              <FormField
                control={form.control}
                name="publishedDate"
                render={({ field }) => (
                  <FormItem className="grid grid-cols-1">
                    <FormLabel>Published Date</FormLabel>
                        <FormControl>
                        <Input
                      className="grid gap-10"
                      type="date"
                      placeholder="Published Date"
                      onChange={(e) => {
                        field.onChange(e.target.value); 
                      }}
                    />
                        </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Title</FormLabel>
                    <FormControl>
                      <Input className="w-64 md:w-full" placeholder="Enter Title " {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="author"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>author</FormLabel>
                    <FormControl>
                      <Input className="w-64 md:w-full" placeholder="Enter Author" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="category"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>category</FormLabel>
                    <FormControl>
                      <Input className="w-64 md:w-full" placeholder="Enter Category" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="sections.heading"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>heading</FormLabel>
                    <FormControl>
                      <Input className="w-64 md:w-full" placeholder="Enter Heading " {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="sections.content"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>content</FormLabel>
                    <FormControl>
                      <Input className="w-64 md:w-full" placeholder="Enter Content" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="image"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>image</FormLabel>
                    <FormControl>
                      <Input type="file" className="w-64 md:w-full" placeholder="Enter " {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="py-10 w-full">
                <Button className="w-full hidden md:block" type="submit" variant="outline">
                  Submit
                </Button>
              </div>
            </div>
            <div className="p-5">
              <AddTags Tags={Tags} setTags={setTags} />
            </div>
          </div>
          <div className="grid place-content-center">
            <Button className="w-32 md:hidden" type="submit" variant="outline">
              Submit
            </Button>
          </div>
        </form>
      </Form>
    </React.Fragment>
  );
};

export default AddBlogLayout;
