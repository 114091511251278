import { Separator } from "@radix-ui/react-select";
import * as  React from "react";

const ImpactProgram = () => {
  return (
    <React.Fragment>
      <main>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 font-sans">
          {/* Image Section */}
          <div className="flex items-center justify-center">
            <img
              className="rounded-xl shadow-lg shadow-primary"
              src="https://res.cloudinary.com/dn49bytnn/image/upload/v1729967164/bzjg8idt8wjsyigclfts.jpg"
              alt="Impact Programs"
            />
          </div>

          {/* Text Section */}
          <div className="gap-y-5 lg:py-20 grid place-items-center md:place-content-start">
            <h1 className="text-primary text-lg md:text-2xl lg:text-3xl text-center font-bold leading-snug font-sans">
              Impact Programs
            </h1>
            <Separator className="bg-secondary w-20 h-1" />
            <h3 className="font-bold text-center">300+ AI and Robotics Labs Established</h3>
            <p className="text-base md:text-center lg:text-lg text-center cursor-not-allowed leading-snug">
              ICE Robotics provides the tech, program management, and execution support to help CSR, NGOs, and governments execute impactful programs at a large scale. Through their successful execution of over 50 projects, ICE Robotics has positively impacted more than 500,000 students.
            </p>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default ImpactProgram;
