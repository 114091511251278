import * as React from "react";

import Autoplay from "embla-carousel-autoplay";
import { ArrowRight } from "lucide-react";
import { Carousel, CarouselContent, CarouselItem } from "../../ui/carousel";

const Events = () => {
  const data = [
    {
      img: "./Imagefolder/NewImages/carparts.jpg",
      heading: "Kids @ BrightCHAMPS",
      detail_descrip:
        "Students had a hands-on experience building and learning with robotics kits at BrightCHAMPS, offering personalized sessions for young learners.",
    },
    {
      img: "./Imagefolder/NewImages/girlholdingrobo.jpg",
      heading: "Exploring Robotics & Coding",
      detail_descrip:
        "Over 200 teachers have learned the basics of robotics, coding, and hands-on STEM skills through specialized workshops.",
    },
    {
      img: "./Imagefolder/NewImages/iceroboapp.jpg",
      heading: "Teacher Development",
      detail_descrip:
        "STEM Partners conduct physical development programs, helping teachers build skills in innovative teaching methods.",
    },
    {
      img: "./Imagefolder/NewImages/kidroboarm (1).jpg",
      heading: "Tech Champions",
      detail_descrip:
        "Our programs inspire students to embrace robotics and coding, making learning fun and engaging.",
    },
    {
      img: "./Imagefolder/NewImages/playingminecraft.jpg",
      heading: "Introducing STEM to Students",
      detail_descrip:
        "Our specially designed STEM classroom kits introduce students to critical thinking, problem-solving, and technical skills through interactive learning.",
    },
    {
      img: "./Imagefolder/NewImages/robo_tyres.jpg",
      heading: "Robotics Classes",
      detail_descrip:
        "Interactive robotics sessions where students build and program robots, developing problem-solving and teamwork skills.",
    },
    {
      img: "./Imagefolder/NewImages/robohead.jpg",
      heading: "STEM Workshop",
      detail_descrip:
        "A hands-on workshop that allows students to explore robotics and engineering concepts through real-world applications, promoting a future-focused education.",
    },
  ];

  return (
    <React.Fragment>
      <div className="space-y-5 grid grid-cols-1 sm:mt-4">
        <div className="lg:mx-auto lg:w-4/5">
          <Carousel
            opts={{ align: "start" }}
            plugins={[
              Autoplay({
                delay: 4000,
              }),
            ]}
          >
            <CarouselContent>
              {data.map(
                (
                  item: {
                    img: string;
                    heading:
                      | string
                      | number
                      | boolean
                      | React.ReactElement<
                          any,
                          string | React.JSXElementConstructor<any>
                        >
                      | Iterable<React.ReactNode>
                      | React.ReactPortal;
                    detail_descrip:
                      | string
                      | number
                      | boolean
                      | React.ReactElement<
                          any,
                          string | React.JSXElementConstructor<any>
                        >
                      | Iterable<React.ReactNode>
                      | React.ReactPortal;
                  },
                  index: React.Key
                ) => (
                  <CarouselItem
                    key={index}
                    className="md:basis-1/3 lg:basis-1/4 w-96 sm:p-6"
                  >
                    <div className="mx-auto md:space-y-5 text-center font-sans">
                      <img
                        className="mx-auto md:w-full lg:h-full h-[20rem] shadow-lg shadow-primary"
                        src={item.img}
                        alt="img"
                      />
                      <h1 className="text-lg font-bold my-5 leading-snug font-sans">
                        {item.heading}
                      </h1>
                      <p className="text-base lg:text-lg leading-snug font-sans line-clamp-3 hover:line-clamp-none">
                        {item.detail_descrip}
                      </p>
                    </div>
                    <section className="text-sm p-2 px-4 text-gray_900/60 flex justify-center items-center block sm:hidden">
                      <span>Swipe</span>
                      <ArrowRight size={16} className="text-gray_900/40" />
                    </section>
                  </CarouselItem>
                )
              )}
            </CarouselContent>
          </Carousel>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Events;
