import * as React from "react";
const OurRobots = () => {
  return (
    <React.Fragment>
      <main className="grid p-5 lg:max-w-screen-2xl  mx-auto gap-5">
        <div className="grid grid-cols-1 md:grid-cols-12 mx-auto col-span-12 gap-4">
          <img
            className="h-[12rem] lg:h-[18rem] mx-auto lg:p-3 hover:transform hover:transition-transform hover:scale-110 hover:duration-700 md:col-span-4"
            src="/Imagefolder/our-robots.jpg"
            alt="Robots"
          />
          <p className="my-auto text-base lg:text-lg xl:text-xl md:col-span-8">
            Our students receive incredible Robotics training and learn a vast
            majority of Robotics programming languages, courses, and tools like
            JavaScript, Arduino, EV3, Mindstorms, and Mobile App development,
            to name a few. Our latest addition to our Robotics family is the LEGO® Education SPIKE™ Prime Teach, a dynamic, hyper-engaging Robotics Building Kit for students aged 11-14.
          </p>
        </div>
        <div className=" grid-cols-1 md:grid-cols-12 mx-auto col-span-12 gap-4 block md:hidden ">
        <img
            className="h-[12rem] lg:h-[18rem] mx-auto hover:transform hover:transition-transform hover:scale-110 hover:duration-700 md:col-span-4"
            src="/Imagefolder/our-lego.jpg"
            alt="Lego"
          />
          <p className="my-auto lg:p-3 text-base lg:text-lg xl:text-xl md:col-span-8">
            The LEGO® Education SPIKE™ Prime Teach includes 3 motors (1 large
            and 2 medium), 3 sensors (distance, force, color), 1 programmable
            hub with a 5x5 LED light matrix, 6 input/output ports, an integrated
            6-axis gyro sensor, a speaker, Bluetooth connectivity, a 32 MB memory
            to store up to 20 programs, and a rechargeable battery. It comes with
            2 mini-figures and 528 LEGO® Technic™ and System elements in a vibrant
            color palette, all stored in a sturdy storage box with sorting trays.
          </p>
          
        </div>
        <div className="md:grid grid-cols-1 md:grid-cols-12 mx-auto col-span-12 gap-4  hidden">
          <p className="my-auto lg:p-3 text-base lg:text-lg xl:text-xl md:col-span-8">
            The LEGO® Education SPIKE™ Prime Teach includes 3 motors (1 large
            and 2 medium), 3 sensors (distance, force, color), 1 programmable
            hub with a 5x5 LED light matrix, 6 input/output ports, an integrated
            6-axis gyro sensor, a speaker, Bluetooth connectivity, a 32 MB memory
            to store up to 20 programs, and a rechargeable battery. It comes with
            2 mini-figures and 528 LEGO® Technic™ and System elements in a vibrant
            color palette, all stored in a sturdy storage box with sorting trays.
          </p>
          <img
            className="h-[12rem] lg:h-[18rem] hover:transform hover:transition-transform hover:scale-110 hover:duration-700 md:col-span-4"
            src="/Imagefolder/our-lego.jpg"
            alt="Lego"
          />
        </div>
      </main>
    </React.Fragment>
  );
};
export default OurRobots;







