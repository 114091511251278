import React, { useState, useEffect } from 'react';

const PAPDImg = () => {
  const [zoomLevel, setZoomLevel] = useState<number>(1);
  const [offsetX, setOffsetX] = useState<number>(0);
  const [offsetY, setOffsetY] = useState<number>(0);

  const images = [
    { img: "https://res.cloudinary.com/pickup/image/upload/v1721314702/pickupbiz_laundary/iac7t08fgxye3yjqyq9x.jpg" },
    { img: "http://res.cloudinary.com/pickup/image/upload/v1721315588/pickupbiz_laundary/da2aimtrg2dzv7hjo22v.jpg" },
    { img: "http://res.cloudinary.com/pickup/image/upload/v1721315673/pickupbiz_laundary/wj7oftfli3mabdmlqdiz.jpg" },
    { img: "http://res.cloudinary.com/pickup/image/upload/v1721315721/pickupbiz_laundary/hh3mkr8wljaunvltnf2x.jpg" }
  ];

  const [mainImage, setMainImage] = useState(images[0].img);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - left;
    const y = e.clientY - top;
    const offsetX = 0.5 - x / width;
    const offsetY = 0.5 - y / height;
    const scale = 2;
    setZoomLevel(1 + scale * Math.sqrt(offsetX * offsetX + offsetY * offsetY));
    setOffsetX(offsetX);
    setOffsetY(offsetY);
  };

  const handleImageHover = (img: string) => {
    setMainImage(img);
    const index = images.findIndex(image => image.img === img);
    setCurrentIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (currentIndex + 1) % images.length;
      setMainImage(images[nextIndex].img);
      setCurrentIndex(nextIndex);
    }, 2000);

    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <React.Fragment>
      <div style={{ display: 'flex' }}>
        <div
          style={{
            position: 'relative',
            overflow: 'hidden',
            width: '290px',
            height: '290px',
          }}
          onMouseMove={handleMouseMove}
        >
          <img
            src={mainImage}
            alt="Main Image"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              transition: 'transform 0.3s ease-in-out',
              transformOrigin: 'center center',
            }}
          />
        </div>
        <div>
          {images.map((data, index) => (
            <div
              key={index}
              onMouseEnter={() => handleImageHover(data.img)}
              style={{
                padding: '0.25rem',
                cursor: 'pointer',
                transform: mainImage === data.img ? 'scale(0.9)' : 'none',
                transition: 'transform 0.3s ease-in-out, border 0.3s ease-in-out',
              }}
            >
              <img
                src={data.img}
                alt={`Image ${index + 1}`}
                style={{
                  width: '64px', // 16rem converted to px
                  height: '64px',
                  objectFit: 'cover',
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default PAPDImg;
