import React from "react";
import { Card, CardContent } from "../../../ui/Card";

const data = [
  {
    img:"../Images/globe.svg",
    title:"New Age Education",
    para:"Have your children be prepared for a rapidly changing future that traditional education can no longer keep up with. STEM education, of which Robotics is a key component, has assumed increasing importance in the last few years.",
  },
  {
    img: "../Images/globe.svg",
    title: "Our Practical Learning",
    para: "Your children learn by doing, gaining valuable practical skills. Beyond a functional understanding of Robotics, children also learn soft-skills like teamwork, innovative thinking, leadership, problem-solving and more.",
  },
  {
    img: "../Images/globe.svg",
    title: "We're Fun!",
    para: "Engage your children with learning that's also fun. Keep them occupied with a fun-to-learn set of skills and knowledge. Additionally, robotics can encourage kids to think outside the box and come up with original ideas.",
  },
];
const Ice = () => {
  return (
    <React.Fragment>
       <div className="bg-[#12416f] pt-8">
        <img
              src="/Imagefolder/Images/ice_robo.png"
              alt="Ice Robotics"
              className="h-40 mx-auto "
            />
          <h1 className="text-center mt-7 text-3xl font-bold text-white_100">Why Ice Robotics ?</h1>
        </div>
        <main className="lg:grid lg:grid-cols-3 bg-[#12416f] p-8 ">
      {
        data.map((item,index)=>(
      <div className="p-4" key={index}>
        <h1 className=" font-bold md:text-xl text-sm text-primary text-center mb-4">{item.title}</h1>
        <Card className="text-white_100 text-sm  shadow-secondary bg-primary ">
          <CardContent className="p-4 text-center">
          {item.para}
          </CardContent>
         </Card>
      </div>
      ))
      }
      </main>
    </React.Fragment>
  );
};
export default Ice;