import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../../../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../ui/form";
import { Input } from "../../../../ui/Input";
import React from "react";
import actions from "../../../../../actions"

const formSchema = z.object({
    title: z.string().min(2, {
    message: "Topic must be at least 2 characters.",
  }),
});

type AddTopicProps = {
  handleNew: () => void;
};

const AddGalleryTopic = ({ handleNew }: AddTopicProps) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
        title: "",
    },
  });

  async function onSubmit(data: z.infer<typeof formSchema>) {
    try {
      const submit = await actions.POST_DATA({
        url: "gallery/create",
        payload: data,
      }); 
      if(submit.success===true){
        form.reset()
      }
    } catch (error) {
    }
    handleNew();
  }

  return (
    <React.Fragment>
      <main className="grid h-full p-6 sm:p-12 rounded-lg ">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
            <h1 className="text-center xl:text-3xl md:text-2xl text-xl font-bold my-3 xl:my-5">
              Title
            </h1>
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-lg">Add New Title  </FormLabel>
                  <FormControl>
                    <Input placeholder="Add Title" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <section>
              <Button type="submit" className="bg-secondary text-white_100">
                Submit
              </Button>
            </section>
          </form>
        </Form>

      </main>
    </React.Fragment>
  );
};

export default AddGalleryTopic;

