import React from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../ui/Accordion";
import Data from "../../../DB/NavBarData.json";
import { SheetClose } from "../../ui/sheet";
import { Link } from "react-router-dom";

const SideBar = () => {
  return (
    <React.Fragment>
      <main className="grid grid-cols-1 w-fit p-2 mx-auto overflow-y-scroll h-full">
        {Data.map((item, index) => {
          const { name, link, head } = item;
          const sub = head?.map((e) => e);
          const hasSubItems = sub && sub.length > 0;
          return (
            <React.Fragment key={index}>
              {hasSubItems && (
                <Accordion  type="single" collapsible>
                  <AccordionItem  value="index">
                    <AccordionTrigger>
                      <SheetClose >
                        <Link to={item.link} >
                        <h1 className="text-lg sm:text-xl text-tertiary cursor-pointer font-semibold">{name}</h1>
                        </Link>
                      </SheetClose>
                    </AccordionTrigger>
                    <AccordionContent className="bg-white_100/30 px-2 py-0.5 text-start">
                      {sub.map((item, index) => (
                        <div key={index} >
                          <Link to={item.link} >
                            <SheetClose>
                              <h1 className="text-tertiary font-semibold text-lg 2xl:text-xl cursor-pointer text-start">
                                {item.title}
                              </h1>
                            </SheetClose>
                          </Link>
                        </div>
                      ))}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              )}
              {!hasSubItems && (
                <Link to={link}  className="text-start my-3">
                  <SheetClose>
                    <h1 className="text-lg sm:text-xl text-tertiary cursor-pointer font-semibold">{name}</h1>
                  </SheetClose>
                </Link>
              )}
            </React.Fragment>
          );
        })}
      </main>
    </React.Fragment>
  );
};

export default SideBar;
