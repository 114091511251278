import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../ui/form";
import { Input } from "../../../ui/Input";
import React from "react";
import { Card } from "../../../ui/Card";
import actions from "../../../../actions";

const FormSchema = z.object({
  name: z.string().min(2, {
    message: "name must be at least 2 characters.",
  }),
  discount: z.string().min(1, {
    message: "discount must be at least 1 characters.",
  }),
});
type AddCouponProps = {
  handleNew: () => void;
};

const AddCoupons = ({ handleNew }: AddCouponProps) => {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
      discount: "",
    },
  });
  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      const submit = await actions.POST_DATA({
        url: "coupon/add",
        payload: data,
      });
      if (submit.status == 200) {
        form.reset();
      }
    } catch (error) {
    }
    handleNew();
  }
  return (
    <React.Fragment>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
          <Card className="p-5 border-none shadow-md shadow-primary">
            <h1 className="text-center xl:text-3xl md:text-2xl text-xl font-bold my-3 xl:my-5 ">
              Add Coupons
            </h1>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Add Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="discount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Discount</FormLabel>
                  <FormControl>
                    <Input placeholder="Add discount" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" className="my-5 bg-tertiary text-white_100">
              Submit
            </Button>
          </Card>
        </form>
      </Form>
    </React.Fragment>
  );
};
export default AddCoupons;
