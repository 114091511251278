import React from 'react';
import { cn } from "../../lib/utils";
import { Marquee } from '../../Magicui/Marquee';

const reviews = [
    {
        img:"./Imagefolder/Courses/kid_design.jpg"
    },
    {
        img:"./Imagefolder/Courses/playtime3.jpg"
    },
    {
        img:"./Imagefolder/Courses/2kid_robo.jpg"
    },
    {
        img:"./Imagefolder/Courses/boytesting.jpg"
    },
    {
        img:"./Imagefolder/Courses/3kids_robo.jpg"
    },
    {
        img:"./Imagefolder/Courses/lego_car.jpg"
    }
]


const ReviewCard = ({
  img,
  name,
  username,
  body,
}: {
  img: string;
  name: string;
  username: string;
  body: string;
}) => {
  return (
    <figure
      className={cn(
        "relative w-64 cursor-pointer overflow-hidden rounded-xl border p-4",
      )}
    >
      <div className="flex flex-row items-center gap-2">
        <img className="rounded-full" width="32" height="32" alt="" src={img} />
        <div className="flex flex-col">
          <figcaption className="text-sm font-medium dark:text-white">
            {name}
          </figcaption>
          <p className="text-xs font-medium dark:text-white/40">{username}</p>
        </div>
      </div>
      <blockquote className="mt-2 text-sm">{body}</blockquote>
    </figure>
  );
};

const CarouselImage=()=> {
  return (
    <>
      <Marquee pauseOnHover className="[--duration:10s]">
        {
            reviews.map((item,index)=>{
                return(
                    <>
                            <div key={index} className="md:basis-1/2 lg:basis-1/3">
                                <div>
                                    <img className="h-[25rem] w-full rounded-lg" src={item.img} alt="carousel_img" />
                                </div>
                            </div>
                            </>
                       )
                    })
                }
      </Marquee>
      <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-white dark:from-background"></div>
      <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-white dark:from-background"></div>
       </>
  );
}


export default CarouselImage;
