import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { Button } from "../../../../ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../ui/Select"
import { Input } from "../../../../ui/Input";
import React from "react"
import actions from "../../../../../actions";
import PopUp from "../../../../Pages/PopUp"
import { IFAQ } from "../../../../../../typings"

const FormSchema = z.object({
    question: z.string().min(2, {
        message: "Question is required .",
      }),
      answer: z.string().min(2, {
        message: "Answer is required .",
      }),
      topic:z.string({
        required_error:"Topic is Required.",
      })
})

type Props={
  success:Boolean,
  fail :Boolean,
}
interface TopicListProps {
  isNew: boolean;
}
const AddFaq=({ isNew }: TopicListProps)=>{
  
  const [data, setData]=React.useState<IFAQ[]>([])
  const [pop, setPop]=React.useState<Props>({
    success:false,
    fail:false,
  });


  const fetchData=async ()=>{
    const res :IFAQ[]= await actions.GET_DATA({url: "faq/topic/all"})
    setData(res)
  }

  React.useEffect(()=>{
    fetchData()
  },[isNew])

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
        question: "",
        answer: "",
        topic:"",
    },
  })

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try{
      const submit = await actions.POST_DATA({
        url: "faq/add",
        payload: data
      });

      if (submit.success === true) {
        form.reset();
        setPop({success: true, fail: false});
      }}
    catch(error) {
    }
    }

  return (
    <React.Fragment>
      {pop.success && (
        <PopUp heading="Success" description="FAQ Successfully Added. " />
      )}
      {pop.fail && <PopUp  heading="Error" description="please Enter the Input ." />}
      <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="p-5 mt-8 leading-loose ">
        <h1 className="text-center xl:text-3xl md:text-2xl text-xl font-bold my-3 xl:my-5">Add FAQs</h1>
        <FormField
          control={form.control}
          name="topic"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Topics</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select Topic" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {
                    data.map((item,index) => (
                      <SelectItem key={index} value={item._id}>{item.name}</SelectItem>
                    ))
                  }
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="question"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Question</FormLabel>
              <FormControl>
                <Input placeholder="Write Question Here ..." {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="answer"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Answer</FormLabel>
              <FormControl>
                <Input placeholder="Write Answer Here ..." {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button className="bg-tertiary text-white_100 my-5 " type="submit">Submit</Button>
      </form>
    </Form>
    </React.Fragment>
  )
}
export default AddFaq
