import actions from "../../../../actions";
import { Button } from "../../../ui/button";
import { Card } from "../../../ui/Card";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "../../../ui/dialog";
import { ICoupons } from "../../../../../typings";
import { Trash2 } from "lucide-react";
import React from "react";

interface CouponListProps {
  isNew: boolean
}
const CouponsList = ({isNew} :CouponListProps ) => {
  const [data, setData] = React.useState<ICoupons[]>([]);

  const fetchCoupons = async () => {
    const response = await actions.GET_DATA({url:"coupon/all"})
    setData(response);
  };

  React.useEffect(() => {
    fetchCoupons();
  }, [isNew]);

  const deleteCoupon = async (id: string) => {
    try {
      const response = await actions.DELETE_WITH_POST({
        url:"coupon/delete",
        params:id ,
      })
      if (response.status === 200) {
        fetchCoupons();
      }
    } catch (error) {
    }
  };

  return (
    <React.Fragment>
      <main>
        <Card className="p-5 border-none shadow-md shadow-primary">
          <h1 className="text-center xl:text-3xl md:text-2xl text-xl  font-bold my-3 xl:my-5 ">
            Coupons List
          </h1>
          {data.map((item, index) => {
            return (
              <div
                key={index}
                className="text-lg leading-loose gap-2 sm:gap-5 grid grid-cols-2"
              >
                <div className="flex gap-2 sm:gap-5">
                  <h1 className="font-bold">{index + 1}.</h1>
                  <h1> {item.name}</h1>
                </div>
                <div className="flex justify-end">
                  <Dialog>
                    <DialogTrigger>
                      <Button variant="outline" className="border-none">
                        <Trash2 className="hover:text-red_100 size-6 " />
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="bg-white_100 w-5/6 sm:w-full">
                      <DialogTitle>Confirm Delete</DialogTitle>
                      <DialogDescription className="md:text-lg">
                        Are you sure you want to delete this Coupon?
                      </DialogDescription>
                      <div className="flex justify-end space-x-2 mt-6">
                        <DialogClose asChild>
                          <Button className="text-white_100">Cancel</Button>
                        </DialogClose>
                        <DialogClose asChild>
                          <Button
                            className="bg-red_100 text-white_100"
                            onClick={() => {
                              deleteCoupon(item._id);
                            }}
                          >
                            Delete
                          </Button>
                        </DialogClose>
                      </div>
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
            );
          })}
        </Card>
      </main>
    </React.Fragment>
  );
};

export default CouponsList;
