import React from 'react'
import AddCampCoupons from './AddCampCoupons'
import CampCouponsList from './CampCouponsList';
import HeadTabsCoupon from '../Coupons/HeadTabsCoupon';

const CampCoupons = () => {

  const [isNew, setIsNew]= React.useState(false);

    const handleNew = ()=>{
        setIsNew(!isNew);
    }

  return (
    <React.Fragment>
      <HeadTabsCoupon>
        <main className='grid grid-cols-1 md:grid-cols-2 gap-5'>
            <div>
                <AddCampCoupons handleNew={handleNew}  />
            </div>
            <div>
              <CampCouponsList isNew={isNew} />
            </div>
        </main>
        </HeadTabsCoupon>
    </React.Fragment>
  )
}

export default CampCoupons