import React from 'react'
import OrderCards from './OrderCards'
import DataCards from './DataCards'

const AdminCards = () => {
    
  return (
    <React.Fragment>
        <main>
            <div className=''>
                <DataCards/>
            </div>
            <div className='my-10'>
                <div className='grid sm:grid-cols-2 2xl:grid-cols-4 gap-5'>
                </div>
                <OrderCards />
            </div>
        </main>
    </React.Fragment>
  )
}

export default AdminCards;
