"use client";
import { Button } from '../../../ui/button';
import { Card, CardFooter } from '../../../ui/Card';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IProdData } from 'typings';
import { toast } from 'sonner';
import actions from '../../../../actions';

const CourseBook: React.FC = () => {
    const navigate = useNavigate();

    const [data, setData] = React.useState<IProdData[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
  const [dynamicRouteData, setDynamicRouteData] = React.useState<any | null>(null);

  const HandleRoute = async (id: string) => {
    try {
      const req = await fetch(`https://iceroboapi.pickupbiz.in/api/v1/items/uniq/${id}`);
      const res = await req.json();
      setDynamicRouteData(res);
      navigate('/dynamicSummer', { state: { itemData: res } });
    } catch (error) {
      console.error('Error fetching unique item:', error);
    }
  };

    const fetchSummerData = async () => {
        setLoading(true);
        try {
            const res: IProdData[] = await actions.GET_DATA_TOKEN({ url: "items/items/category/665841fb7bf7a627d8b73c7c" });
            if (Array.isArray(res)) {
                setData(res);
            } else {
                setData([]);
            }
        } catch (error) {
            setData([]);
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        fetchSummerData();
    }, []);

    const addToCart = async (id: string) => {
        try {
            const payload = {
                itemId: id,
                quantity: 1,
            };

            const add = await actions.POST_DATA_TOKEN({
                url: "cart/add",
                payload,
            });

            if (add.message === "Invalid Token") {
                toast("Failed", {
                    description: "Please login to continue",
                    action: {
                        label: "Login",
                        onClick: () => navigate("/login"),
                    },
                });
                return;
            }

            if (add.success === true) {
                toast("Success", {
                    description: "Item added to cart successfully",
                    action: {
                        label: "Go to cart",
                        onClick: () => navigate("/checkout"),
                    },
                });
            } else {
                toast("Error", {
                    description: "Failed to add item to cart",
                });
            }
        } catch (error) {
            // Handle error
        }
    };

    return (
        <React.Fragment>
            <h1 className='text-xl md:text-3xl text-primary font-bold text-center '>
                We have flexible options to choose from
            </h1>
            <div className="grid xl:grid-cols-4 gap-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 p-5 md:p-10 lg:max-w-screen-2xl mx-auto w-full">
                {
                    data.map((item) =>
                        <Card key={item._id} className="border rounded-3xl border-b-8 border-tertiary">
                            <div className="lg:px-5 sm:px-10 md:px-5 p-5">
                                <img
                                    className=" h-[16rem] sm:h-[12rem] lg:h-[16rem] mx-auto md:w-[25rem] w-[20rem] hover:transform hover:scale-110 hover:brightness-75 hover:duration-700 bg-primary rounded-xl"
                                    src={item.image}
                                    alt={item.name}
                                />
                            </div>
                            <div className="px-5 leading-loose tracking-wide">
                                <h2 className="xl:text-xl line-clamp-2 md:text-lg text-xl xl:h-14 md:h-12 sm:h-14 text-primary font-bold text-center">{item.name}</h2>
                                <h2 className="text-center font-medium text-base my-3 text-secondary">{item.subTitle}</h2>
                                <h3 className="text-center text-3xl font-bold">$ {item.price}</h3>
                            </div>
                            <CardFooter className="px-5 my-4 gap-5 grid grid-cols-2">
                                <Button
                                    className="w-full text-base bg-tertiary hover:bg-tertiary/85 rounded-full text-white_100"
                                    onClick={() => HandleRoute(item._id)}
                                >
                                    Read More
                                </Button>
                                <Button
                                    className="font-semibold text-base bg-secondary hover:bg-secondary/80 rounded-full text-white_100"
                                    onClick={() => addToCart(item._id)}
                                >
                                    Add to Cart
                                </Button>
                            </CardFooter>
                        </Card>
                    )
                }
            </div>
        </React.Fragment>
    );
};

export default CourseBook;