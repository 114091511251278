import React from 'react';
import { Level1 } from './Level1OfTERA';
const TERAICE = () => {
    const data=[
        
        {
            "Level":"Scratch Animation"
        },
        {
            "Level":"Dot Robots"
        },
        {
            "Level":"3D Dodlepen"
        },
        {
            "Level":"LittleBits"
        },
        {
            "Level":"Ozobot"
        },
        {
            "Level":"Snap Ser"
        },
    
    ]
    return (
        <div className='grid place-content-center '>
            <div className='lg:flex mx-auto p-10'>
                <div className=''>
                <Level1/>
                </div>
            {
            data.map((item,index)=>
                <>
                <div className={`h-[8rem] w-[8rem] p-0 border-2 rotate-45 rounded-xl place-content-center text-center ${index%2===0?"lg:mt-28 ml-32 md:ml-40 lg:ml-0 bg-gradient-to-tr from-primary":"bg-gradient-to-bl from-primary"}`}>
                <h1 className='-rotate-45 font-bold text-xl pr-10'>Level {index+1+1}</h1>
                    <h1 className='-rotate-45 font-bold mt-1 w-36'>{item.Level}</h1>
                </div>
                </>
                    )
                }
            </div>
        </div>
    );
}

export default TERAICE;

