
import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Account from '../components/Pages/My_Account/Account';
import MainHome from '../components/Pages/Home/MainHome';
import About from '../components/Pages/About/About';
import OurRobots from '../components/Pages/ourRobots/OurRobots';
import PrivacyPolicy from '../components/Pages/OurPolicy';
import TestFAQ from '../components/Pages/faq/FAQ';
import RodMpIndex from '../components/Pages/RoadMap/RodMpIndex';
import Competition from '../components/Pages/Competition/Competition';
import Courses from '../components/Pages/Courses/Courses';
import PdPa from '../components/Pages/PADays/PdPa';
import Contact from '../components/Pages/Contact/Contact';
import MainProgram from '../components/Pages/Programs/MainProgram';
import AdminLayout from './AdminLayout';
import GalleryNew from '../components/Pages/Gallery/GalleryNew';
import Shop from '../components/Pages/Shop/Shop';
import VirtualClasses from '../components/Pages/VirtualClasses/VirtualClasses';
import VirtualIndex from '../components/Pages/VirtualClasses/VirtualIndex';
import { DataProvider } from '../components/lib/DataContext';
import AdminLogin from '../components/Pages/Auth/AdminLogin';
import Layout from './UserLayout';
import AddUsers from '../components/Pages/DashboardAtt2/Users/AddUsers';
import Categories from '../components/Pages/DashboardAtt2/Products/Categories';
import AddProducts from '../components/Pages/DashboardAtt2/Products/AddProducts';
import AdminFaq from '../components/Pages/DashboardAtt2/Website/AdminFaq/AdminFaq';
import { ProductList } from '../components/Pages/DashboardAtt2/Products/ProductList';
import TotalOrders from '../components/Pages/DashboardAtt2/Orders/TotalOrders';
import CampCoupons from '../components/Pages/DashboardAtt2/CampCoupons/CampCoupons';
import MainTrialList from '../components/Pages/DashboardAtt2/CampCoupons/MainTrialList';
import Coupons from '../components/Pages/DashboardAtt2/Coupons/Coupons';
import ProductDetails from '../components/Pages/DashboardAtt2/Products/ProductsDetails';
import Gallery from '../components/Pages/DashboardAtt2/Website/AdminGallery';
import SignUpPage from '../components/Pages/Signup/SignUpPage';
import DashedIndex from '../components/Pages/DashboardAtt2/adminDashboard/Dashedindex';
import MainRegistration from '../components/Pages/RegistrationForm/MainRegistration';
import Index from '../components/Pages/VirtualClasses/Index';
import CustomerTable from '../components/Pages/DashboardAtt2/Customer/CustomerTable';
import DynamicCustomer from '../components/Pages/DashboardAtt2/Customer/DynamicCustomer';
import DynamicBlogs from '../components/Pages/Blog/DynamicBlogs';
import Blog from '../components/Pages/Blog/Blog';
import { IBlogDocument } from 'typings';
import RefundPolicy from '../components/Pages/Footer/RefundPolicy/RefundPolicy';
import SummerCamp from '../components/Pages/Camps/SummerCamps';
import DynamicSummer from '../components/Pages/Camps/SummerCamps/DynamicSummer';
import WinterCamp from '../components/Pages/Camps/WinterCamps';
import DynamicWinter from '../components/Pages/Camps/WinterCamps/DynamicWinter';
import Break from '../components/Pages/Camps/MarchBreak/Break';
import DynamicBreak from '../components/Pages/Camps/MarchBreak/DynamicBreak';
import DynamicComponent from '../components/Pages/Shop/DynamicComponent';
import AnalyticsIndex from '../components/Pages/DashboardAtt2/Analytics/AnalyticsIndex';
import AddBlogIndex from '../components/Pages/DashboardAtt2/Website/Blogs/AddBlogs/AddBlogIndex';
import Checkout from '../components/Pages/VirtualClasses/Checkout';

import Success from '../components/Pages/Status/Success';
import Failed from '../components/Pages/Status/Failed';
import MainUserList from '../components/Pages/DashboardAtt2/Users/MainUserList';


const data: IBlogDocument[] = [];

const userRoutes = [
  { path: '/', element: <MainHome /> },
  { path: '/login', element: <Account /> },
  { path: '/signUp', element: <SignUpPage /> },
  { path: '/about', element: <About /> },
  { path: '/ourRobots', element: <OurRobots /> },
  { path: '/ourPolicy', element: <PrivacyPolicy /> },
  { path: '/faq', element: <TestFAQ /> },
  { path: '/programs', element: <MainProgram /> },
  { path: '/roadMap', element: <RodMpIndex /> },
  { path: '/blogs', element: <Blog data={[]} /> },
  { path: '/dynamicBlogs', element: <DynamicBlogs /> },
  { path: '/registration', element: <MainRegistration /> },
  { path: '/competition', element: <Competition /> },
  { path: '/courses', element: <Courses /> },
  { path: '/papdDays', element: <PdPa /> },
  { path: '/contact', element: <Contact /> },
  { path: "/admin", element: <AdminLogin /> },
  { path: "/gallery", element: <GalleryNew /> },
  { path: "/shop", element: <Shop /> },
  { path: "/dynamicComponent", element: <DynamicComponent /> },
  { path: "/virtualClasses", element: <VirtualClasses /> },
  { path: "/checkout", element: <Index /> },
  { path: "/dynamicVirtual", element: <VirtualIndex /> },
  { path: "/refundPolicy", element: <RefundPolicy /> },
  { path: "/summerCamp", element: <SummerCamp /> },
  { path: "/dynamicSummer", element: <DynamicSummer /> },
  { path: "/winterCamp", element: <WinterCamp /> },
  { path: "/dynamicWinter", element: <DynamicWinter /> },
  { path: "/marchBreak", element: <Break /> },
  { path: "/dynamicBreak", element: <DynamicBreak /> },
  { path: "/checkout", element: <Checkout /> },
  { path: "/success", element: <Success/>},
  { path: "/cancel", element: <Failed/> }
];

const adminRoutes = [
  { path: '/dashboard', element: <DashedIndex /> }, 
  { path: '/dashboard/users', element: <MainUserList /> },
  { path: '/dashboard/addUsers', element: <AddUsers /> },
  { path: '/dashboard/categories', element: <Categories /> },
  { path: '/dashboard/addProduct', element: <AddProducts /> },
  { path: '/dashboard/products', element: <ProductList /> },
  { path: '/dashboard/productDetails', element: <ProductDetails /> },
  { path: '/dashboard/orders', element: <TotalOrders /> },
  { path: '/dashboard/customer', element: <CustomerTable /> },
  { path: '/dashboard/dynamicCustomer', element: <DynamicCustomer /> },
  { path: '/dashboard/coupons', element: <Coupons /> },
  { path: '/dashboard/trial', element: <MainTrialList /> },
  { path: '/dashboard/campCoupons', element: <CampCoupons /> },
  { path: '/dashboard/faq', element: <AdminFaq /> },
  { path: '/dashboard/analytics', element: <AnalyticsIndex />},
  { path: '/dashboard/blogs', element: <AddBlogIndex />},
  { path: '/dashboard/faq', element: <AdminFaq />},
  { path: '/dashboard/gallery', element: <Gallery /> },
];

const Landing = () => {
  const [userRole, setUserRole] = React.useState<string>('user');
  const navigate = useNavigate();

  useEffect(() => {
    const role = sessionStorage.getItem('tgw740q89nwe#@@&^wgehdfsgf'); // Fetch the role on mount
    if (role) {
      const deRole=atob(role)
      setUserRole(deRole); // Update role state if found
    }
  }, []);
  
  return (
    <React.Fragment>
      <main>
        <DataProvider>
          {userRole === 'admin' ? (
            <AdminLayout>
              <Routes>
                {adminRoutes.map((route, index) => (
                  <Route key={index} path={`${route.path}`} element={route.element} />
                ))}
              </Routes>
            </AdminLayout>
          ) : (
            <Layout>
              <Routes>
                {userRoutes.map((route, index) => (
                  <Route key={index} path={route.path} element={route.element} />
                ))}
              </Routes>
            </Layout>
          )}
        </DataProvider>
      </main>
    </React.Fragment>
  );
};

export default Landing;

