import React, { useEffect, useRef } from "react";
import { Chart, ChartData, ChartOptions, registerables } from "chart.js";
import actions from "../../../../actions";
Chart.register(...registerables);

const BarChart: React.FC = () => {

  const [barData, setBarData]=React.useState<number[]>([]);
  const [labels, setLabels]=React.useState<string[]>([]);

  const fetchPastRevenue = async ()=>{
    try{
      const response = await actions.GET_CHART_DATA({url:"cart/revenue/pastsixyears"})
      const result = response;
      console.log(result,"--pastsixyears");
      const totalRevenue = result.map((item:any)=>
      typeof item.totalRevenue === "number" ? item.totalRevenue : 0
      )
      // console.log(totalRevenue, "total past years")
      const years = result.map((item:any)=> item.year);
      // conso  le.log(years,"years")
      setBarData(totalRevenue);
      setLabels(years);
    }catch(error){
      console.error('Error fetching past revenue:', error);
    }
  }

  React.useEffect(()=>{
    fetchPastRevenue();
  },[])

  const chartRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (chartRef.current && barData.length > 0 && labels.length > 0) {
      if ((chartRef.current as any).chartInstance) {
        (chartRef.current as any).chartInstance.destroy();
      }
      const context = chartRef.current.getContext("2d");
      if (!context) return;
      const data: ChartData<"bar", number[], string> = {
        labels: labels,
        datasets: [
          {
            label: "Revenue $",
            data: barData,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
            ],
            borderColor: [
              "rgba(255, 0, 54)",
              "rgba(0, 64, 125)",
              "rgba(255, 181, 0)",
              "rgba(0, 112, 113)",
              "rgba(85, 0, 255)",
            ],
            borderWidth: 1,
          },
        ],
      };
      const options: ChartOptions<'bar'> = {
        scales: {
          x: {
            type: "category",
          },
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 2000,
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              usePointStyle: true, 
              pointStyle: 'rect',  
              boxWidth: 10,        
              boxHeight: 10,    
            },
          },
        },
      };
      const newChart = new Chart(context, {
        type: "bar",
        data,
        options,
      });
      (chartRef.current as any).chartInstance = newChart;
    }
  }, [barData, labels]);

  return (
    <div>
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <canvas ref={chartRef} />
      </div>
    </div>
  );
};

export default BarChart;