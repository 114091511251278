"use client";
import React from "react";
import CourseTest from "./CourseTest";
import CarouselImage from "./CarouselImage";

const Courses = () => {
  return (
    <React.Fragment>
      <main className="lg:max-w-screen-xl w-full mx-auto m-5  p-5">
        <div>
          <CarouselImage/>
        </div>
        <div>
          <CourseTest />
        </div>
      </main>
    </React.Fragment>
  );
};

export default Courses;
