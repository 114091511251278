import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ArrowUpDown,
  ChevronDown,
  CircleCheckBig,
  CircleDashed,
  CircleDot,
  DotSquare,
  MoreHorizontal,
  MoveUpRight,
  Trash2,
} from "lucide-react";

import { Button } from "../../../ui/button";
import { Checkbox } from "../../../ui/Checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../ui/dropdown-menu";
import { Input } from "../../../ui/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";
import actions from "../../../../actions";
// import Link from "next/link";
import { IStudentData } from "../../../../../typings";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "../../../ui/dialog";
import { Link, useNavigate } from "react-router-dom";

export default function CustomerTable() {
  const columns: ColumnDef<IStudentData>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected()
              ? true
              : table.getIsSomePageRowsSelected()
                ? "indeterminate"
                : false
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },

    {
      accessorKey: "studentFName",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            First Name
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => (
        <div className="capitalize ml-4">{row.getValue("studentFName")}</div>
      ),
    },
    {
      accessorKey: "studentLName",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Last Name
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => (
        <div className="capitalize ml-4">{row.getValue("studentLName")}</div>
      ),
    },
    {
      accessorKey: "dob",
      header: "Date of Birth",
      cell: ({ row }) => {
        const dob = new Date(row.getValue("dob"));

        // Format the date as "dd/mm/yyyy"
        const formattedDate = `${dob.getDate()}/${dob.getMonth() + 1
          }/${dob.getFullYear()}`;

        return <div className="capitalize">{formattedDate}</div>;
      },
    },
    {
      accessorKey: "program",
      header: "Program",
      cell: ({ row }) => (
        <div className="capitalize">{row.getValue("program")}</div>
      ),
    },
    {
      accessorKey: "gender",
      header: "Gender",
      cell: ({ row }) => (
        <div className="capitalize ml-1">{row.getValue("gender")}</div>
      ),
    },
    {
      accessorKey: "address",
      header: "Address",
      cell: ({ row }) => (
        <div className="capitalize ">{row.getValue("address")}</div>
      ),
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => {
        const render = () => {
          if (row.getValue("status") === "pending") {
            return (
              <Button variant="outline" className="gap-2 border-none">
                <CircleDot className=" h-4 w-4 " color="red" />
                <span className="capitalize">{row.getValue("status")}</span>
              </Button>
            );
          } else if (row.getValue("status") === "in-progress") {
            return (
              <Button variant="outline" className="gap-2 border-none">
                <CircleDashed className=" h-4 w-4 " color="orange" />
                <span className="capitalize">{row.getValue("status")}</span>
              </Button>
            );
          } else if (row.getValue("status") === "completed") {
            return (
              <Button variant="outline" className="gap-2 border-none">
                <CircleCheckBig className=" h-4 w-4 " color="green" />
                <span className="capitalize">{row.getValue("status")}</span>
              </Button>
            );
          }
        };
        const setStatusPending = async (status: string) => {
          try {
            const task = row.original;
            const update = await actions.PUT_DATA({
              url: "registerStudents/update",
              params: task._id,
              payload: {
                status: status,
              },
            });
            if (update.success === true) {
              fetchCustomerDetails();
            }
          } catch (error) {
          }
        };
        return (
          <div className="capitalize">
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8  p-0 ">
                  {render()}
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>Set Status</DropdownMenuLabel>

                <DropdownMenuItem>
                  <Button
                    onClick={() => setStatusPending("pending")}
                    variant="outline"
                    className="gap-2 w-full border border-gray_200  "
                  >
                    <CircleDashed color="red" className="h-4 w-4" />
                    <h1 className="">Pending</h1>
                  </Button>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Button
                    onClick={() => setStatusPending("in-progress")}
                    variant="outline"
                    className="gap-2 w-full border border-gray_200  "
                  >
                    <CircleDot color="orange" className="h-4 w-4" />
                    <h1 className="">In Progress</h1>
                  </Button>
                </DropdownMenuItem>
                <DropdownMenuItem>
                  <Button
                    onClick={() => setStatusPending("completed")}
                    variant="outline"
                    className="gap-2 w-full border border-gray_200  "
                  >
                    <CircleCheckBig color="green" className="h-4 w-4" />
                    <h1 className="">Completed</h1>
                  </Button>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
    {
      header: "Delete",
      cell: ({ row }) => (
        <div className="capitalize">
          <Dialog>
            <DialogTrigger>
              <Button variant="outline" className="border-none">
                <Trash2 className="hover:text-red_100 size-6 " />
              </Button>
            </DialogTrigger>
            <DialogContent className="bg-white_100 w-5/6 sm:w-full">
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogDescription className="md:text-lg">
                Are you sure you want to delete this customer?
              </DialogDescription>
              <div className="flex justify-end space-x-2 mt-6">
                <DialogClose asChild>
                  <Button className="text-white_100">Cancel</Button>
                </DialogClose>
                <DialogClose asChild>
                  <Button
                    className="bg-red_100 text-white_100"
                    onClick={() => {
                      deleteStudent(row.original._id);
                    }}
                  >
                    Delete
                  </Button>
                </DialogClose>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      ),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const student = row.original;

        return (
            <Button
              variant="ghost"
              className="h-8 w-8 p-0 border border-gray_200 "
              onClick={() => HandleRoute(student._id)}
            >
              <span className="sr-only">Open menu</span>
              <MoveUpRight className="size-6" />
            </Button>
        );
      },
    }
  ];

  const [data, setData] = React.useState<IStudentData[]>([]);
  const [dynamicRouteData, setDynamicRouteData] = React.useState<any | null>(null);
  const navigate = useNavigate();

  const fetchCustomerDetails = async () => {
    try {
      const res = await actions.GET_DATA({ url: "registerStudents/all" });
      setData(res);
    } catch (error) {
      throw new Error("error fetching data ");
    }
  };

  React.useEffect(() => {
    fetchCustomerDetails();
  }, []);

  const deleteStudent = async (id: string) => {
    try {
      const res = await actions.DELETE_DATA({
        url: "registerStudents/delete",
        params: id,
      });
      if (res.success === true) {
        fetchCustomerDetails();
      }
    } catch (error) {
    }
  }

  const HandleRoute = async (id: string) => {
    try {
      const req = await fetch(`https://iceroboapi.pickupbiz.in/api/v1/registerStudents/get/${id}`);
      const res = await req.json();
      setDynamicRouteData(res);
      navigate('/dashboard/dynamicCustomer', { state: { itemData: res } });
    } catch (error) {
    }
  };


  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] = React.useState<
    VisibilityState
  >({});
  const [rowSelection, setRowSelection] = React.useState({});

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <div className="w-full">
      <div className="flex items-center gap-4 py-4">
        <Input
          placeholder="Search Student"
          value={
            (table.getColumn("studentFName")?.getFilterValue() as string) ?? ""
          }
          onChange={(event) =>
            table.getColumn("studentFName")?.setFilterValue(event.target.value)
          }
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              Columns <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}
