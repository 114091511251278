"use server"

import Cookies from "js-cookie"
// import BASE_URL from "@/config"
// import { cookies } from "next/headers"
import { getRefToken } from "./GET.actions"
import BASE_URL from "../config/page"


type props ={
    url:string,
    params:string,
  
}

const deleteData=async(props:props)=>{
    try {
        const response = await fetch(`${BASE_URL}/${props.url}/${props.params}`,{
            method:"DELETE",
            headers:{
                "Content-Type":"application/json"
            },
        })
        const result = await response.json();
            return result;
    } catch (error) {
        throw error;
    }
}
const deleteDataWithGet=async(props:props)=>{
    try {
        const response = await fetch(`${BASE_URL}/${props.url}/${props.params}`,{
            method:"GET",
            headers:{
                "Content-Type":"application/json"
            },
        })
        const result = await response.json();
            return result;
    } catch (error) {
        throw error;
    }
}

const deleteDataWithPost=async(props:props)=>{
    try {
        const response = await fetch(`${BASE_URL}/${props.url}/${props.params}`,{
            method:"POST",
            headers:{
                "Content-Type":"application/json"
            },
        })
        const result = await response.json();
            return result;
    } catch (error) {
        throw error;
    }
}

const deleteDataWithToken=async(props:props)=>{
    const token = Cookies.get("token")
    try {
        const response = await fetch(`${BASE_URL}/${props.url}/${props.params}`,{
            method:"DELETE",
            headers:{
                "Content-Type":"application/json",
                'x-access-token': ` ${token}`,
            },
        })
        const data = await response.json();
      if(data.message ==='Invalid Token'){
        await getRefToken()
        const res = await fetch(`${BASE_URL}/${props.url}`, {
          method: "DELETE",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "x-access-token": `${token}`,
          },
        });
        const data = await res.json();
        return data
      }
    return data;
    } catch (error) {
        throw error;
    }
}
export  {deleteData,deleteDataWithToken,deleteDataWithGet,deleteDataWithPost};