import React, { useEffect, useState } from "react";
import { Popover, PopoverTrigger } from "../../ui/Popover";
import {
  CircleUserRound,
  LogOut,
  User,
  UserCog,
} from "lucide-react";
import { PopoverContent } from "@radix-ui/react-popover";
import { useNavigate } from "react-router-dom"; 
import CartSidebar from "../VirtualClasses/CartSideBar";
import actions from "../../../actions";
import Cookies from "js-cookie";

const ProfileIcon = () => {  

  const navigate = useNavigate();
  const [log_text,setLog_text]=useState("login")
  const [check,setCheck]=useState(false)

  async function handleLogout() {
    try {
      const refreshToken = getCookie("refToken");
      if (!refreshToken) {
        return;
      }

      const response = await actions.LOGOUT_DATA({
        url:"auth/logout",
        payload:{refreshToken}
      })
      
      if (response.success === true) {
        deleteCookie("refToken");
        deleteCookie("token");
        navigate("/login");
      }else{
      }
    } catch (error) {
    }
  }

  let token=Cookies.get('token')

  useEffect(() => {
    if (token === undefined) {
      setLog_text("login")
      setCheck(false)
    }
    else{
      const Username=localStorage.getItem('userName')
      setLog_text(Username);
      setCheck(true)
    }
  }, [token]);

  function getCookie(name: string) {
    const cookies = document.cookie.split(";");
    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName.trim() === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }

  function deleteCookie(name: string) {
    document.cookie = `${name}=; Max-Age=0; path=/;`;
  }

  return (
    <React.Fragment>
      {
        check==true ? 
        <>
         <main className="md:hidden">
        <Popover>
          <PopoverTrigger className="my-1">
          <div className={`flex gap-1 font-semibold`}>
          {/* <div className={`grid grid-cols-2 gap-2 font-semibold`}> */}
          {/* <div className={`grid gap-2 font-semibold`}> */}
              <CircleUserRound className="mx-auto mt-1 " size={30}/>
              <h1
                className={`text-sm font-bold my-auto ${
                  token ? "text-[#00ff00]" : ""
                } `}
              >
                {log_text}
              </h1>
            </div>
          
          </PopoverTrigger>
          <PopoverContent className="bg-white_100 font-medium text-black_100 leading-loose px-3 rounded-lg">
            <div className="flex gap-5 items-center">
              <CartSidebar />
            </div>

            {/* <div
              className="flex items-center w-fit gap-3 cursor-pointer"
              onClick={() => navigate("/login")}
            >
              <User size={20} />
              <h1 className="text-tertiary">User Login</h1>
            </div> */}

            <div
              className="flex items-center w-fit gap-3 cursor-pointer"
              onClick={() => navigate("/admin")} 
            >
              <UserCog size={20} />
              <h1>Admin Login</h1>
            </div>

            <div
              className="flex items-center gap-3 cursor-pointer"
              onClick={handleLogout}
            >
              <LogOut size={20} />
              <h1>Log Out</h1>
            </div>
          </PopoverContent>
        </Popover>
      </main>
        </>
        :
        <>
         <main className="md:hidden">
        <Popover>
          <PopoverTrigger className="my-1">
          <div className={`grid gap-2 font-semibold`}>
              <CircleUserRound className="mx-auto" size={34} />
              {/* <h1
                className={`text-sm font-semibold ${
                  token ? "text-[#00ff00]" : ""
                } `}
              >
                {log_text}
              </h1> */}
            </div>
          </PopoverTrigger>
          <PopoverContent className="bg-white_100 font-medium text-black_100 leading-loose px-3 rounded-lg">
            <div className="flex gap-5 items-center">
              <CartSidebar />
            </div>

            <div
              className="flex items-center w-fit gap-3 cursor-pointer"
              onClick={() => navigate("/login")}
            >
              <User size={20} />
              <h1 className="text-tertiary">User Login</h1>
            </div>

            <div
              className="flex items-center w-fit gap-3 cursor-pointer"
              onClick={() => navigate("/admin")} 
            >
              <UserCog size={20} />
              <h1>Admin Login</h1>
            </div>

            {/* <div
              className="flex items-center gap-3 cursor-pointer"
              onClick={handleLogout}
            >
              <LogOut size={20} />
              <h1>Log Out</h1>
            </div> */}
          </PopoverContent>
        </Popover>
      </main>
        </>
      }
     
    </React.Fragment>
  );
};

export default ProfileIcon;
