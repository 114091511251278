import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "../../../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../ui/form";
import { Input } from "../../../ui/Input";
import React from "react";
import { Card } from "../../../ui/Card";
import actions from "../../../../actions";

const FormSchema = z.object({
  month: z.string().min(2, {
    message: "month must be at least 2 characters.",
  }),
  off: z.string().min(1, {
    message: "off must be at least 1 characters in number form.",
  }),
  date: z.string().min(1, {
    message: "date must be at least 1 characters in number form.",
  }),
});

type AddCampProps = {
  handleNew: ()=>void,
}

const AddCampCoupons = ({handleNew} : AddCampProps) => {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      month: "",
      off: "",
      date: "",
    },
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      const submit = await actions.POST_DATA({
        url: "campcoupon/add",
        payload: data,
      });
      if (submit.success === true) {
        form.reset();
      }
    } catch (error) {
    }
    handleNew()
  }

  return (
    <React.Fragment>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
          <Card className="p-5 border-none space-y-4 shadow-md shadow-primary">
            <h1 className="text-center xl:text-3xl  md:text-2xl text-xl font-bold my-3 xl:my-5 ">
              Add Camp Coupons
            </h1>
            <FormField
              control={form.control}
              name="month"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Month</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter Month" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="off"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Off</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter Off discount" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="date"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Date</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter Date" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" className="my-5 bg-tertiary text-white_100">
              Submit
            </Button>
          </Card>
        </form>
      </Form>
    </React.Fragment>
  );
};

export default AddCampCoupons;
