
import React from "react";
import { Link } from "react-router-dom";
import { Bot } from "lucide-react";
import SideNav from "../components/Pages/DashboardAtt2/NavBar/SideNav";
import BurgerMenu from "../components/Pages/DashboardAtt2/NavBar/BurgerMenu";
import UserIcon from "../components/Pages/DashboardAtt2/NavBar/UserIcon";

  const AdminLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    //   return (
  return (
    <React.Fragment>
      <main className="w-full grid grid-cols-12">
      {/* Sidebar for larger screens */}
      <section className="lg:col-span-2 2xl:col-span-2 hidden lg:block border-r border-r-gray_200">
        <div className="h-16 flex items-center justify-center border-b border-b-gray_200">
          <Link
            to="/dashboard"  
            className="font-semibold flex gap-3 items-center justify-center"
          >
            <Bot className="size-8 mb-1 place-items-center" />
            <span className="font-semibold">ICE ROBOTICS</span>
          </Link>
        </div>
        {/* Side Navigation */}
        {/* <section className="grid "> */}
        <section className="grid place-items-center">
          <SideNav />
        </section>
      </section>
      {/* Main Content Area */}
      <section className="col-span-12 lg:col-span-10 2xl:col-span-10 h-full w-full">
        <header className="h-16 flex items-center justify-end border-b border-b-gray_200">
          <section className="lg:hidden w-full">
            <BurgerMenu />
          </section>
          <section className="hidden lg:block px-5">
            <UserIcon />
          </section>
        </header>

        {/* Main children content */}
        <main className="h-[93vh] p-4 overflow-y-scroll">
          {children}
        </main>
      </section>
    </main>
    </React.Fragment>
  );
};

export default AdminLayout;