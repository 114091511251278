import { Sheet, SheetContent, SheetTrigger } from "../../ui/sheet";
import { ShoppingCart } from "lucide-react";
import React from "react";
import { useData } from "../../lib/DataContext";
import IndexCart from "./IndexCart";

const CartSidebar = () => {
  const { data } = useData();

  return (
    <React.Fragment>
      <div className="block">
        <Sheet>
          <SheetTrigger asChild>
            <div className="">
              {data >= 0 && (
                <span className="text-xs z-[999] relative top-2 md:-top-6 lg:-top-3 left-4 md:left-5 bg-tertiary text-white_100 rounded-full w-5 h-5 flex items-center justify-center">
                  {data}
                </span>
              )}
              <div className="flex gap-5 md:hidden">
                <ShoppingCart size={22} className="mx-auto cursor-pointer " />
                <h1 className="md:py-2 text-sm  font-semibold">Cart</h1>
              </div>
              <div className="hidden md:grid gap-5 md:gap-0 md:absolute md:top-[2.1rem] lg:top-7 2xl:top-10 mx-auto">
                <ShoppingCart size={22} className="mx-auto cursor-pointer " />
                <h1 className="md:py-2 text-sm font-semibold hidden md:block">
                  Cart
                </h1>
              </div>
            </div>
          </SheetTrigger>
        <SheetContent className="bg-white_100  mt-28  md:mt-[6.8rem]  lg:mt-[6.4rem]  xl:mt-[7.8rem]  2xl:mt-[8.3rem]  overflow-y-scroll  max-h-[80vh] sm:max-h-[70vh] md:max-h-[75vh]  lg:max-h-[80vh] px-4  sm:px-6">
            <IndexCart />
          </SheetContent>
        </Sheet>
      </div>
    </React.Fragment>
  );
};

export default CartSidebar;
