import * as React from "react";
import { Separator } from "@radix-ui/react-select";
import { Card, CardContent, CardHeader } from "../../ui/Card";

const AboutBottom = () => {
  const data = [
    {
      img: "/Imagefolder/faculty/hamid.png",
      name: "Hamid",
      desc: "A passionate and innovative engineer with working experience in Robotics and Arduino hardware & software. With years of experience in robotics and mechatronics, he always leads his sessions with enthusiasm and engages all his students in various manners."
    },
    {
      img: "/Imagefolder/faculty/stan.png",
      name: "Stan",
      desc: "Graduated with a Bachelor of Science in Physics. He is dedicated to offering academic help to new students in the field of STEM. He has developed a variety of effective assessment material in programming and robotics that aim to enhance each students’ learning experience."
    },
    {
      img: "/Imagefolder/faculty/mian.png",
      name: "Mian",
      desc: "A dedicated and goal-driven educator with 6 years of progressive experience in teaching Computer Science, Programming, Artificial Intelligence, Machine Learning and Robotics. He teaches students how to implement various techniques such as neural networks, genetic algorithms and machine learning."
    },
    {
      img: "/Imagefolder/faculty/danyaal.jpeg",
      name: "Danyaal",
      desc: "In highschool I was part of the robotics team. We went on to participate in a vex IQ skill challenge and although we didn't come in first it opened my eyes to the world of robotics. Since then I've taken on multiple side projects from different robotic programs and have been slowly mastering them all. It's like I landed my dream job."
    }
  ];

  return (
    <React.Fragment>
      <main>
        <h1 className="text-xl sm:text-3xl font-bold text-center m-5">
          OUR FACULTY
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-7">
          {data.map((item, index) => {
            return (
              <Card
                key={index}
                className="grid lg:grid-cols-12 place-items-center hover:transform hover:transition-transform hover:scale-105 hover:duration-1000 border-primary border-l-primary border-l-4 border-b-primary border-b-4 shadow-md shadow-primary"
              >
                <CardHeader className="lg:col-span-6 max-w-screen-sm sm:w-full">
                  <img
                    className="h-52 xl:h-80 rounded-xl"
                    src={item.img}
                    alt={item.name}
                  />
                </CardHeader>
                <CardContent className="lg:col-span-6">
                  <h2 className="font-semibold text-xl sm:text-2xl">
                    {item.name}
                  </h2>
                  <Separator className="bg-secondary h-0.5" />
                  <p className="text-xs sm:text-sm py-3 line-clamp-6 sm:line-clamp-none hover:line-clamp-none">
                    {item.desc}
                  </p>
                </CardContent>
              </Card>
            );
          })}
        </div>
      </main>
    </React.Fragment>
  );
};

export default AboutBottom;
