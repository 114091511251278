import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Tabs, TabsList, TabsTrigger } from "../../../ui/Tabs";
import { Box, FilePlus2, Globe, PackagePlus } from "lucide-react";

interface HeadTabsProps {
  value: string;
  title: string;
  icon: any;
  link: string;
}

const tabs: HeadTabsProps[] = [
    {
        value: "categories",
        title: "Category",
        icon: <FilePlus2 className="size-5" />,
        link: "/dashboard/categories",
      },
      {
        value: "addProducts",
        title: "Add",
        icon: <PackagePlus className="size-5" />,
        link: "/dashboard/addProduct",
      },
      {
        value: "productDetails",
        title: "Products",
        icon: <Box className="size-5" />,
        link: "/dashboard/products",
      }
];

export default function HeadTabsProduct({ children }: { children: React.ReactNode }) {
  const location = useLocation();  

  return (
    <main>
      <Tabs defaultValue="site" className=" ">
        {/* <TabsList className="grid w-full grid-cols-2 sm:grid-cols-3 gap-4 bg-primary border border-gray_200 md:px-4 h-14"> */}
        <TabsList className="grid grid-cols-3 h-fit sm:grid-cols-3 md:grid-cols-3 gap-4 border  border-gray_200  md:px-4  ">

          {tabs.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <TabsTrigger
                  className={`${
                    item.link === location.pathname
                      ? "border border-gray_200 rounded-md"
                      : ""
                  }`}
                  value={item.value}
                >
                  <NavLink
                    to={item.link}
                    className="flex gap-1 place-items-center text-wrap text-lg font-bold"
                  >
                    {item.icon}
                    <span className="text-lg ">{item.title}</span>
                  </NavLink>
                </TabsTrigger>
              </React.Fragment>
            );
          })}
        </TabsList>
      </Tabs>
      <section className="w-full md:p-4 my-2 rounded-md h-full">
        {children}
      </section>
    </main>
  );
}
