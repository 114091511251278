import React from 'react';
import AnalyticsChats from './AnalyticsChats';

const AnalyticsIndex = () => {
    return (
        <div>
            <AnalyticsChats/>
        </div>
    );
}

export default AnalyticsIndex;
