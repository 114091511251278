import React from "react";
import data from "../../../DB/PolicyWorkshop.json";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/Tabs";
import { ArrowBigRightDash } from "lucide-react";

const PrivacyDown = () => {
  return (
    <React.Fragment>
      <main className=" max-w-screen-2xl h-fit px-10 mx-auto ">
        {data.map((item, index) => {
          const { head, detail, tabs } = item;
          const TABS = tabs?.map((e) => e);

          return (
            <div key={index} className="space-y-5">
              <h1 className="text-lg xl:text-xl font-extrabold flex gap-2 place-items-center text-tertiary">
                <ArrowBigRightDash />
                {head}
              </h1>
              <p>{detail}</p>
              <section className="m-5">
                <Tabs defaultValue="registration">
                  <TabsList className="border border-border_grey rounded-none h-14 overflow-x-auto mx-auto w-full lg:w-[100vh]  xl:w-full flex justify-evenly">
                    {TABS.map((item, index) => (
                      <TabsTrigger
                        key={index}
                        value={item.tab}
                        className="text-base text-primary hover:border hover:border-secondary  focus:border-secondary hover:text-secondary focus:text-secondary"
                      >
                        {item.tab}
                      </TabsTrigger>
                    ))}
                  </TabsList>

                  <div className="my-5 h-28 w-full lg:w-[100vh] mx-auto xl:w-full overflow-y-auto border border-border_grey">
                    {/* Hard code */}
                    <TabsContent
                      value="registration"
                      className="px-5 py-2 hidden sm:block"
                    >
                      <div>
                        Registration for the workshop is mandatory for all
                        participants. Registration fee is non-refundable, except
                        in the case of cancellation by the organizers.
                        Participants are required to provide accurate and
                        complete information during the registration process.
                        Participants can register for the workshop online or
                        in-person.
                      </div>
                    </TabsContent>
                    {TABS.map((item, index) => (
                      <TabsContent
                        key={index}
                        value={item.tab}
                        className="px-5"
                      >
                        <div>
                          <div>{item.desc}</div>
                        </div>
                      </TabsContent>
                    ))}
                  </div>
                </Tabs>
              </section>
              <div>{item.end}</div>
            </div>
          );
        })}
      </main>
    </React.Fragment>
  );
};

export default PrivacyDown;
