import React from 'react';
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../ui/form";
import { Input } from "../../ui/Input";
import { Card, CardContent, CardHeader } from "../../ui/Card";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "../../ui/Select";
import { Label } from "../../ui/Label";
import { Textarea } from '../../ui/Textarea';
import { Checkbox } from '../../ui/Checkbox';
import actions from "../../../actions";
import PopUp from "../PopUp";

const formSchema = z.object({
  name: z.string().min(2, {
    message: "Enter your Full Name",
  }),
  email: z.string().email({ message: "Invalid email" }),
  phone: z
    .string()
    .regex(/^\d{10,11}$/, {
      message: "Phone number must be between 10 and 11 digits and contain only numbers.",
    }),
  school: z.string().min(10, {
    message: "Enter School / Organization.",
  }),
  designation: z.enum(["educator", "management", "principal", "parent", "trainer", "student", "others"], {
    required_error: "A designation is required.",
  }),
  country: z.string().min(2, {
    message: "Select your country",
  }),
  state: z.string().min(2, {
    message: "Select your state",
  }),
  demoFor: z.array(z.string()).min(1, {
    message: "Please select at least one option.",
  }),
  message: z
    .string()
    .min(10, {
      message: "Message must be at least 10 characters.",
    })
    .max(160, {
      message: "Message must not be longer than 160 characters.",
    }),
});

const handleNumericInput = (e: React.FormEvent<HTMLInputElement>) => {
  const input = e.currentTarget.value.replace(/\D/g, "");
  e.currentTarget.value = input.slice(0, 11); // Limits to 11 characters
};
type Props = {
  success: Boolean;
  fail: Boolean;
};

export const RequestForm = () => {
  const [pop, setPop] = React.useState<Props>({
    success: false,
    fail: false,
  });
  
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      designation:undefined,
      school:"",
      country: "",
      state: "",
      demoFor: [],
      message: ""
    },
  });

  const handleCheckboxChange = (value: string) => {
    const currentValues = form.getValues().demoFor || [];
    const updatedValues = currentValues.includes(value)
      ? currentValues.filter((item) => item !== value)
      : [...currentValues, value];
    form.setValue("demoFor", updatedValues);
  };

  async function onSubmit(data: z.infer<typeof formSchema>) {
    try {
      const submit = await actions.POST_DATA({
        url: "demo/add",
        payload: data,
      });

      if (submit.success === true) {
        form.reset();
        setPop({ success: true, fail: false });
      }
    } catch (error) {
    }
  }

  return (
    <React.Fragment>
       {pop.success && (
        <PopUp heading="Success" description="Message Successfully Sent " />
      )}
      {pop.fail && <PopUp heading="Error" description="Message  Not  Sent " />}
      {/* <main className={styles.main}> */}
      <main className="lg:max-w-screen-2xl p-5 h-fit font-sans grid place-content-center md:place-content-end " >
        <Card className="bg-lightblue">
          <CardHeader className="bg-primary text-white_100 mb-6 font-bold text-xl md:text-2xl text-center rounded-t-lg">Request your Demo Today</CardHeader>
          <CardContent>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <div className='lg:grid lg:grid-cols-2 gap-4'>
                  <FormField
                    control={form.control}
                    name="name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel className="font-bold text-base ">Full Name </FormLabel>
                        <FormControl>
                          <Input placeholder="Enter Name" {...field} />
                        </FormControl>
                        <FormMessage className="text-red_100" />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem className=''>
                        <FormLabel className="font-bold text-base ">Email </FormLabel>
                        <FormControl>
                          <Input placeholder="Enter your Email" {...field}/>
                        </FormControl>
                        <FormMessage className="text-red_100"/>
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-bold text-base">Phone Number</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter Phone number"
                          {...field}
                          onInput={handleNumericInput} // Limits input to numbers
                        />
                      </FormControl>
                      <FormMessage className="text-red_100" />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="designation"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-bold text-base ">Designation </FormLabel>
                      <FormControl>
                        <Select
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          value={field.value}
                        >
                          <SelectTrigger className="bg-white_100">
                            <SelectValue placeholder="Select a designation" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup className="bg-white_100">
                              {/* <SelectLabel>Designation</SelectLabel> */}
                              <SelectItem value="educator">Educator</SelectItem>
                              <SelectItem value="management">Management</SelectItem>
                              <SelectItem value="principal">Principal</SelectItem>
                              <SelectItem value="parent">Parent</SelectItem>
                              <SelectItem value="trainer">Trainer</SelectItem>
                              <SelectItem value="student">Student</SelectItem>
                              <SelectItem value="others">Others</SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage className="text-red_100"/>
                    </FormItem>
                  )}
                />
                {/* <FormField
                  control={form.control}
                  name="school"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-bold text-base ">School / Organization </FormLabel>
                      <FormControl>
                        <Textarea {...field} className='' placeholder='' />
                      </FormControl>
                      <FormMessage className="text-red_100"/>
                    </FormItem>
                  )}
                /> */}
                <FormField
                  control={form.control}
                  name="country"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-bold text-base ">Country</FormLabel>
                      <FormControl>
                        <Input placeholder='Enter your Country' {...field} />
                      </FormControl>
                      <FormMessage className="text-red_100"/>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="state"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-bold text-base ">State</FormLabel>
                      <FormControl>
                        <Input placeholder='Enter state' {...field} />
                      </FormControl>
                      <FormMessage className="text-red_100"/>
                    </FormItem>
                  )}
                />
                {/* <FormField
                  control={form.control}
                  name="demoFor"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-bold text-base ">Schedule demo for (check all that apply) </FormLabel>
                      <FormControl>
                        <div className=''>
                          <div className="space-x-2">
                            <Checkbox 
                              id="r1"
                              value="AI and Robotics Lab"
                              checked={field.value.includes("AI and Robotics Lab")}
                              onCheckedChange={() => handleCheckboxChange("AI and Robotics Lab")}
                            />
                            <Label htmlFor="r1">AI and Robotics Lab</Label>
                          </div>
                          <div className="space-x-2">
                            <Checkbox
                              id="r2"
                              value="Atal Tinkering Lab"
                              checked={field.value.includes("Atal Tinkering Lab")}
                              onCheckedChange={() => handleCheckboxChange("Atal Tinkering Lab")}
                            />
                            <Label htmlFor="r2">Atal Tinkering Lab</Label>
                          </div>
                          <div className="space-x-2">
                            <Checkbox 
                              id="r3"
                              value="Coding & AI Curriculum" 
                              checked={field.value.includes("Coding & AI Curriculum")}
                              onCheckedChange={() => handleCheckboxChange("Coding & AI Curriculum")}
                            />
                            <Label htmlFor="r3">Coding & AI Curriculum</Label>
                          </div>
                          <div className="space-x-2">
                            <Checkbox 
                              id="r4" 
                              value="AI and Robotics Hardware Kits"  
                              checked={field.value.includes("AI and Robotics Hardware Kits")}
                              onCheckedChange={() => handleCheckboxChange("AI and Robotics Hardware Kits")}
                            />
                            <Label htmlFor="r4">AI and Robotics Hardware Kits</Label>
                          </div>
                        </div>
                      </FormControl>
                      <FormMessage className="text-red_100"/>
                    </FormItem>
                  )}
                /> */}
                <FormField
                  control={form.control}
                  name="message"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-bold text-base ">Message</FormLabel>
                      <FormControl>
                        <Textarea placeholder='Please add as much information as possible to ensure we can fully support you!' {...field} />
                      </FormControl>
                      <FormMessage className="text-red_100"/>
                    </FormItem>
                  )}
                />
                <Button type="submit" className="flex mx-auto bg-primary font-bold text-xl rounded-full text-white_100 ">Submit Demo Request</Button>
              </form>
            </Form>
          </CardContent>
        </Card>
      </main>
    </React.Fragment>
  );
};
