import React from "react"
import MobPrivacyUp from "./MobPrivacyUp"
import MobPrivacyDown from "./MobPrivacyDown"

const PrivacyMobile = () => {

  return (
    <React.Fragment>
      <main className="">
        <div className="mb-3">
            <MobPrivacyUp/>
        </div>
        <div>
            <MobPrivacyDown/>
        </div>
    </main>
    </React.Fragment>
  )
}

export default PrivacyMobile

