import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../ui/dropdown-menu";
import { Button } from "../../../ui/button";
import { CircleUser, Cookie } from "lucide-react";
import actions from "../../../../actions";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const UserIcon = () => {
  const navigate = useNavigate();

  const getUserRole = () => {
    return localStorage.getItem('roles');
  }

  async function handleLogout() {
    const role=btoa('user');
    sessionStorage.setItem('tgw740q89nwe#@@&^wgehdfsgf',role)
    Cookies.remove('token')
    navigate("/");
    window.location.reload();
    try {
      const role = getUserRole()
      const refreshToken = getCookie("refToken");
      if (!refreshToken) {
        // console.error("No refresh token found");
        return;
      }
      const response = await actions.LOGOUT_DATA({
        url:"auth/logout",
        payload:{refreshToken},
      })
      if (response.success === true) { 
        deleteCookie("refToken");
        deleteCookie("token");
        localStorage.removeItem('tgw740q89nwe#@@&^wgehdfsgf')
        localStorage.removeItem('token')
        sessionStorage.removeItem('token')

      } else {
        // console.error("Failed to logout:", response.status);
      }
    } catch (error) {
      // console.error("Error logging out:", error);
    }
  }

  function getCookie(name: string) {
    const cookies = document.cookie.split(";");
    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName.trim() === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }

  function deleteCookie(name: string) {
    document.cookie = `${name}=; Max-Age=0; path=/;`;
  }

  return (
    <React.Fragment>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" size="icon" className="rounded-full">
            <CircleUser className="size-7 md:size-8 lg:size-10 " />
            <span className="sr-only"> user menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel className = "cursor-pointer">My Account</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem className = "cursor-pointer" onClick={handleLogout}>Logout</DropdownMenuItem>
          <DropdownMenuSeparator />
        </DropdownMenuContent>
      </DropdownMenu>
    </React.Fragment>
  );
};

export default UserIcon;
