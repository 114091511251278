"use client"
import { Crown } from 'lucide-react'
import React from 'react'
import { Button } from '../../../ui/button'
import { Link } from 'react-router-dom'

const BreakFormInfo = () => {

  return (
    <React.Fragment>
        <div className="text-start text-white_100">
            <h1 className="text-xl md:text-5xl font-extrabold mb-8 text-center">Develop a Leader this March Break</h1>
            <h2 className="text-xs md:text-2xl font-bold mb-12 text-center">STEM | SOFT SKILLS | OUT DOOR ACTIVITIES</h2>
            <div className="text-start">
            <h3 className="flex gap-3 text-base md:text-xl justify-center"><Crown/>Camps for ages 5 to 14</h3>
            <h3 className="flex gap-3 text-base md:text-xl justify-center"><Crown/>Full or Half-Day Camps</h3>
            <h3 className="flex gap-3 text-base md:text-xl justify-center"><Crown/>Extended Care Available</h3>
            <h3 className="flex gap-3 text-base md:text-xl justify-center"><Crown/>Pickup & Drop Available</h3>
            </div>
            <Link to="#booknow">
            
            <Button className="bg-secondary p-4 mt-6 text-white_100 font-bold border-none flex mx-auto text-xl"  >Book Now</Button>
            </Link>
        </div>
    </React.Fragment>
  )
}

export default BreakFormInfo