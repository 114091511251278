import React, { useEffect, useRef } from "react";
import { Chart, ChartData, ChartOptions, registerables } from "chart.js";
import actions from "../../../../actions";
Chart.register(...registerables);

const LineChart: React.FC = () => {

  const [lineData, setLineData] = React.useState<number[]>([]);
  const [labels, setLabels] = React.useState<string[]>([]);

  const fetchRevenue = async () => {
    try {
      const response = await actions.GET_CHART_DATA({url : "cart/revenuebyentireyear"})
      const result = response;
      console.log(result, "--revenuebyentireyear");
      const revenues = result.map((item: any) =>
        typeof item.revenue === "number" ? item.revenue : 0
      );
      // console.log(revenues, "revenue by year")
      const months = result.map((item: any) => item.month);
      // console.log(months, "months")
      setLineData(revenues);
      setLabels(months);
    } catch (error) {
      console.error("Failed to fetch revenue data:", error);
    }
  };

  React.useEffect(() => {
    fetchRevenue();
  }, []);

  const chartRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (chartRef.current && lineData.length > 0 && labels.length > 0) {
      if ((chartRef.current as any).chartInstance) {
        (chartRef.current as any).chartInstance.destroy();
      }

      const context = chartRef.current.getContext("2d");
      if (!context) return;

      const data: ChartData<"line", number[], string> = {
        labels: labels,
        datasets: [
          {
            label: "Revenue $",
            data: lineData,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
            ],
            borderColor: [
              "rgba(255, 0, 54)",
              "rgba(0, 64, 125)",
              "rgba(255, 181, 0)",
              "rgba(0, 112, 113)",
              "rgba(85, 0, 255)",
            ],
            borderWidth: 1,
            fill: false,
          },
        ],
      };

      const options: ChartOptions<"line"> = {
        scales: {
          x: {
            type: "category",
          },
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1000,
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              usePointStyle: true, 
              pointStyle: 'rect',  
              boxWidth: 10,       
              boxHeight: 10,       
            },
          },
        },
      };

      const newChart = new Chart(context, {
        type: "line",
        data,
        options,
      });

      (chartRef.current as any).chartInstance = newChart;
    }
  }, [lineData, labels]);

  return (
    <div>
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <canvas ref={chartRef} />
      </div>
    </div>
  );
};

export default LineChart;
