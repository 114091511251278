import React from "react";
import { Card } from "../../ui/Card";
import { Separator } from "../../ui/Separator";

const Programs = () => {
  const data = [
    {
      img: "Imagefolder/NewImages/kids_workshop1.jpg",
      name: "Summer Camp ",
      desc: "Empowers your child to become a better leader by honing essential skills.",
    },
    {
      img: "Imagefolder/NewImages/lego_control.jpg",
      name: "Winter Camp ",
      desc: "Exposing campers to a wide range of technologies, including humanoid robots and drones.",
    },
    {
      img: "Imagefolder/NewImages/kidcar.jpg",
      name: "March Break",
      desc: " Equip students with skills and knowledge necessary to thrive for developing live websites.",
    },
  ];

  return (
    <React.Fragment>
      <main className="sm:mt-10">
        <section className="grid place-content-center sm:my-8 text-center">
          <h1 className="mt-10 text-2xl md:text-4xl font-bold">
            MEET THE INNOVATORS AT ROBOTICS
          </h1>
          <Separator className="bg-secondary md:w-[600px] h-1 mx-auto my-2" />
        </section>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 my-5 sm:my-0">
          {data.map((item, index) => {
            return (
              <div key={index} className="grid place-items-center">
                <Card className="p-1 rounded-3xl w-10/12 md:w-10/12">
                  <div className="relative">
                    <div className="w-full">
                      <img
                        className="rounded-3xl h-72 w-full border-double"
                        src={item.img}
                        alt={item.name}
                        width={470}
                        height={310}
                      />
                    </div>
                  </div>
                </Card>
                <div className="rounded-tl-3xl rounded-tr-3xl my-2 text-center text-white_100 text-base w-10/12 font-semibold bg-tertiary p-3 tracking-wider">
                  <h2>
                    {index + 1}. {item.name}
                  </h2>
                </div>
                <div className="w-10/12 md:w-10/12">
                  <p className="line-clamp-2 mb-7 text-center px-2">{item.desc}</p>
                </div>
              </div>
            );
          })}
        </div>
      </main>
    </React.Fragment>
  );
};

export default Programs;
