import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Tabs, TabsList, TabsTrigger } from "../../../ui/Tabs";
import { CircleHelp, Images, List } from "lucide-react";

interface HeadTabsProps {
  value: string;
  title: string;
  icon: any;
  link: string;
}

const tabs: HeadTabsProps[] = [
    {
        value: "blogs",
        title: "Blogs",
        icon: <List className="size-5" />,
        link: "/dashboard/blogs",
    
      },
      {
        value: "faq",
        title: "FAQ",
        icon: <CircleHelp className="size-5" />,
        link: "/dashboard/faq",
      },
      {
        value: "gallery",
        title: "Gallery",
        icon: <Images className="size-5"  />,
        link: "/dashboard/gallery",
      },
];

export default function HeadTabsSite({ children }: { children: React.ReactNode }) {
  const location = useLocation();  

  return (
    <main>
      <Tabs defaultValue="site" className="w-full">
        {/* <TabsList className="grid w-full grid-cols-2 sm:grid-cols-3 gap-4 border border-gray_200 md:px-4 h-14"> */}
        <TabsList className="grid w-full grid-cols-3 gap-3 border border-gray_200   h-14  ">

          {tabs.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <TabsTrigger
                  className={`${
                    item.link === location.pathname
                      ? "border border-gray_200  rounded-md"
                      : ""
                  }`}
                  value={item.value}
                >
                  <NavLink
                    to={item.link}
                    className="flex gap-1 text-wrap md:text-lg text-lg font-bold place-items-center"
                  >
                    {item.icon}
                    <span className="xl:text-lg text-sm text-center">{item.title}</span>
                  </NavLink>
                </TabsTrigger>
              </React.Fragment>
            );
          })}
        </TabsList>
      </Tabs>
      <section className="w-full md:p-4 my-2 rounded-md h-full">
        {children}
      </section>
    </main>
  );
}
