import React from 'react';
import { PetaLevel1 } from './Level1ofPETAICE';

const PETAICE = () => {
    const data=[
        {
            "Level":"VEX"
        },
        {
            "Level":"Dash & Dot Robots"
        },
        {
            "Level":"3D Robomaster DJI"
        }
    ]
    return (
        <div className='grid place-content-center'>
            <div className='space-y-10'>
                <PetaLevel1/>
            {
                    data.map((item,index)=>
                    <>
               <div className={`md:w-[38rem] mx-auto md:h-20 h-16 w-80 border-2 rounded-3xl flex bg-lightblue ${index%2===1?"md:ml-40":""}`}>
                <div className='w-32 border-2 rounded-3xl flex p-1 bg-primary text-white_100'>
                    <div className='h-10 w-10 my-auto bg-gray_900 rounded-full'></div>
                    <div className='text-center font-semibold my-auto ml-2'>Level {index+1+1}</div>
                </div>
                <div className='text-center font-bold my-auto ml-5'>{item.Level}</div>
               </div>
               </>
                    )
                }
            </div>
        </div>
    );
}

export default PETAICE;
