import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { IBlogDocument } from "../../../../typings";
import { Button } from "../../ui/button";

type Props = {
  data: IBlogDocument[];
  blogsPerPage?: number;
};

const Blog = (props: Props) => {
  const { blogsPerPage = 6 } = props;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [data, setData] = React.useState<IBlogDocument[]>([]);
  const [dynamicRouteData, setDynamicRouteData] = React.useState(null);
  const navigate = useNavigate();

  const GetData = async () => {
    const req = await fetch("https://iceroboapi.pickupbiz.in/api/v1/blog/all");
    const res = await req.json();
    setData(res.data);
  };

  React.useEffect(() => {
    GetData();
  }, []);

  const totalPages = Math.ceil(data.length / blogsPerPage);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleClick = (page: number) => {
    setCurrentPage(page);
  };

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = data.slice(indexOfFirstBlog, indexOfLastBlog);

  const HandleRoute = async (id: string) => {
    const req = await fetch(
      `https://iceroboapi.pickupbiz.in/api/v1/blog/getbyid/${id}`
    );
    const res = await req.json();
    setDynamicRouteData(res);
    navigate("/dynamicBlogs", { state: { itemData: res } });
  };

  return (
    <React.Fragment>
      <main className="grid md:grid-cols-3 sm:grid-cols-2 gap-5 py-1 p-5 lg:max-w-screen-2xl w-full mx-auto m-5">
        {currentBlogs.map((item, index) =>
          <section
            key={index}
            className="xl:grid grid-cols-2 gap-5 border border-primary/75 hover:cursor-pointer hover:shadow-primary/65 hover:shadow rounded-xl p-3 sm:p-5"
          >
            <div>
              <img
                className="h-48 rounded-xl w-full hover:brightness-75 hover:transform hover:transition-transform hover:duration-700"
                src={item.image}
                alt=""
              />
            </div>
            <div className="grid grid-cols-1 sm:gap-5 px-4">
              <div onClick={() => HandleRoute(item._id)} className="items-end">
                <h1 className="text-lg xl:line-clamp-4 sm:line-clamp-2 mt-2 text-black_100 font-bold hover:text-secondary">
                  {item.title}
                </h1>
              </div>
              <div className="items-center flex sm:justify-around justify-between gap-2">
                <h5 className="line-clamp-4">
                  {item.publishedDate.slice(0, 10)}
                </h5>
                <h1>
                  {item.author}
                </h1>
              </div>
            </div>
            <Button
              onClick={() => HandleRoute(item._id)}
              className="hover:bg-tertiary hover:text-white_100 m-3 xl:m-0"
            >
              Read more...
            </Button>
          </section>
        )}
      </main>
      <div className="p-1 mb-5 text-white_100 flex justify-center gap-1 flex-wrap">
        <Button
          onClick={handlePrevious}
          disabled={currentPage === 1}
          className={`bg-red_100 text-base w-fit ${currentPage === 1
            ? "opacity-50 cursor-not-allowed"
            : ""}`}
        >
          Previous
        </Button>

        {currentPage > 3 && <span className="text-tertiary px-2">...</span>}

        {currentPage > 2 &&
          <Button
            onClick={() => handleClick(currentPage - 1)}
            className="bg-white_100 border border-tertiary text-tertiary"
          >
            {currentPage - 1}
          </Button>}

        <Button
          onClick={() => handleClick(currentPage)}
          className="bg-primary border border-tertiary text-white_100"
        >
          {currentPage}
        </Button>

        {currentPage < totalPages - 1 &&
          <Button
            onClick={() => handleClick(currentPage + 1)}
            className="bg-white_100 border border-tertiary text-tertiary"
          >
            {currentPage + 1}
          </Button>}

        {/* Ellipsis after the current page range */}
        {currentPage < totalPages - 2 &&
          <span className="text-tertiary px-2">...</span>}

        <Button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className={`bg-tertiary text-base w-fit text-white_100 ${currentPage ===
          totalPages
            ? "opacity-50 cursor-not-allowed"
            : ""}`}
        >
          Next
        </Button>
      </div>
    </React.Fragment>
  );
};

export default Blog;
