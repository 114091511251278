import {
    BadgeInfo,
    Facebook,
    Instagram,
    Linkedin,
    Mail,
    MapPin,
    MessageCircleMore,
    Phone,
    Twitter,
    Youtube,
  } from "lucide-react";
  import React from "react";
import { Link } from "react-router-dom";
  
  
  const FooterComp = () => {
    return (
      <React.Fragment>
        <main className="h-fit w-full bg-primary text-white_100 ">
          <section className="h-fit md:max-w-screen-2xl md:mx-auto grid md:px-16 px-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div className="md:mx-auto p-5 sm:hidden lg:block block">
              <h1 className="text-3xl font-bold">ICE ROBOTICS</h1>
              <h4 className="text-sm">STEM | INNOVATION | LEARNING</h4>
              <section className="flex gap-4 my-5">
                <Link to="https://www.facebook.com/iceroboticscanada/">
                  <Facebook />
                </Link>
  
                <Link to="https://www.linkedin.com/company/ice-robotics-canada">
                  <Linkedin />
                </Link>
  
                <Link to="https://twitter.com/i/flow/login?redirect_after_login=%2FICERoboticsCa">
                  <Twitter />
                </Link>
                <Link to="https://www.instagram.com/ice_robotics/">
                  <Instagram />
                </Link>
                <Link to="https://www.youtube.com/@ICERoboticsCA">
                  <Youtube />
                </Link>
              </section>
            </div>
            <div className=" p-5">
              <h2 className="font-bold grid md:place-content-center">CONTACT US</h2>
  
              <p className="flex gap-2 place-items-center">
                <a href="https://maps.app.goo.gl/BnFYSvvDZocgEwKVA">
                  <MapPin size={18} />
                </a>
                140 La Rose Ave Unit 201, Etobicoke, ON M9P 1B2
              </p>
              <section className=" grid grid-cols-1">
                <p className="flex gap-2 place-items-center">
                  <a href="tel:(416) 619-4555">
                    <Phone size={18} />
                  </a>
                  (416)619-4555/(416) 399-2181
                </p>
                <p className="flex gap-2 place-items-center">
                  {" "}
                  <a href="mailto:info@icerobotics.ca">
                    <Mail size={18} />
                  </a>
                  info@icerobotics.ca
                </p>
              </section>
            </div>
  
            <div className=" p-5 md:mx-auto">
              <h2 className="font-bold ">OPENING HOURS</h2>
              <section className="">
                <p>Monday - Friday : 11am - 7pm</p>
                <p>Saturday - Sunday : 10am - 2pm</p>
                <Link className="" to="/refundPolicy">
                  <p className="flex gap-2 place-items-center font-medium my-2 hover:underline hover:underline-offset-4 active:underline hover:decoration-white_100 active:underline-offset-4">
                    <BadgeInfo size={18} /> Click here for our Refund Policy
                  </p>
                </Link>
              </section>
            </div>
            <div className="sm:block lg:hidden hidden col-span-2  sm:grid sm:grid-cols-2">
              <div className="mx-auto">
                <h1 className="text-3xl font-bold">ICE ROBOTICS</h1>
                <h4 className="text-sm">STEM | INNOVATION | LEARNING</h4>
              </div>
              <div className="flex gap-4 my-5">
                <Link to="https://web.whatsapp.com/">
                  <MessageCircleMore className="hover:underline" />
                </Link>
  
                <Link to="https://www.facebook.com/iceroboticscanada/">
                  <Facebook />
                </Link>
  
                <Link to="https://www.linkedin.com/company/ice-robotics-canada">
                  <Linkedin />
                </Link>
  
                <Link to="https://twitter.com/i/flow/login?redirect_after_login=%2FICERoboticsCa">
                  <Twitter />
                </Link>
                <Link to="https://www.instagram.com/ice_robotics/">
                  <Instagram />
                </Link>
                <Link to="https://www.youtube.com/@ICERoboticsCA">
                  <Youtube />
                </Link>
              </div>
            </div>
          </section>
        </main>
      </React.Fragment>
    );
  };
  
  export default FooterComp;
  