import React, { useState } from "react";
import AddTopic from "./AddTopic";
import TopicList from "./TopicList";
import AddFaq from "./AddFaq";
import FaqTable from "./FaqTable";
import HeadTabsSite from "../HeadTabsSite";

const AdminFaq = () => {
  const [isNew, setIsnew] = useState(false);
  const handleNew = () => {
    setIsnew(!isNew);
  };
  
  return (
    <React.Fragment>
        <HeadTabsSite>
        <main className=" grid gap-5 xl:grid-cols-3 md:grid-cols-2 grid-cols-1">
            <div className= " shadow-border_grey shadow-md rounded-lg" >
                <AddTopic handleNew={handleNew}/>
            </div>
            <div className= " shadow-border_grey shadow-md rounded-lg" >
                <TopicList isNew={isNew}/>
            </div>
            <div className= " shadow-border_grey shadow-md rounded-lg" >
                <AddFaq isNew={isNew} />
            </div>
        </main>
        <section>
            <FaqTable />            
        </section>
        </HeadTabsSite>
    </React.Fragment>
  );
};

export default AdminFaq;
