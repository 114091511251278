import React, { useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import actions from '../../../../actions';

ChartJS.register(ArcElement, Tooltip, Legend);

const Doughnuts = () => {
  const [chartData, setChartData] = useState({
    labels: [],  
    datasets: [
      {
        label: 'Quantities',
        data: [], 
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1,
      },
    ],
  });

  const fetchGetMostTrendProd = async () => {
    try {
      const response = await actions.GET_DATA({url : "cart/getMostTrendingProducts"})
      const apiData = response;  

      const labels = apiData.map((item : any) => item.name); 
      console.log(labels, "product labels") 
      const data = apiData.map((item : any) => item.totalQuantity); 

      const backgroundColor = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
      ];

      const borderColor= [
        "rgba(255, 0, 54)",
        "rgba(0, 64, 125)",
        "rgba(255, 181, 0)",
        "rgba(0, 112, 113)",
        "rgba(85, 0, 255)",
        "rgba(255, 127, 0)",
      ];
      // const borderColor = [
      //   'rgba(255, 99, 132, 1)',
      //   'rgba(54, 162, 235, 1)',
      //   'rgba(255, 206, 86, 1)',
      //   'rgba(75, 192, 192, 1)',
      //   'rgba(153, 102, 255, 1)',
      //   'rgba(255, 159, 64, 1)',
      // ];

      setChartData({
        labels,
        datasets: [
          {
            label: 'Quantities',
            data,
            backgroundColor: backgroundColor.slice(0, labels.length),
            borderColor: borderColor.slice(0, labels.length),
            borderWidth: 1,
          },
        ],
      });
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  useEffect(() => {
    fetchGetMostTrendProd();
  }, []);

  const options = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true, 
          pointStyle: 'rect', 
          boxWidth: 10,
        },
      },
    },
  };

  return <Doughnut className="mx-auto" data={chartData} options={options} />;
};

export default Doughnuts;
