import React from "react";
import { Crown } from "lucide-react";
import SumCampForm from "./SumCampForm";

const SumCampForm_Info = () => {
    return (
        <React.Fragment>
            <div className="backdrop-brightness-75 " style={{ backgroundImage: `url(/Imagefolder/Images/summcamp.png)` }}>
                <main className="">
                    <h1 className="font-bold text-xl lg:text-3xl text-center ">Develop a Leader this Summer</h1>
                    <h3 className="text-base lg:text-xl text-center font-bold mt-2 font-sans">STEM | SOFT SKILLS | OUT DOOR ACTIVITIES</h3>
                    <div className="grid md:grid-cols-2 grid-cols-1">
                        <div className="md:text-lg lg:text-xl font-sans md:mt-28 p-6 mx-auto">
                            <div className="gap-4 space-y-6 ">
                                <div className="flex gap-3">
                                    <Crown className="text-secondary " size={30} />
                                    <h4>Camps for ages 5 to 14</h4>
                                </div>
                                <div className=" flex gap-3 ">
                                    <Crown className="text-secondary " size={30} />
                                    <h4>Full or Half-Day Camps</h4>
                                </div>
                                <div className=" flex gap-3 ">
                                    <Crown className="text-secondary " size={30} />
                                    <h4>Extended Care Available</h4>
                                </div>
                                <div className=" flex gap-3 ">
                                    <Crown className="text-secondary " size={30} />
                                    <h4>Pickup and Drop Available</h4>
                                </div>
                            </div>
                        </div>
                        <section>
                            <SumCampForm />
                        </section>
                    </div>
                </main>
            </div>
        </React.Fragment>
    );
};

export default SumCampForm_Info;
