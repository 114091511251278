import React from "react";

const data = [
  {
    img: "/Imagefolder/Images/globe.svg",
    title: "LEADERSHIP SKILLS",
    para:
      "Soft-skills like communication, team-building and problem-solving help develop leaders."
  },
  {
    img: "/Imagefolder/Images/react.svg",
    title: "STEM EDUCATION ",
    para: "Robotics, Coding and Technology training prepares children for the future."
  },
  {
    img: "/Imagefolder/Images/qa.svg",
    title: "DAILY PROJECTS",
    para: "Apply learning in real time at the end of each day."
  },
];

const Learning = () => {
  return (
    <React.Fragment>
      <main className="bg-[#12416f]">
        <h1 className="text-xl sm:text-3xl text-center p-5 tracking-wide text-primary font-bold">A multi-dimensional learning experience</h1>
        <section className="grid place-items-center">
          <div className="sm:w-7/12 lg:w-full h-fit place-items-center grid lg:grid-cols-3">
            {data.map((item, index) => (
              <div className="grid place-items-center" key={index}>
                <img src={item.img} alt={item.title} className="h-24 w-24  mb-7" />
                <h2 className="text-xl md:text-2xl text-center font-bold text-primary">{item.title}</h2>
                <p className="text-center mb-10 px-10 leading-relaxed text-white_100">{item.para}</p>
              </div>
            ))}
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

export default Learning;
