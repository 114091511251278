import React from 'react';
import Landing from './Routing/Landing';


const App = () => {
  return (
    <React.Fragment>
      {/* <Landing /> */}
      <Landing />
    </React.Fragment>
  );
}

export default App;
