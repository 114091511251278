import * as React from "react";
import CompRight from "./CompRight";
import { Separator } from "@radix-ui/react-separator";
import { RequestForm } from "./RequestForm";

const FreeDemo = () => {
  return (
    <React.Fragment>
      <main className="grid sm:py-5 gap-5" id="requestForm">
        <div className="grid place-items-center gap-5 ">
          <h1 className="text-xl sm:text-2xl md:text-4xl text-center font-bold text-red-500 text-primary">
            Book your FREE Demo Today!
          </h1>
          <Separator className="bg-secondary w-32 h-1 mx-auto" />
          <span className="sm:m-3 px-4 text-base sm:text-lg text-center font-sans">
            Are you ready to experience the power of AI and Robotics? Request a
            free demo of our AI and Robotics ecosystem today!
          </span>
        </div>
        <div className="grid md:grid-cols-2 grid-cols-1 sm:gap-5">
          <div>
            <RequestForm />
          </div>
          <div>
            <CompRight />
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default FreeDemo;
