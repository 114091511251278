import React from "react";
import { Label } from "../../ui/Label";
import HowItWork from "./HowItWork/index";
import SummerConsult from "./SummerExclusive";
import Programs from "./Programs";
import QuestionAns from "./QuestionAns";
import SummerCamp_Info from "./SummerCamp_Info";
import SummerForm from "./SummerForm";
import About_Program from "./About_Program";
import Program_CarouselImage from "./Program_Carousel";

// React.lazy for lazy loading components
// const ComponentC = lazy(() => import("../../Pages/Programs/VideoProg"));

const MainProgram = () => {
  return (
    <React.Fragment>
      <main className=" md:max-w-screen-2xl p-5 mx-auto">
      <div
        className="bg-cover relative h-fit grid place-items-center"
        style={{ backgroundImage: 'url("Imagefolder/Images/summer-camp.png")' }}
      >
        <div className="absolute bg-[black]/75 top-0 w-full h-full"></div>

        <section className="grid grid-cols-1 lg:grid-cols-2 w-10/12 gap-5 sm:gap-10 z-10 m-3 sm:m-10  ">
          <aside className="w-full h-full">
            <SummerCamp_Info />
          </aside>
          <div className="bg-none w-full h-full">
            <Label className="text-xl font-bold text-white_100">
              Talk to us to confirm your seat.
            </Label>
            <SummerForm />
          </div>
        </section>
      </div>

      <div className="my-3 sm:my-5 px-2 sm:p-5">
        <About_Program />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 text-white_100 bg-[#12416f] text-white">
        <section className="grid place-content-center p-5 mx-auto text-center">
          <h1 className="text-xl sm:text-3xl font-bold">Nurture Your Child's Mind!</h1>
          <p className="text-base sm:text-xl my-3">
            Throughout the years, we have inspired innumerable students in their
            journey through robotics education, kindling a unique and profound
            passion for technology within each of them.
          </p>
        </section>
        <section className="grid mx-auto">
          <Program_CarouselImage />
        </section>
      </div>

      <div>
        <HowItWork />
        <SummerConsult />
      </div>

      <div>
        <Programs />
      </div>

      <div className="lg:p-5">
        <QuestionAns />
      </div>
      </main>
    </React.Fragment>
  );
};

export default MainProgram;
