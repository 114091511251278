import { Crown } from 'lucide-react'
import React from 'react'


const WinterInfo = () => {
  return (
    <React.Fragment>
        <div className="text-start text-white_100">
            <h1 className="text-lg md:text-4xl leading-snug font-extrabold mb-4">Develop a Leader this Winter</h1>
            <h2 className="text-xs md:text-2xl font-bold mb-12">STEM | SOFT SKILLS | OUT DOOR ACTIVITIES</h2>
            <div className="text-start">
            <h3 className="flex gap-3 text-base md:text-xl"><Crown/> Camps for ages 5 to 14</h3>
            <h3 className="flex gap-3 text-base md:text-xl"><Crown/>From January 2 to January 5</h3>
            <h3 className="flex gap-3 text-base md:text-xl"><Crown/>Extended Care Available</h3>
            <h3 className="flex gap-3 text-base md:text-xl"><Crown/>Pickup and Drop Available</h3>
            </div>
        </div>
    </React.Fragment>
  )
}

export default WinterInfo