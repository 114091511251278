import { Separator } from "@radix-ui/react-select";
import * as React from "react";

const AtalTinkering = () => {
  return (
    <React.Fragment>
      <main>
        <div className="md:grid md:block hidden grid-cols-1 md:grid-cols-2 gap-5 font-sans">
          {/* Text Section */}
          <div className="gap-y-5 lg:py-20 grid place-items-center md:place-content-start">
            <h1 className="text-primary text-lg md:text-2xl lg:text-3xl text-center font-bold leading-snug font-sans">
              Robotics & STEM Labs in Schools
            </h1>
            <Separator className="bg-secondary w-20 h-1" />
            <h3 className="font-bold text-center">
              300+ Robotics and STEM Labs Established
            </h3>
            <p className="text-base md:text-center lg:text-lg text-center cursor-not-allowed leading-snug font-sans">
              ICE Robotics provides end-to-end support for establishing and
              running Robotics and STEM labs in schools, offering students a
              dynamic environment to explore and excel in robotics, coding,
              engineering, and problem-solving skills.
            </p>
          </div>

          {/* Image Section */}
          <div className="flex items-center justify-center">
            <img
              className="rounded-xl shadow-lg shadow-primary"
              src="https://res.cloudinary.com/dn49bytnn/image/upload/v1729967054/alivybr2dormyrw8dig1.jpg"
              alt="Atal Tinkering Lab"
            />
          </div>
        </div>

        <div className="sm:grid grid-cols-1 md:grid-cols-2 md:hidden block gap-5 font-sans">
          <div className="flex items-center justify-center">
            <img
              className="rounded-xl shadow-lg shadow-primary"
              src="https://res.cloudinary.com/dn49bytnn/image/upload/v1729967054/alivybr2dormyrw8dig1.jpg"
              alt="Atal Tinkering Lab"
            />
          </div>
          <div className="gap-y-5 lg:py-20 grid place-items-center md:place-content-start">
            <h1 className="text-primary text-lg md:text-2xl lg:text-3xl text-center font-bold leading-snug font-sans">
              Atal Tinkering Lab in Schools
            </h1>
            <Separator className="bg-secondary w-20 h-1" />
            <h3 className="font-bold text-center">
              300+ AI and Robotics Labs Established
            </h3>
            <p className="text-base md:text-center lg:text-lg text-center cursor-not-allowed leading-snug font-sans">
              ICE Robotics offers comprehensive end-to-end assistance in
              establishing and running Atal Tinkering Lab in schools, providing
              students with a stimulating environment for exploring and
              mastering STEM, tinkering, the Internet of Things, and
              entrepreneurship.
            </p>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default AtalTinkering;
