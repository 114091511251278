import * as React from "react";

const AboutTop = () => {
  return (
    <React.Fragment>
      <main className="text-white_100 opacity-80 p-5 grid lg:grid-cols-2 gap-5">
        <div className="gap-5 grid text-sm sm:text-base leading-normal sm:leading-loose">
          <div>
            <h1 className="text-xl sm:text-3xl my-3">
              About <b className="text-secondary">ICE Robotics</b>
            </h1>
            <p>
              ICE Robotics, a Not-For-Profit Corporation, offers STEM-based
              (Science, Technology, Engineering, and Mathematics) programs for
              ages 5 and above both on-site and off-campus. We believe that an
              early introduction to STEM with our innovative hands-on courses
              will allow students to approach similar concepts in the classroom
              with a more enriched perspective. As students progress, they are
              stimulated to make real-world connections and explore endless
              possibilities in the world of STEM!
            </p>
            <p>
              Our vast network of professional and experienced teachers provides
              support to students engaged in learning robotics, controlling, and
              other advanced concepts via the latest technologies.
            </p>
          </div>
          <div>
            <p>
              Students at ICE Robotics work with various LEGO® education
              technology, Vernier sensors, Arduino and Raspberry Pi
              microControllers; VEX robotics, 3D printers, drones, and much
              more! Our courses are based on models which are utilized to teach
              students key concepts through an enjoyable and collaborative
              learning environment. Through these tools, students are inspired
              and motivated to apply their learning in new contexts and embark
              on a self-directed learning process.
            </p>
          </div>
          <div>
            <p>
              According to research, children have a far better chance of fully
              understanding and remembering what they have learned when they are
              allowed to use multiple senses during the learning process, as
              well as given the opportunities to explore, tinker, be challenged,
              and share their knowledge. Keeping this goal in mind, we have
              designed various camp programs thus enabling our new generation to
              succeed. Together, we can support and inspire our young minds to
              be active, motivated, and collaborative learners.
            </p>
          </div>
        </div>
        <div className="mx-auto md:grid-cols-2 lg:grid-cols-1 grid gap-5">
          <img
            className="w-full h-[15rem] lg:w-[25rem] hover:brightness-90 hover:transform hover:transition-transform hover:scale-110 hover:duration-700 rounded-lg"
            src="/Imagefolder/Images/AboutTop_Img_1.jpg"
            alt=""
          />
          <img
            className="w-full h-[15rem] lg:w-[25rem] hover:brightness-90 hover:transform hover:transition-transform hover:scale-110 hover:duration-700 rounded-lg"
             src="/Imagefolder/Images/AboutTopImg_2.jpg"
            alt=""
          />
          <img
            className="w-full h-[15rem] lg:w-[25rem] hover:brightness-90 hover:transform hover:transition-transform hover:scale-110 hover:duration-700 rounded-lg"
            src="/Imagefolder/Images/AboutTopImg_3.jpg"
            alt=""
          />
        </div>
      </main>
    </React.Fragment>
  );
};

export default AboutTop;
