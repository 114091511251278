import React from 'react';

const Success = () => {
    return (
        <>
        <div className='grid place-content-center h-screen w-screen bg-[#000000]'>
            <div className=''>
            <img className='' src="https://res.cloudinary.com/dn49bytnn/image/upload/v1726836357/ubf6e7qcv6sdiwiyratn.gif" alt="" />
            </div>
            <div>
                <div>
                <h1 className='text-white_100 text-center text-2xl text-[#03F0C6]'>Payment Successful !</h1>
                </div>
            </div>
        </div>
        </>
    );
}
export default Success;