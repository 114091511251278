import React from 'react'
import HeadTabsUser from './HeadTabsUser'
import UsersList from './UsersList'

const MainUserList = () => {
  return (
    <React.Fragment>
        <HeadTabsUser>
            <UsersList />
        </HeadTabsUser>
    </React.Fragment>
  )
}

export default MainUserList