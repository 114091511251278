import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardFooter } from '../../ui/Card';
import { Button } from '../../ui/button';
import { Input } from '../../ui/Input';
import actions from '../../../actions';
import { toast } from 'sonner'; 
import { useData } from '../../lib/DataContext';
import BASE_URL from '../../../config/index';
const Shop: React.FC = () => {
  const [data, setData] = React.useState<any[]>([]);
  const [filteredData, setFilteredData] = React.useState<any[]>([]);
  const [dynamicRouteData, setDynamicRouteData] = React.useState<any | null>(null);
  const [searchQuery, setSearchQuery] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(true);
  const [catMap, setCatMap] = React.useState<{ [key: string]: string }>({});
  
  const navigate = useNavigate();
const {Handler,isNew,setIsNew}=useData()

  const getData = async () => {
    setLoading(true); 
    try {
      const req = await fetch(`https://iceroboapi.pickupbiz.in/api/v1/items/all`);
      const res = await req.json();
      setData(res.data);
      setFilteredData(res.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const FetchCat = async () => {
    try {
      const req = await fetch('https://iceroboapi.pickupbiz.in/api/v1/items/categories/all');
      const res = await req.json();
      const categoryMap: { [key: string]: string } = {};
      res.data.forEach((category: any) => {
        categoryMap[category._id] = category.name;
      });
      setCatMap(categoryMap);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  React.useEffect(() => {
    FetchCat();
    getData();
  }, []);
  

  const HandleRoute = async (id: string) => {
    try {
      const req = await fetch(`https://iceroboapi.pickupbiz.in/api/v1/items/uniq/${id}`);
      const res = await req.json();
      setDynamicRouteData(res);
      navigate('/dynamicComponent', { state: { itemData: res } });
    } catch (error) {
      console.error('Error fetching unique item:', error);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  React.useEffect(() => {
    const filtered = data.filter(
      (item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        catMap[item.category]?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchQuery, data, catMap]);

  const addToCart = async (id: string) => {
    try {
      const payload = {
        itemId: id,
        quantity: 1,
      };
      const add = await actions.POST_DATA_TOKEN({
        url: "cart/add",
        payload,
      });
      
      if (add.message === "Invalid Token") {
        alert("Please Login to Continue.")
        navigate("/login")
        toast("Failed", {
          description: "Please login to continue",
          action: {
            label: "Login",
            onClick: () => navigate("/login"),
          },
        });
        return; 
      }
      
      if (add.success === true) {
        Handler();
        toast("Success", {
          description: "Item added to cart successfully",
          action: {
            label: "Go to cart",
            onClick: () => navigate("/checkout"),
          },
        });
      }
    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  };
  

  return (
    <main className="lg:max-w-screen-2xl w-full mx-auto p-5">
      {loading ? (
        <div className="text-base md:text-2xl text-center font-bold text-tertiary">
          Loading Products, Please Wait...
        </div>
      ) : (
        <>
          <Input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search Items Here ..."
            className="border w-full border-gray-300 px-3 py-3 rounded-md mb-4"
          />

          <div className="grid xl:grid-cols-4 gap-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1  lg:p-10 lg:max-w-screen-2xl mx-auto w-full">
            {filteredData.map((item, index) => (
              <Card key={index} className="border rounded-3xl border-b-8 border-tertiary">
                <h1 className="text-base text-tertiary uppercase mt-5 font-bold text-center">
                  {catMap[item.category]}
                </h1>
                <div className="lg:px-5 sm:px-10 md:px-5 p-5">
                  <img
                    className="h-[16rem] hover:cursor-pointer sm:h-[12rem] lg:h-[16rem] mx-auto md:w-[25rem] w-[20rem] hover:transform hover:scale-110 hover:brightness-75 hover:duration-700 bg-primary rounded-xl"
                    src={item.image}
                    alt={item.name}
                  />
                </div>
                <div className="px-5 leading-loose tracking-wide">
                  <h2 className="xl:text-xl line-clamp-2 md:text-lg text-xl xl:h-14 md:h-12 sm:h-14 text-primary font-bold text-center">
                    {item.name}
                  </h2>
                  <h2 className="text-center font-medium text-base my-3 text-secondary">
                    {item.subTitle}
                  </h2>
                  <h3 className="text-center text-3xl font-bold">
                    $ {item.price}
                  </h3>
                </div>
                <CardFooter className="px-5 my-4 gap-5 grid sm:grid-cols-1 2xl:grid-cols-2 grid-cols-2">
                  <Button onClick={() => HandleRoute(item._id)} className="w-full text-base bg-tertiary hover:bg-tertiary/85 rounded-full text-white_100">
                    Read More
                  </Button>
                  <Button 
                    className="font-semibold text-base bg-secondary hover:bg-secondary/80 rounded-full text-white_100"
                    onClick={() => addToCart(item._id)}
                  >
                    Add to Cart
                  </Button>
                </CardFooter>
              </Card>
            ))}
          </div>
        </>
      )}
    </main>
  );
};

export default Shop;