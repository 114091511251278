import BASE_URL from "../config/page";
import Cookies from "js-cookie";

type Props = {
  url: string;
};

type IdProps = {
  url: string;
  params: string;
};

const fetchChartData = async (props: Props) => {
    try {
      const res = await fetch(`${BASE_URL}/${props.url}`, {
        cache: "no-store"
      });
      const data = await res.json();
      // console.log("--Line19--", res);
      return data;
    } catch (error) {
      throw new Error("error fetching data");
    }
  };

const fetchData = async (props: Props) => {
  try {
    const res = await fetch(`${BASE_URL}/${props.url}`, {
      cache: "no-store"
    });
    const data = await res.json();
    return data.data;
  } catch (error) {
    throw new Error("error fetching data");
  }
};
const fetchDataWithoutData = async (props: Props) => {
  try {
    const res = await fetch(`${BASE_URL}/${props.url}`, {
      cache: "no-store"
    });
    const data = await res.json();
    return data;
  } catch (error) {
    throw new Error("error fetching data");
  }
};

const fetchDataById = async (props: IdProps) => {
  try {
    const res = await fetch(`${BASE_URL}/${props.url}/${props.params}`, {
      cache: "no-store"
    });
    const data = await res.json();
    return data.data;
  } catch (error) {
    throw new Error("error fetching data");
  }
};

export const getRefToken = async () => {
  const cookiesStore = Cookies;
  const token = Cookies.get("refToken");
  cookiesStore.remove("token");
  try {
    const res = await fetch(`${BASE_URL}/auth/refresh/${token}`);
    const data = await res.json();
    if (data.success === true) {
      const oneDay: number = 24 * 60 * 60 * 1000;
      cookiesStore.set("token", data.accessToken, {
        expires: Date.now() + oneDay
      });
    }
    return data.data;
  } catch (error) {
  }
};

const fetchDataWithToken = async (props: Props) => {
    let token = Cookies.get("token");
  
    const fetchWithToken = async () => {
      const res = await fetch(`${BASE_URL}/${props.url}`, {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "x-access-token": `${token}`
        },
        cache: "no-store"
      });
    //   return res.json();
    const result = await res.json();
      return result;
    };
  
    try {
      let data = await fetchWithToken();
  
      if (data.message === "Invalid Token") {
        // Refresh token
        await getRefToken();
        token = Cookies.get("token"); // Update token after refreshing
  
        // Retry with new token
        data = await fetchWithToken();
      }
  
      return data?.data;

    } catch (error) {
      console.error("Error in fetchDataWithToken:", error);
      return null; // Return null or a fallback in case of error
    }
  };


export { fetchData, fetchDataById, fetchDataWithToken, fetchDataWithoutData, fetchChartData };

