import React from 'react'
import AdminCards from './AdminCards'
import PieCharts from './PieCharts'
import { Card } from '../../../ui/Card'
import Doughnuts from './DoughnutChart'
import { CampAll} from './CampAll'
import { DemoAll } from './DemoAll'

const DashedIndex = () => {
  return (
    <React.Fragment>
      <main className=''>
        <div>
          <AdminCards />
        </div>
        <div className=" grid md:grid-cols-2 gap-5" >
          <Card className="w-full shadow-md shadow-gray_200 border-none p-3" >
            <h1 className="font-bold text-[#00407d] text-xl my-5 text-center">Product Analysis</h1>
            <Doughnuts />
          </Card>
          <Card className=" w-full shadow-md shadow-gray_200 border-none p-3" >
            <h1 className="font-bold text-[#00407d] text-xl my-5 text-center">Order Placed by Categories</h1>
          <PieCharts />
          </Card>
        </div>
        <div className="my-5">
          <h1 className="text-2xl text-primary font-bold" >CampForms Table</h1>
          <CampAll/>
        </div>
        <div className="my-5">
          <h1 className="text-2xl text-primary font-bold" >DemoForms Table</h1>
        <DemoAll/>
        </div>
      </main>
    </React.Fragment>
  )
}

export default DashedIndex
