import React from 'react';
import { CircleCheckBig } from 'lucide-react';

const BannerVirtual = () => {
    return (
        <div style={{backgroundImage: `url(/Imagefolder/virtualclassbanner.jpg)`}} className="h-[30rem] bg-center bg-cover grid place-content-center text-center text-white_100 brightness-90 space-y-3">

            <h1 className="md:text-5xl text-4xl font-bold drop-shadow-md">Virtual STEM Robotics Classes</h1>
            <span className=" md:text-4xl text-2xl font-medium">Minecraft | Coding | App Development</span>
            <h1 className="text-xl text-primary font-extrabold">Get your child started with STEM Education at home</h1>
            <div className="space-y-5">
                <div className="flex place-content-center gap-2">
                    <CircleCheckBig /><h1>8 Sessions - $299*</h1>
                </div>
                <div className="flex place-content-center gap-2">
                    <CircleCheckBig /><h1>Flexible Schedules</h1>
                </div>
                <div className="flex place-content-center gap-2">
                    <CircleCheckBig /><h1>Ages 5 to 14</h1>
                </div>
            </div>
            {/* <div>
            <Button className="w-1/2 border-2 bg-none shadow-md  shadow-secondary hover:shadow-primary border-primary hover:bg-lightblue font-extrabold hover:text-secondary" >Learn More</Button>
            </div> */}
        </div> 
    );
}

export default BannerVirtual;
