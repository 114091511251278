import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../../../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../ui/Select";
import { Input } from "../../../../ui/Input";
import actions from "../../../../../actions";
import PopUp from "../../../../Pages/PopUp";
import { IGallery } from "../../../../../../typings";

const FormSchema = z.object({
  title: z.string({
    required_error: "Topic is Required.",
  }),
  image: z.string().min(2, {
    message: "Image is required.",
  }),
});

type Props = {
  success: boolean;
  fail: boolean;
};

const AddGallery = () => {
  const [topics, setTopics] = React.useState<IGallery[]>([]);
  const [pop, setPop] = React.useState<Props>({
    success: false,
    fail: false,
  });

  const fetchData = async () => {
    try {
      const res: IGallery[] = await actions.GET_DATA({ url: "gallery/all" });
      setTopics(res);
    } catch (error) {
      console.error("Failed to fetch gallery topics:", error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      title: "",
    },
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    // try {
    //   const submit = await actions.POST_DATA({
    //     url: "images/add",
    //     payload: data,
    //   });

    //   if (submit.success) {
    //     form.reset();
    //     setPop({ success: true, fail: false });
    //   }
    // } catch (error) {
    //   setPop({ success: false, fail: true });
    // }
  }

  return (
    <React.Fragment>
      {pop.success && <PopUp heading="Success" description="FAQ Successfully Added." />}
      {pop.fail && <PopUp heading="Error" description="Please Enter the Input." />}
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="p-5 mt-8 leading-loose">
          <h1 className="text-center xl:text-3xl md:text-2xl text-xl font-bold my-3 xl:my-5">
            Add Gallery
          </h1>
          <FormField
            control={form.control}
            name="image"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Image</FormLabel>
                <FormControl>
                  <Input type="file"  {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="title"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Topics</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select Topic" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {topics.map((item, index) => (
                      <SelectItem key={index} value={item._id}>
                        {item.title}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button className="bg-tertiary text-white_100 my-5" type="submit">
            Submit
          </Button>
        </form>
      </Form>
    </React.Fragment>
  );
};

export default AddGallery;

