import React, { useCallback, useEffect, useState } from "react";
import actions from "../../../actions";
import { Input } from "../../ui/Input";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../ui/Accordion";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/Card";

interface IFaqItem {
  question: string;
  answer: string;
}

interface IFAQ {
  topic: {
    _id: string;
    name: string;
  };
  faqs: IFaqItem[]; 
}

const TestFAQ: React.FC = () => {
  const [data, setData] = useState<IFAQ[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<IFAQ[]>([]);

  const fetchFaq = async () => {
    try {
      const res: IFAQ[] = await actions.GET_DATA({ url: "faq/topic/all/faq" });
      setData(res); 
    } catch (error) {
      console.error("Error fetching FAQ data:", error);
    }
  };


  useEffect(() => {
    fetchFaq();
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    searchFaq(e.target.value);
  };

  const searchFaq = useCallback(
    (query: string) => {
      const filteredResults = data
        .map((item) => ({
          topic: item.topic,
          faqs: item.faqs?.filter(
            (faqItem) =>
              faqItem.question.toLowerCase().includes(query.toLowerCase()) ||
              faqItem.answer.toLowerCase().includes(query.toLowerCase())
          ),
        }))
        .filter((item) => item.faqs.length > 0); 
      setSearchResults(filteredResults);
    },
    [data]
  );

  const faqItems = searchQuery ? searchResults : data;

  return (
    <React.Fragment>
      <main className="lg:max-w-screen-2xl p-5 w-full lg:mx-auto grid grid-cols-1 lg:px-5 gap-5 tracking-wide leading-relaxed">
        <div className="text-center text-lg sm:text-3xl text-tertiary font-bold">
          Frequently Asked Questions (FAQ)
          <div>
            <Input
              type="search"
              placeholder="Search FAQ"
              value={searchQuery}
              onChange={handleSearchChange}
              className="mt-4 p-5 rounded-2xl"
            />
          </div>
        </div>

        {faqItems.length === 0 ? (
          <p className="text-center">No FAQs available.</p>
        ) : (
          faqItems.map((item, index) => (
            <Card key={item.topic._id} className="w-full mx-auto border-none shadow-lg rounded-3xl">
              <CardHeader>
                <CardTitle className="text-center text-lg sm:text-3xl text-tertiary font-bold">
                  {item.topic.name}
                </CardTitle>
              </CardHeader>
              <CardContent className="grid gap-5">
                {item.faqs?.map((faqItem, faqIndex) => (
                  <Card
                    key={faqItem.question} 
                    className="rounded-3xl shadow-md border-2 border-tertiary/20 shadow-tertiary/50"
                  >
                    <Accordion type="single" collapsible className="px-5">
                      <AccordionItem value={`faq-${faqIndex}`}>
                        <AccordionTrigger className="font-semibold text-xs sm:text-lg text-start">
                          Q. {faqItem.question}
                        </AccordionTrigger>
                        <AccordionContent className="text-xs sm:text-base">
                          A. {faqItem.answer}
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </Card>
                ))}
              </CardContent>
            </Card>
          ))
        )}
      </main>
    </React.Fragment>
  );
};

export default TestFAQ;
