import { Button } from "../../../../../ui/button";
import React, { useState } from "react";

type Props = {
  Tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
};

const AddTags = (props: Props) => {
  const { Tags, setTags } = props;
  const [inputValue, setInputValue] = useState<string>("");
  const [val, setVal] = useState<string>("");

  const handleAddTag = (e: any) => {
    e.preventDefault();
    if (inputValue.trim() !== "") {
      setTags([...Tags, inputValue]);
      setInputValue("");
    }
    if (inputValue == "") {
      setVal("Please Enter A Tag");
    } else {
      setVal("");
    }
  };
  return (
    <div className="space-y-5 p-5 lg:mt-10 grid place-content-center lg:block rounded-3xl">
      <label className="font-bold text-2xl">
        <pre>Add Tags </pre>
      </label>
      <div className="space-y-5 ">
        <input
          type="text"
          className="p-3 border w-64 lg:w-full h-10 rounded-md"
          placeholder="Enter Tags"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <label className="text-red_100">{val}</label>
        <Button className="w-full" onClick={handleAddTag}>
          Click To Add To Tags
        </Button>
      </div>
      <h1 className="font-semibold text-xl">Added Tags</h1>
      <ul className="bg-primary h-96 overflow-scroll rounded-xl p-5">
        {Tags.map((todo, index) => (
          <li className="text-xl font-semibold" key={index}>
            {index + 1} - {todo}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AddTags;
