import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Tabs, TabsList, TabsTrigger } from "../../../ui/Tabs";
import { Box, FilePlus2, PackagePlus } from "lucide-react";

interface HeadTabsProps {
  value: string;
  title: string;
  icon: any;
  link: string;
}

const tabs: HeadTabsProps[] = [
  {
    value: "coupons",
    title: "Coupon",
    icon: <FilePlus2 className="size-4 sm:size-5" />,
    link: "/dashboard/coupons",
  },
  {
    value: "campCoupons",
    title: "CampCoupon",
    icon: <FilePlus2 className="size-4 sm:size-5" />,
    link: "/dashboard/campCoupons",
  },
  {
    value: "trialClass",
    title: "TrialClass",
    icon: <PackagePlus className="size-4 sm:size-5" />,
    link: "/dashboard/trial",
  },
];

export default function HeadTabsCoupon({ children }: { children: React.ReactNode }) {
  const location = useLocation(); 

  return (
    <main>
      <Tabs defaultValue="site" className="w-full">
        <TabsList className="grid w-full grid-cols-3 gap-4 border border-gray_200 md:px-4 h-14">
          {tabs.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <TabsTrigger
                  className={`${
                    item.link === location.pathname
                      ? "border border-gray_200 rounded-md"
                      : ""
                  }`}
                  value={item.value}
                >
                  <NavLink
                    to={item.link}
                    className="flex gap-1 text-wrap place-items-center md:text-lg text-lg font-bold"
                  >
                    {item.icon}
                    <span className="xl:text-lg place-items-center text-sm">{item.title}</span>
                  </NavLink>
                </TabsTrigger>
              </React.Fragment>
            );
          })}
        </TabsList>
      </Tabs>
      <section className="w-full md:p-4 my-2 rounded-md h-full">
        {children}
      </section>
    </main>
  );
}
