import React from 'react'
import WinterForm from './WinterForm'
import WinterInfo from './WinterInfo'
import { Label } from '../../../ui/Label'
import CampBookNow from './CampBookNow'
import DailyActivity from './DailyActivity'
import Learning from './Learning'

const WinterCamp = () => {
  return (
    <React.Fragment>
      <main className='p-5  md:max-w-screen-2xl mx-auto'>
      <main className="bg-cover relative  h-fit  grid place-items-center h-calc(100vh - 20vh)" style={{ backgroundImage: `url(/Imagefolder/Images/winterCamp.jpg)` }}>
        <div className="absolute bg-black_100/60 top-0 w-full h-full"></div>
        <section className="grid grid-cols-1 lg:grid-cols-2 w-10/12 gap-6 z-10 m-10">
          <aside className="text-center place-content-center w-full h-full;">
            <WinterInfo />
          </aside>
          <div className='bg-none w-full h-full'>
            <Label className="text-xl font-bold text-white_100 ">Fill out the details to get a call back.</Label>
            <WinterForm />
          </div>
        </section>
      </main>
      <div className='my-5'>
        <CampBookNow />
      </div>
      <Learning />
      <DailyActivity />
      </main>
    </React.Fragment>
  )
}

export default WinterCamp