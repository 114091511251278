import React from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../ui/carousel";
import Autoplay from "embla-carousel-autoplay";


const images = [
  {
    img: "/Imagefolder/NewImages/happykid.jpg",
  },
  {
    img: "/Imagefolder/NewImages/kidtrainig1.jpg",
  },
  {
    img: "/Imagefolder/NewImages/kidroboarm.jpg",
  },
  {
    img: "/Imagefolder/NewImages/playtime1.jpg",
  },
  {
    img: "/Imagefolder/NewImages/playtime2.jpg",
  },
  {
    img: "/Imagefolder/NewImages/girlholdingrobo2.jpg",
  },
]

const data = [
  {
    img: "/Imagefolder/NewImages/girlholdingrobo2.jpg",
  },
  {
    img: "/Imagefolder/NewImages/playtime2.jpg",
  },
  {
    img: "/Imagefolder/NewImages/playtime1.jpg",
  },
  {
    img: "/Imagefolder/NewImages/kidroboarm.jpg",
  },
  {
    img: "/Imagefolder/NewImages/kidtrainig1.jpg",
  },
  {
    img: "/Imagefolder/NewImages/happykid.jpg",
  },
]

const Program_CarouselImage = () => {
  return (
    <React.Fragment>
      <main className="md:flex gap-10 p-3 sm:py-6 ">
        <Carousel
          orientation="vertical"
          opts={{ align: "start" }}
          className="w-full max-w-xs"
          plugins={[Autoplay({ delay: 1500 })]}
        >
          <CarouselContent className="-mt-1 h-[350px]">
            {images.map((item, index) => {
              return (
                <CarouselItem key={index}>
                  <div className="">
                    <img
                      className="rounded-3xl border-b-4 border-t-4 border-white_100 shadow-md shadow-white_100"
                      src={item.img}
                      alt=""
                    />
                  </div>
                </CarouselItem>
              )
            })}
          </CarouselContent>
        </Carousel>
        <Carousel
          orientation="vertical"
          opts={{ align: "end" }}
          className="w-full max-w-xs hidden sm:block"
          plugins={[Autoplay({ delay: 1500 })]}
        >
          <CarouselContent className="-mt-1 h-[350px] ">
            {data.map((item, index) => {
              return (
                <CarouselItem key={index}>
                  <div>
                    <img
                      className="rounded-3xl border-b-4 border-t-4 border-white_100 shadow-md  shadow-white_100"
                      src={item.img}
                      alt=""
                    />
                  </div>
                </CarouselItem>
              )
            })}
          </CarouselContent>
        </Carousel>
      </main>
    </React.Fragment>
  );
};

export default Program_CarouselImage;
