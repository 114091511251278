"use client"
import React from 'react'
import { useNavigate } from 'react-router-dom';

const RoadmapLink = () => {
    const navigate = useNavigate();
  return (
    <React.Fragment>
        <main onClick={() => navigate("/roadMap")} className=''>
            <div className=' bg-[#b5ac5b] text-center font-bold text-4xl p-10 hover:underline'>

            Check Robotics Roadmap: <span className='text-[darkblue] hover:cursor-pointer'>click here</span>
            </div>
        </main>
    </React.Fragment>
  )
}

export default RoadmapLink