import React from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "../../../ui/table"

const data = [
    {
        time: [
            { time_am_pm: "9am-11am" },
            { time_am_pm: "11am-12pm" },
            { time_am_pm: "12pm-12:30pm" },
            { time_am_pm: "12:30pm-1:15pm" },
            { time_am_pm: "1:15pm-2:15pm" },
            { time_am_pm: "2:15pm-2:30pm" },
            { time_am_pm: "2:30pm-4pm" },
        ],
        activity: [
            { act: "Robotics" },
            { act: "Creative Writing" },
            { act: "Lunch" },
            { act: "Outdoor Walk" },
            { act: "Math" },
            { act: "Snack Break" },
            { act: "Project of the Day" },
        ]
    }
]

const mobData = [
    {
        time: "9am-11am",
        activity: "Robotics"
    },
    {
        time: "11am-12pm",
        activity: "Creative Writing"
    },
    {
        time: "12pm-12:30pm",
        activity: "Lunch"
    },
    {
        time: "12:30pm-1:15pm",
        activity: "Outdoor Walk"
    },
    {
        time: "1:15pm-2:15pm",
        activity: "Math"
    },
    {
        time: "2:15pm-2:30pm",
        activity: "Snack Break"
    },
    {
        time: "2:30pm-4pm",
        activity: "Project of the Day"
    },

]

const DailyActivity = () => {
    return (
        <React.Fragment>
            <h1 className="text-xl md:text-3xl font-bold text-center my-5">Daily Camp Activities.</h1>
            <Table className="my-5 hidden sm:block w-full text-center md:grid place-items-center">
                {
                    data.map((item, index) => {
                        const { time, activity } = item;
                        const Time = time.map((e) => e)
                        const Activities = activity.map((e) => e)
                        return (
                            <TableBody className="border" key={index}>
                                <TableRow>
                                    {
                                        Time.map((item, index) =>
                                            <TableCell key={index} className="border border-black_100 font-bold text-xl text-primary">{item.time_am_pm}</TableCell>
                                        )
                                    }
                                </TableRow>
                                <TableRow>
                                    {
                                        Activities.map((item, index) =>
                                            <TableCell key={index} className="border text-xl">{item.act}</TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableBody>
                        )
                    })
                }
            </Table>
            <Table className="my-5 sm:hidden text-center ">
                <TableBody>
                    {
                        mobData.map((item, index) => {
                            return (
                                <TableRow className="border" key={index}>
                                    <TableCell className="border border-black_100 font-bold text-primary">{item.time}</TableCell>
                                    <TableCell className="border">{item.activity}</TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </React.Fragment>
    )
}

export default DailyActivity