import React from 'react';
const Failed = () => {
    return (
        <>
        <div className='grid place-content-center h-screen w-screen bg-[#181821]'>
            <div className='grid place-content-center'>
            <img className='lg:h-96 lg:w-96 h-32 w-32 mb-10 ' src="http://res.cloudinary.com/dn49bytnn/image/upload/v1726836602/ehjm2vleiei7strqaa1b.gif" alt="" />
            </div>
            <div>
                <div>
                <h1 className='text-white_100 text-center text-2xl text-[#E74263]'>Payment Failed</h1>
                </div>
            </div>
        </div>
        </>
    );
}
export default Failed;