import React, { useEffect, useState } from "react";
import actions from "../../../../../actions";
import { Trash2 } from "lucide-react";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "../../../../ui/dialog";
import { Button } from "../../../../ui/button";

interface GalleryTopic {
  _id: string;
  title: string;
  createdAt: string;
  updatedAt: string;
}
interface TopicListProps {
  isNew: boolean;
}

const GalleryTopicList = ({ isNew }: TopicListProps) => {
  const [data, setData] = useState<GalleryTopic[]>([]);

  const fetchTopic = async () => {
    try {
      const res = await actions.GET_DATA({ url: "gallery/all" });
      setData(res);
    } catch (error) {
      // console.error("Error fetching the topic", error);
    }
  };

  useEffect(() => {
    fetchTopic();
  }, [isNew]);

  const deleteTopic = async (_id: string) => {
    try {
      const del = await actions.DELETE_DATA({
        url: `gallery/delete`,
        params: _id,
      });

      if (del.success === true) {
        fetchTopic();
      }
    } catch (error) {
    }
  };

  return (
    <React.Fragment>
      <main className="grid h-full rounded-lg">
        <div className="p-4 sm:p-12 h-full  space-y-5 ">
          <div>
            <h1 className="text-center xl:text-3xl md:text-2xl text-xl font-bold my-3 xl:my-5">
              Title List
            </h1>
          </div>

          <div className="h-72 overflow-auto ">
            <ul>
              {data.map((item) => {
                return (
                  <li
                    key={item.title}
                    className="flex justify-between items-center p-2 "
                  >
                    <p>{item.title}</p>

                    <p className=" hover:cursor-pointer ">
                      <Dialog>
                        <DialogTrigger>
                          <Button variant="outline" className="border-none">
                            <Trash2 className="hover:text-red_100 size-6 " />
                          </Button>
                        </DialogTrigger>
                        <DialogContent className="bg-white_100 w-5/6 sm:w-full">
                          <DialogTitle>Confirm Delete</DialogTitle>
                          <DialogDescription className="md:text-lg">
                            Are you sure you want to delete?
                          </DialogDescription>
                          <div className="flex justify-end space-x-2 mt-6">
                            <DialogClose asChild>
                              <Button className="text-white_100">Cancel</Button>
                            </DialogClose>
                            <DialogClose asChild>
                              <Button
                                className="bg-red_100 text-white_100"
                                onClick={() => {
                                  deleteTopic(item._id);
                                }}
                              >
                                Delete
                              </Button>
                            </DialogClose>
                          </div>
                        </DialogContent>
                      </Dialog>
                    </p>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default GalleryTopicList;
