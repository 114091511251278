import steps from "../../Pages/RoadMap/Exadata.json"
import React from 'react';

const ProcessInfographic = () => {
  return (
    <div className="bg-gray-100 p-5">
      <div className="max-w-4xl mx-auto bg-white p-5 rounded-lg shadow-md md:flex flex-col sm:flex-row justify-between items-center space-y-5 sm:space-y-0 grid grid-cols-3">
        {steps.map((step, index) => (
          <div key={index} className="relative flex flex-col items-center text-center w-full sm:w-1/5 ">
            <div className="bg-white rounded-full p-2 w-16 h-16 flex justify-center items-center shadow-lg">
              <img className='rounded-full' src={step.icon} alt={step.title} width={40} height={40} />
            </div>
            <h3 className="text-lg font-semibold text-gray-700 mt-3">{step.title}</h3>
            <p className="text-sm text-gray-600">{step.description}</p>
            {index < steps.length - 1 && (
              <div className="absolute left-1/2 transform -translate-x-1/2 top-1/2 w-full">
                <div className="absolute left-0 top-[-2px] w-full h-[6px] bg-pink-500"></div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
export default ProcessInfographic;