import * as React from 'react'
import PrivacyUp from './PrivacyUp'
import PrivacyDown from './PrivacyDown'
import PrivacyMobile from './PrivacyMobile'

const   PrivacyPolicy = () => {
  return (
    <React.Fragment>
        <main className='  lg:max-w-screen-2xl w-full lg:mx-auto grid md: lg:p-10  gap-5'>
           <div className='hidden lg:block'>
              <PrivacyUp/>
              <PrivacyDown/>
           </div>
           <div className='block lg:hidden'>
                <PrivacyMobile/>
           </div>
        </main>
    </React.Fragment>
  )
}

export default PrivacyPolicy