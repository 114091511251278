import {
    Component,
    Globe,
    Home,
    LineChart,
    Package,
    ShoppingCart,
    SquarePen,
    Users,
  } from "lucide-react";
  import React from "react";
  import { motion } from "framer-motion";
  import { Link, useLocation } from "react-router-dom"; 
  
  const sidData = [
    {
      title: "Dashboard",
      icon: <Home className="h-4 w-4" />,
      link: "/dashboard",
    },
    {
      title: "Users",
      icon: <SquarePen className="h-4 w-4" />,
      link: "/dashboard/users",
    },
    {
      title: "Orders",
      icon: <ShoppingCart className="h-4 w-4" />,
      link: "/dashboard/orders",
    },
    {
      title: "Coupons",
      icon: <Component className="h-4 w-4" />,
      link: "/dashboard/coupons",
    },
    {
      title: "Products",
      icon: <Package className="h-4 w-4" />,
      link: "/dashboard/categories",
    },
    {
      title: "Customer",
      icon: <Users className="h-4 w-4" />,
      link: "/dashboard/customer",
    },
    {
      title: "Analytics",
      icon: <LineChart className="h-4 w-4" />,
      link: "/dashboard/analytics",
    },
    {
      title: "WebSite",
      icon: <Globe className="h-4 w-4" />,
      link: "/dashboard/blogs",
    },
  ];
  
  const SideNav = () => {
    const location = useLocation(); 
  
    return (
      <React.Fragment>
        <nav className="grid font-medium md:w-44 xl:w-56 2xl:w-64 p-3">
          <div className="space-y-3">
            {sidData.map((item, index) => (
              <motion.div
                key={index}
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: index * 0.1, duration: 0.5 }}
              >
                <Link
                  to={item.link} 
                  className={`${
                    location.pathname === item.link
                      ? "flex text-lg bg-gray_200 items-center  gap-3 rounded-lg px-5 xl:px-10 2xl:px-14 p-2 transition-all"
                      : "flex text-lg bg-gray_100 items-center  gap-3 rounded-lg px-5 xl:px-10 2xl:px-14 p-2 transition-all"
                  }`}
                >
                  {item.icon}
                  {item.title}
                </Link>
              </motion.div>
            ))}
          </div>
        </nav>
      </React.Fragment>
    );
  };
  
  export default SideNav;
  