import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { Button } from "../../../ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/Select"
import { Card } from "../../../ui/Card"
import { Input } from "../../../ui/Input"
import { Eye, EyeOff } from "lucide-react"
import React from "react"
import actions from "../../../../actions"
import PopUp from "../../PopUp"
import HeadTabsUser from "./HeadTabsUser"

const FormSchema = z.object({
    name: z.string().min(2, {
      message: "Username must be at least 2 characters.",
    }),
    email: z.string().email({
      message: "Enter a valid email address",
    }),
    password: z.string().min(6, {
      message: "Password must be at least 6 characters.",
    }),
    cPassword: z.string().min(6, {
      message: "Password must be at least 6 characters.",
    }),
    roles: z.enum(["user", "admin"], {
      required_error: "Role is required.",
    }),
  }).refine(data => data.password === data.cPassword, {
    message: "Passwords don't match",
    path: ["cPassword"],
  });

  type Props = {
    success: Boolean;
    fail: Boolean;
  }

const AddUsers =()=> {

    const [pop, setPop] = React.useState<Props>({
        success: false,
        fail: false,
      })

    const [passwordShown, setPasswordShown] = React.useState(false);
    const [cpasswordShown, setCpasswordShown] = React.useState(false);
  
    const toggleCPasswordVisibility = () => {
      setCpasswordShown(!cpasswordShown);
    };
  
    const togglePasswordVisibility = () => {
      setPasswordShown(!passwordShown);
    };
  

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
        name: "",
        email: "",
        password: "",
        cPassword: "",
        roles: undefined,
      },
  })

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try{
        const submit = await actions.POST_DATA({
            url:"auth/register",
            payload: data
        })
        if(submit.success === true) {
            form.reset();
            setPop({
                success: true,
                fail: false,
              })
        }
    }catch(error){
        setPop({
            success: false,
            fail: true,
          })
    }
  }

  return (
    <React.Fragment>
    <HeadTabsUser>
    <main className="p-5">
    {pop.success && (
        <PopUp heading="Success" description="Form Successfully Sent " />
      )}
      {pop.fail && <PopUp heading="Error" description="Form Not Sent  " />}
    <Form {...form}>
      <Card className="p-5 m-5 lg:max-w-screen-md w-full mx-auto border-none shadow-md shadow-tertiary">
        <h1 className="xl:text-3xl 2xl:text-4xl md:text-2xl text-xl font-bold text-center my-3 xl:my-5 ">Add Users</h1>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 md:px-5">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-tertiary font-bold">Name</FormLabel>
                <FormControl>
                  <Input placeholder="Enter Name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-tertiary font-bold">E-mail</FormLabel>
                <FormControl>
                  <Input placeholder="Enter Email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-tertiary font-bold">Password</FormLabel>
                <FormControl>
                  <div className="relative">
                    <Input
                      className="border rounded-lg"
                      type={passwordShown ? "text" : "password"}
                      placeholder="Enter Password"
                      {...field}
                    />
                    <span
                      className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    >
                      {passwordShown ? (
                        <Eye className="text-xl text-tertiary" />
                      ) : (
                        <EyeOff className="text-xl text-tertiary" />
                      )}
                    </span>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="cPassword"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-tertiary font-bold">Confirm Password</FormLabel>
                <FormControl>
                  <div className="relative">
                    <Input
                      className="border rounded-lg"
                      type={cpasswordShown ? "text" : "password"}
                      placeholder="Enter Confirm Password Here ..."
                      {...field}
                    />
                    <span
                      className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                      onClick={toggleCPasswordVisibility}
                    >
                      {cpasswordShown ? (
                        <Eye className="text-xl text-tertiary" />
                      ) : (
                        <EyeOff className="text-xl text-tertiary" />
                      )}
                    </span>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="roles"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-tertiary font-bold">Roles</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a role" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="user">User</SelectItem>
                    <SelectItem value="admin">Admin</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button variant="outline" className="text-primary font-bold text-lg shadow-md shadow-primary" type="submit">
            Submit
          </Button>
        </form>
      </Card>
    </Form>
    </main>
    </HeadTabsUser>
    </React.Fragment>
  )
}

export default AddUsers;