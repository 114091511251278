"use client";
import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import actions from "../../actions";

// Define the types for the API response and context state
interface APIResponseType {
  items?: { id: number; name: string,quantity:number }[]; // Adjust the structure based on your actual response
}

interface DataContextType {
  data: number;
  fetchData: () => void;
  Handler: () => void;
  isNew:boolean;
  setIsNew:any;
}

interface DataProviderProps {
  children: ReactNode;
}

// Create the context with an initial undefined value
const DataContext = createContext<DataContextType | undefined>(undefined);

// Create a provider component
export const DataProvider = ({ children }: DataProviderProps) => {
  const [isNew,setIsNew]=useState(false)
  const [data, setData] = useState<number>(0);

  const fetchData = async () => {
    try {
      const res: APIResponseType = await actions.GET_DATA_TOKEN({ url: "cart/all" });
  
      // Check if response is successful and items are present
      if (res && res.items && Array.isArray(res.items) && res.items.length > 0) {
        const prods = res.items.map((item) => item.quantity).reduce((acc, qty) => acc + qty, 0);
        setData(prods);
      } else {
        setData(0);
      }
    } catch (error) {
      setData(0); // Fallback to 0 in case of an error
    }
  };
  
 
  
  // Fetch data when the provider mounts
  const Handler=()=>{
   setIsNew(!isNew)
   window.location.reload();
 }

  return (
    <DataContext.Provider value={{ data, fetchData,Handler,isNew,setIsNew }}>
      {children}
    </DataContext.Provider>
  );
};

// Custom hook to use the DataContext
export const useData = (): DataContextType => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error("useData must be used within a DataProvider");
  }
  return context;
};
