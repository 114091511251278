import React from 'react'
import { Login } from './Login'

const AdminLogin = () => {
  return (
    <React.Fragment>
        <main  className="bg-top lg:bg-contain bg-no-repeat  h-screen w-screen grid place-content-center lg:grid-cols-3 md:grid-cols-1 bg-gradient-to-tr to-[#31B5C2] via-[#7f50e6] from-primary ">
            <div></div>
            <Login />
            <div></div>
        </main>
    </React.Fragment>
  )
}

export default AdminLogin