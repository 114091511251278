import actions from "../../../../actions";
import React from "react";
import { Button } from "../../../ui/button";
import { MoveLeft } from "lucide-react";
import { Card } from "../../../ui/Card";
import { IStudentData } from "../../../../../typings";
import { Link, useLocation, useParams } from "react-router-dom";

const DynamicCustomer: React.FC = () => {
    const [data, setData] = React.useState(null);
    const location = useLocation();
    const itemData = location.state?.itemData.data;
    React.useEffect(() => {
        if (itemData) {
            setData(itemData);  
        }
    }, [itemData]);
    if (!data) {
        return <div>Loading...</div>;
    }
    
    const Gender = () => {
        if (data.gender === "male") {
            return (
                <Button className="font-bold text-base text-white_100 bg-primary">
                    Male
                </Button>
            );
        } else if (data.gender === "female") {
            return (
                <Button className="font-bold text-base text-white_100 bg-tertiary">
                    Female
                </Button>
            );
        } else if (data.gender === "other") {
            return (
                <Button className="font-bold text-base text-white_100 bg-secondary">
                    Other
                </Button>
            );
        } else {
            return (
                <Button className="font-bold text-base text-white_100 bg-gray_900">
                    Prefer not to say
                </Button>
            );
        }
    };
    
    const DOB = () => {
        const dob = new Date(data.dob);
        const formattedDate = `${dob.getDate()}/${dob.getMonth() + 1
            }/${dob.getFullYear()}`;
        return <div className="capitalize">{formattedDate}</div>;
    };

    return (
        <React.Fragment>
            <section>
                <h1 className="text-2xl md:text-3xl font-bold text-center my-5 ">Student Information</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 w-full mx-auto m-5 p-5 ">
                    <Card className="p-5 leading-loose shadow-md shadow-border_grey">
                        <h1 className="text-xl text-center font-semibold text-secondary my-5">Student Info :</h1>
                        <div className="my-5 ">
                            <h2 className="grid grid-cols-2 font-normal">
                                {" "}
                                Student Name :{" "}
                                <span className="font-bold">
                                    {data.studentFName} {data.studentLName}
                                </span>
                            </h2>
                            <h2 className="grid grid-cols-2 font-normal">
                                {" "}
                                Date of Birth :{" "}
                                <span className="font-bold">
                                    <DOB />
                                </span>{" "}
                            </h2>
                            <h2 className="grid grid-cols-2 font-normal">
                                {" "}
                                Gender :{" "}
                                <span className="">
                                    <Gender />
                                </span>{" "}
                            </h2>
                            <h2 className="grid grid-cols-2 font-normal">
                                {" "}
                                Program : <span className="font-bold">{data.program}</span>{" "}
                            </h2>
                            <p className=" grid grid-cols-2 font-normal">
                                {" "}
                                Address : <span className="font-bold">{data.address}</span>{" "}
                            </p>
                        </div>
                    </Card>
                    <Card className="p-5 leading-loose shadow-md shadow-border_grey">
                        <h1 className="text-xl text-center font-semibold text-secondary my-5">Parent / Guardian Info : </h1>
                        <div className="my-5">
                            <h2 className=" grid grid-cols-2 font-normal">
                                {" "}
                                Parent/Guardian Name :{" "}
                                <span className="font-bold">
                                    {data.guardianFName} {data.guardianLName}
                                </span>{" "}
                            </h2>
                            <h2 className=" grid grid-cols-2 font-normal">
                                {" "}
                                Contact No :{" "}
                                <span className="font-bold">{data.guardianPhone}</span>{" "}
                            </h2>
                            <h1 className="text-xl text-center font-semibold text-secondary my-5">Emergency Contact Info : </h1>
                            <h2 className=" grid grid-cols-2 font-normal">
                                {" "}
                                Full Name:{" "}
                                <span className="font-bold">
                                    {data.emergencyContactFName} {data.emergencyContactLName}
                                </span>{" "}
                            </h2>
                            <h2 className=" grid grid-cols-2 font-normal">
                                {" "}
                                Relation With Student :{" "}
                                <span className="font-bold">{data.relation}</span>{" "}
                            </h2>
                            <h2 className=" grid grid-cols-2 font-normal">
                                {" "}
                                Emergency Contact :{" "}
                                <span className="font-bold">
                                    {data.emergencyContactPhone}
                                </span>{" "}
                            </h2>
                        </div>
                    </Card>
                </div>
            </section>
            <Link to="/dashboard/customer">
                <Button variant="outline">
                    <MoveLeft color="black" />
                </Button>
            </Link>
        </React.Fragment>
    );
};

export default DynamicCustomer;

