import React from "react";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTrigger,
} from "../../ui/sheet";
import { Menu, Phone } from "lucide-react";
import UserAccount from "./UserAccount";
import CartSidebar from "../VirtualClasses/CartSideBar";
import { DataProvider } from "../../lib/DataContext";
import ProfileIcon from "./ProfileIcon";
import SideBar from "./SideBar";
import { useNavigate } from "react-router-dom";
const BurgerMenu = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <DataProvider>
        <main className="grid grid-cols-2 md:grid-cols-4 w-full items-center gap-5 p-5">
          <div
            onClick={() => navigate("/")}
            className=" cursor-pointer  rounded-xl md:p-2 "
          >
            <img
              className="max-h-16"
              src="http://res.cloudinary.com/dn49bytnn/image/upload/v1730974340/gdqa059pbzoj7enxjdrn.png"
              alt="IceRoboLogo"
            />
          </div>
          <div className="md:grid md:grid-cols-3 col-start-3 items-center text-center text-nowrap gap-5 hidden md:block">
            <div className="grid gap-2                                     ">
              <a href="tel:(416) 619-4555 ">
                <Phone className="mx-auto mt-3 cursor-pointer " size={22} />
              </a>
              {/* <h1 className="font-semibold md:py-2 text-sm">Call</h1> */}
              <h1 className="font-semibold py-1 2xl:py-0 text-sm">Call</h1>
            </div>
            <div className=" mx-5">
              <CartSidebar />
            </div>
            <div>
              <UserAccount />
            </div>
          </div>
          <div className=" text-end grid grid-cols-1  gap-10 items-center  ">
            <div className="md:hidden">
              <ProfileIcon />
            </div>
            <Sheet>
              <SheetTrigger asChild>
                <div className="grid place-items-end col-start-2  ">
                  <Menu size={28} />
                </div>
              </SheetTrigger>
              <SheetContent
                // className=" mt-24 overflow-y-scroll bg-white_100"
                className=" mt-24 overflow-y-scroll bg-primary  "
                side="left"
              >
                {/* <SheetHeader className="bg-white_100"> */}
                <SheetHeader className="bg-primary">
                  <SheetDescription>
                    <SideBar />
                  </SheetDescription>
                </SheetHeader>
              </SheetContent>
            </Sheet>
          </div>
        </main>
      </DataProvider>
    </React.Fragment>
  );
};
export default BurgerMenu;
