import React from "react";
import CardInfo from "./CardInfo";
import CardSection from "./CardSection";

const Contact = () => {
  return (
    <React.Fragment>
      <main className="h-calc(100vh - 20vh) bg-cover relative  w-full h-full p-[1.32rem] grid place-items-center" style={{backgroundImage: `url(/Imagefolder/contact.jpg)`}}>
        <div className="absolute bg-black_100/80 top-0 w-full h-full" />
        <h1 className=" text-center font-bold text-4xl text-white_100  z-10 py-10 tracking-widest">Contact Us</h1>
        <section className=" grid grid-cols-1 lg:grid-cols-2 w-10/12 gap-5 z-10">
          <aside className="w-full h-full">
            <CardInfo />
          </aside>
          <div className="bg-none w-full h-full">
            <CardSection />
          </div>
        </section>
      </main>
      <div>
      </div>
    </React.Fragment>
  );
};

export default Contact;
