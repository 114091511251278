import { zodResolver } from "@hookform/resolvers/zod"
import { useForm, useFieldArray } from "react-hook-form"
import { z } from "zod"
import { Button } from "../../../ui/button"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../ui/form"
import { Input } from "../../../ui/Input"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../ui/Select"
import { Textarea } from "../../../ui/Textarea"
import { Card } from "../../../ui/Card"
import actions from "../../../../actions"
import React from "react"
import { IProdData } from "../../../../../typings"
import PopUp from "../../PopUp"
import HeadTabsProduct from "./HeadTabsProduct"

const FormSchema = z.object({
  name: z.string().min(2, {
    message: "Course Name must be at least 2 characters.",
  }).max(20, {
    message: "Course Name must be at most 20 characters.",
  }),
  subTitle: z.enum(["Ages 5 to 8", "Ages 9 to 14", "Ages 9 and Above"], {
    required_error: "A subTitle is required.",
  }),
  category: z.string().nonempty({
    message: "Category is required.",
  }),
  description: z.string().min(20, {
    message: "Description must be at least 20 characters.",
  }),
  price: z.string().min(1, {
    message: "A price is required.",
  }),
  info: z.any()
})

type Props = {
  success: Boolean;
  fail: Boolean;
}

const AddProducts = () => {
  const [data, setData] = React.useState<IProdData[]>([])

  const [pop, setPop] = React.useState<Props>({
    success: false,
    fail: false,
  })

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
      subTitle: undefined,
      category: "",
      description: "",
      price: "",
      info: [""],
    },
  })

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "info",
  })

  const fetchCategory = async () => {
    const res: IProdData[] = await actions.GET_DATA_TOKEN({ url: "items/categories/all" })
    setData(res)
  }

  React.useEffect(() => {
    fetchCategory()
  }, [])

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      const submit = await actions.POST_DATA_TOKEN({
        url: "items/add",
        payload: data,
      })
      if (submit) {
        form.reset()
        setPop({
          success: true,
          fail: false,
        })
      }
    } catch (error) {
      setPop({
        success: false,
        fail: true,
      })
    }
  }

  return (
    <React.Fragment>
      <HeadTabsProduct>
      <main className="p-5">
      {pop.success && (
        <PopUp heading="Success" description="Add Product Successfully " />
      )}
      {pop.fail && <PopUp heading="Error" description="Failed to add product " />}
      <Form {...form} >
        <form onSubmit={form.handleSubmit(onSubmit)} className="grid md:w-1/2 mx-auto gap-5 ">
          <Card className="p-5 border-gray_200 space-y-7 shadow-md shadow-tertiary ">
      <h1 className="font-bold text-center text-xl">Add Product</h1>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem >
                  <FormLabel>Product Name</FormLabel>
                  <FormControl>
                    <Input className="border-gray_200" placeholder="Enter Product Name" {...field} />
                  </FormControl>
                  <FormDescription>
                    Do not exceed 20 characters when entering the product name.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="subTitle"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>SubTitle</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="border-gray_200">
                        <SelectValue placeholder="Choose SubTitle" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="Ages 5 to 8">Ages 5 to 8</SelectItem>
                      <SelectItem value="Ages 9 to 14">Ages 9 to 14</SelectItem>
                      <SelectItem value="Ages 9 and Above">Ages 9 and Above</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="category"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Category</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="border-gray_200">
                        <SelectValue placeholder="Choose Category" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {
                        data.map((item) => (
                          <SelectItem key={item.name} value={item._id}>{item.name}</SelectItem>
                        ))
                      }
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            {fields.map((field, index) => (
              <FormField
                key={field.id}
                control={form.control}
                name={`info.${index}`}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{`Info ${index + 1}`}</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Enter info"
                        className="border-gray_200"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                    <Button type="button" onClick={() => remove(index)}>Remove</Button>
                  </FormItem>
                )}
              />
            ))}
            <Button type="button" onClick={() => append("")}>Add Info</Button>
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Description"
                      className="border-gray_200"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="price"
              render={({ field }) => (
                <FormItem >
                  <FormLabel>Price</FormLabel>
                  <FormControl>
                    <Input className="border-gray_200" placeholder="Enter Price " {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className="w-52 " type="submit">Submit</Button>
          </Card>
        </form>
      </Form>
      </main>
      </HeadTabsProduct>
    </React.Fragment>
  )
}

export default AddProducts
