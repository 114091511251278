"use server"

import BASE_URL from "../config/page"

type props = {
    url: string,
    params: string,
    payload: {
        [key: string]: string | number | boolean | null | undefined | Date,
    }
}

const updateData = async (props: props) => {
    try {
        const response = await fetch(`${BASE_URL}/${props.url}/${props.params}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(props.payload)
        })
        const result = await response.json();
        return result;
    } catch (error) {
        throw error;
    }
}

export default updateData;