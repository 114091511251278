"use client";
import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { Button } from "../../ui/button";
import actions from "../../../actions";
import PopUp from "../PopUp";
import { Input } from "../../ui/Input";
import { Textarea } from "../../ui/Textarea";

// Validation schema
const FormSchema = z.object({
  name: z.string().min(3, {
    message: "Name must be at least 3 characters.",
  }),
  email: z.string().email({
    message: "Invalid email address.",
  }),
  city: z.string().min(2, {
    message: "Enter city name",
  }),
  phone: z
    .string()
    .regex(/^\d{10,11}$/, {
      message: "Phone number must be between 10 and 11 digits and contain only numbers.",
    }),
  message: z
    .string()
    .min(10, {
      message: "Message must be at least 10 characters.",
    })
    .max(160, {
      message: "Message must not be longer than 160 characters.",
    }),
});

type Props = {
  success: Boolean;
  fail: Boolean;
};

const CardSection = () => {
  const [pop, setPop] = React.useState<Props>({
    success: false,
    fail: false,
  });
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
      email: "",
      city: "",
      phone: "",
      message: "",
    },
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      const submit = await actions.POST_DATA({
        url: "contactForm/add",
        payload: data,
      });

      if (submit.success === true) {
        form.reset();
        setPop({ success: true, fail: false });
      }
    } catch (error) {
    }
  }

  const handleNumericInput = (e: React.FormEvent<HTMLInputElement>) => {
  const input = e.currentTarget.value.replace(/\D/g, "");
    e.currentTarget.value = input.slice(0, 11);
  };

  return (
    <React.Fragment>
      {pop.success && (
        <PopUp heading="Success" description="Message Successfully Sent " />
      )}
      {pop.fail && <PopUp heading="Error" description="Message Not Sent " />}
      <main className="pb-5 text-white_100 grid place-items-center">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="w-full xl:w-3/4 space-y-6"
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="Email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid grid-cols-2 gap-2">
              <FormField
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>City</FormLabel>
                    <FormControl>
                      <Input placeholder="City" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="phone"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Phone</FormLabel>
                    <FormControl>
                      {/* Added onInput event to restrict input to numbers only */}
                      <Input
                        placeholder="Phone"
                        {...field}
                        onInput={handleNumericInput}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Message</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Please add as much information as possible to ensure we can fully support you!"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              variant="outline"
              className="uppercase bg-inherit font-bold tracking-wider"
            >
              Submit
            </Button>
          </form>
        </Form>
      </main>
    </React.Fragment>
  );
};

export default CardSection;
