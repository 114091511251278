"use server"
import BASE_URL from "../config/page"
import Cookies from 'js-cookie';
// import { cookies } from "next/headers"
import { getRefToken } from "./GET.actions"
type Props ={
    url: string,
    payload:{
        [key: string]: string | number | boolean | null | undefined | Date |string[] | null | undefined | object ;
    }
}
const postData = async( props:Props )=>{
    try {
        const res = await fetch(`${BASE_URL}/${props.url}`, {
            method: "POST",
            body: JSON.stringify(props.payload),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        })
        const data = await res.json()
        return data
    } catch (error) {
        throw new Error("error fetching data") 
    }
}
const postWithToken = async (props: Props) => {
  const token = Cookies.get('token')
  try {
   
    const res = await fetch(`${BASE_URL}/${props.url}`, {
      method: "POST",
      body: JSON.stringify(props.payload),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "x-access-token": `${token}`,
      },
    });
    const data = await res.json();
      if(data.message ==='Invalid Token'){
        await getRefToken()
        const res = await fetch(`${BASE_URL}/${props.url}`, {
          method: "POST",
          body: JSON.stringify(props.payload),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            "x-access-token": `${token}`,
          },
        });
        const data = await res.json();
        return data
      }
    return data;
  } catch (error) {
    throw new Error("error fetching data");
  }
};
const Login = async (props: Props) => {
  try {
    const cookiesStore = Cookies;
    const res = await fetch(`${BASE_URL}/${props.url}`, {
      method: "POST",
      body: JSON.stringify(props.payload),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await res.json();
    if (data.success === true) {
      localStorage.setItem("accesstoken",data.accessToken,)

      localStorage.setItem('userName',data.data.name)
      const oneDay: number = 24 * 60 * 60 * 1000;
      const sevDays = 168 * oneDay;
      cookiesStore.set("token", data.accessToken, { expires: Date.now() + oneDay });
      cookiesStore.set("refToken", data.refreshToken, { expires: Date.now() + sevDays });
    }
    return data;
  } catch (error) {
    throw new Error("error fetching data");
  }
};
const Logout = async (props:Props) => {
    try {
      const cookiesStore = Cookies;
      const refreshToken = cookiesStore.get("refToken");
  
      if (!refreshToken) {
        // console.error('No refresh token found');
        return { success: false, message: "No refresh token found" };
      }
      const res = await fetch(`${BASE_URL}/${props.url}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        body: JSON.stringify({ refreshToken }),
      });
      if (res.ok) {
        cookiesStore.remove("token");
        cookiesStore.remove("refToken");
        return { success: true, message: "Logout successful" };
      } else {
        const errorData = await res.json();
        return { success: false, message: "Failed to logout" };
      }
    } catch (error) {
      throw new Error("Error logging out");
    }
  };
export { postData, postWithToken, Login, Logout };
