import { Button } from '../../ui/button';
import { MoveLeft } from 'lucide-react';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
interface Section {
  heading: string;
  content: string;
}
const DynamicBlogs = () => {
    const [data, setData] = React.useState(null);
    const location = useLocation();
    const itemData = location.state?.itemData;
    React.useEffect(() => {
        if (itemData) {
            setData(itemData.data);
        }
    }, [itemData]);
    if (!data) {
        return <div>Loading...</div>;
    }
    return (
      <React.Fragment>
      <main className="md:max-w-screen-xl w-full lg:mx-auto grid grid-cols-1 p-5 gap-8 tracking-wide leading-relaxed">
        <Link to="/blogs">
          <Button
            className="mt-8 hover:bg-tertiary hover:text-white_100 "
          >
            <MoveLeft className="mr-2" />
            <h1>Back To Blogs</h1>
          </Button>
        </Link>
        <section className="space-y-8">
          <div className="relative group">
            <img
              className="mx-auto p-2 rounded-3xl h-80 sm:h-[30rem] w-full md:w-[60rem] object-cover md:object-fill shadow-lg"
              src={data.image}
              alt={data.title}
            />
            <div className="absolute inset-y-28 sm:inset-y-48 inset-x-4 md:inset-48 bg-black_100 backdrop-blur-lg text-white_100 p-4 rounded-3xl opacity-0 group-hover:opacity-80 transition duration-300 flex items-center justify-center">
              <h1 className="text-sm sm:text-xl lg:text-2xl text-center font-bold">
                {data.title}
              </h1>
            </div>
          </div>
          {/* Section Mapping */}
          <div className="space-y-5 sm:space-y-10">
            {data.sections.map((section: Section, index: number) => (
              <div key={index} className="space-y-5">
                <p className="text-sm md:text-lg xl:text-xl font-bold text-secondary">
                  {section.heading}
                </p>
                <p className="text-sm sm:text-base md:text-lg">
                  {section.content}
                </p>
              </div>
            ))}
          </div>
          <div className="flex justify-between text-xs sm:text-base font-semibold">
            <h5>{data.publishedDate.slice(0, 10)}</h5>
            <h5>{data.author}</h5>
          </div>
          <div className="flex flex-wrap gap-2 mt-4">
            {data.tags.map((item:any,index:any) => (
              <span
                key={index}
                className="p-2 sm:py-2 text-xs sm:text-base font-semibold border rounded-xl bg-primary text-white_100"
              >
                {item}
              </span>
            ))}
          </div>
        </section>
      </main>
    </React.Fragment>
    );
}
export default DynamicBlogs;