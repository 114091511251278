import * as React from "react";

import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ArrowUpDown,
  ChevronDown,
  Trash2,
} from "lucide-react";
import { Button } from "../../../../ui/button";
import { Checkbox } from "../../../../ui/Checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,

  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,

  DropdownMenuTrigger,
} from "../../../../ui/dropdown-menu";
import { Input } from "../../../../ui/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../ui/table";
import actions from "../../../../../actions";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "../../../../ui/dialog";

interface GalItem {
  _id: string;
  title: string;
  createdAt: string;
  updatedAt: string;
}

declare interface IGallery {
  _id: string;
  video: string;
  parentId: GalItem; // Assuming parentId is a single GalItem
}

export default function GalleryTable() {

  const columns: ColumnDef<IGallery>[] =[

    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          // checked={
          //   table.getIsAllPageRowsSelected() ||
          //   (table.getIsSomePageRowsSelected() && "indeterminate")
          // }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: "video",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          Image
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize ml-4">
          <img src={row.getValue("video")} className="w-40 h-24" alt="" />
        </div>
      ),
    },

    {
      accessorKey: "title",
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}

        >
          Title
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => (
        <div className="capitalize ml-4 ">{row.original.parentId.title}</div>
      ),
    },

    {
      accessorKey: "createdAt",
      header: "Created At",
      cell: ({ row }) => {
        const createdAt = new Date(row.original.parentId.createdAt.slice(0, 10));

        // Format the date as "dd/mm/yyyy"
        const formattedDate = `${createdAt.getDate()}/${
          createdAt.getMonth() + 1
        }/${createdAt.getFullYear()}`;

        return <div className="capitalize">{formattedDate}</div>;
      },
    },

    {
      accessorKey: "updatedAt",
      header: "Updates At",
      cell: ({ row }) => {
        const updatedAt = new Date(row.original.parentId.updatedAt.slice(0, 10));

        // Format the date as "dd/mm/yyyy"
        const formattedDate = `${updatedAt.getDate()}/${
          updatedAt.getMonth() + 1
        }/${updatedAt.getFullYear()}`;

        return <div className="capitalize">{formattedDate}</div>;
      },
    },
    {
      header: "Delete",
      cell: ({ row }) => (
        <div className="capitalize">
          <Dialog>
            <DialogTrigger>
              <Button variant="outline" className="border-none">
                <Trash2 className="hover:text-red_100 size-6 " />
              </Button>
            </DialogTrigger>
            <DialogContent className="bg-white_100 w-5/6 sm:w-full">
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogDescription className="md:text-lg">
                Are you sure you want to delete this image?
              </DialogDescription>
              <div className="flex justify-end space-x-2 mt-6">
                <DialogClose asChild>
                  <Button className="text-white_100">Cancel</Button>
                </DialogClose>
                <DialogClose asChild>
                  <Button
                    className="bg-red_100 text-white_100"
                    onClick={() => {
                      deleteGallery(row.original._id);
                    }}
                  >
                    Delete
                  </Button>
                </DialogClose>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      ),
    },
  ];

  const [data, setData] = React.useState<IGallery[]>([]);

  const fetchGallery = async () => {
    try {
      const res = await actions.GET_DATA({ url: "gallery/videos/get/all" });
      setData(res);
    } catch (error) {
      throw new Error("error fetching data ");
    }
  };

  React.useEffect(() => {
    fetchGallery();
  }, []);

  const deleteGallery = async (id: string) => {
    try {
      const res = await actions.DELETE_DATA({
        url: "gallery/videos/delete",
        params: id,
      });
      if (res.success === true) {
        fetchGallery();
      }
    } catch (error) {
    }
  }

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] = React.useState<
    VisibilityState
  >({});
  const [rowSelection, setRowSelection] = React.useState({});

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <div className="w-full sm:p-5">
      <h1 className="xl:text-3xl 2xl:text-4xl md:text-2xl text-xl font-bold text-center my-3 xl:my-5">
          Gallery
        </h1>
      <div className="flex items-center gap-4 py-4">
        <Input
          placeholder="Search Title"

          value={
            (table.getColumn("title")?.getFilterValue() as string) ?? ""
          }
          onChange={(event) =>
            table.getColumn("title")?.setFilterValue(event.target.value)
          }
          className="max-w-sm"
        />
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline" className="ml-auto">
              Columns <ChevronDown className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) =>
                      column.toggleVisibility(!!value)
                    }
                  >
                    {column.id}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{" "}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </Button>
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}
