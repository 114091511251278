import React from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from '../components/Pages/NavBar/NavBar';
import Footer from '../components/Pages/Footer';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();

  const showNavBar = !['/login', '/admin', '/signUp'].includes(location.pathname);
  const showFooter = !['/login', '/admin', '/signUp'].includes(location.pathname);

  return (
    <div className="flex flex-col min-h-screen">
      {showNavBar && (
        <div className="bg-primary text-white_100 w-full sticky top-0 z-[999]">
          <NavBar />
        </div>
      )}

      <main className="flex-grow">
        {children}
      </main>

      {showFooter && (
        <div className="bg-tertiary">
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Layout;
