import React from 'react'
import CategoriesList from './CategoriesList'
import AddCategories from './AddCategories';
import HeadTabsProduct from './HeadTabsProduct';

const Categories = () => {

    const [isNew, setIsNew]= React.useState(false);

    const handleNew = ()=>{
        setIsNew(!isNew);
    }
    
  return (
    <React.Fragment>
        <HeadTabsProduct>
        <main className='grid grid-cols-1 lg:max-w-screen-lg mx-auto gap-5'>
            <div>
                <AddCategories handleNew={handleNew} />
            </div>
            <div>
                <CategoriesList isNew={isNew} />
            </div>
        </main>
        </HeadTabsProduct>
    </React.Fragment>
  )
}

export default Categories