import { useState } from "react";
import * as React from "react";

const Genius = () => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  
  const handleMouseMove = (e: { currentTarget: { getBoundingClientRect: () => { left: any; top: any; width: any; height: any; }; }; clientX: number; clientY: number; }) => {
    const { left, top, width, height } = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - left;
    const y = e.clientY - top;
    const scale = 2;
    setZoomLevel(1 + scale * Math.sqrt(offsetX * offsetX + offsetY * offsetY));
    setOffsetX(offsetX);
    setOffsetY(offsetY);
  };

  const images = [
    {
      img: "./Imagefolder/NewImages/girlholdingrobo1.jpg"
    },
    {
      img: "./Imagefolder/NewImages/kiddesigning.jpg"
    },
    {
      img: "./Imagefolder/NewImages/kidtraining.jpg"
    },
    {
      img: "./Imagefolder/NewImages/playingminecraft.jpg"
    }
  ];

  const [mainImage, setMainImage] = useState(images[0].img);

  const handleImageHover = (img: React.SetStateAction<string>) => {
    setMainImage(img);
  };

  return (
    <React.Fragment>
      <div className="flex flex-col items-center mt-5">
        <div
          className="image-container overflow-hidden relative"
          onMouseMove={handleMouseMove}
          style={{ width: '350px', height: '350px' }}
        >
          <img
            src={mainImage}
            alt="Main"
            className="absolute top-0 bg-cover left-0 w-full h-full ease-in-out"
            style={{
              transform: `scale(${zoomLevel}) translate(${offsetX * 50}%, ${offsetY * 50}%)`,
              transformOrigin: 'center center'
            }}
          />
        </div>
        <div className="grid gap-[0.6rem] pt-2 grid-cols-4 sm:flex">
          {images.map((data, index) => (
            <div
              key={index}
              className="bg-cover cursor-pointer hover:scale-90"
              onMouseEnter={() => handleImageHover(data.img)}
            >
              <img
                src={data.img}
                alt={`Image ${index + 1}`}
                className="w-20 h-20 object-cover"
              />
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Genius;
