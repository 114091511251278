import React from "react";
import AddCoupons from "./AddCoupons";
import CouponsList from "./CouponsList";
import HeadTabsCoupon from "./HeadTabsCoupon";

const Coupons = () => {
  const [isNew, setIsNew] = React.useState(false);

  const handleNew = () => {
    setIsNew(!isNew);
  };

  return (
    <React.Fragment>
      <HeadTabsCoupon>
      <main className="grid grid-cols-1x md:grid-cols-2 gap-5 p-5">
        <div>
          <AddCoupons handleNew={handleNew} />
        </div>
        <div>
          <CouponsList isNew={isNew} />
        </div>
      </main>
      </HeadTabsCoupon>
    </React.Fragment>
  );
};

export default Coupons;
