import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { Input } from "../../ui/Input";
import { Card } from "../../ui/Card";
import actions from "../../../actions";
import { Eye, EyeOff, Home } from "lucide-react";
import { Label } from "../../ui/Label";
import PopUp from "../HomeFreedemo/PopUp";
import { Link, useNavigate } from "react-router-dom";

const formSchema = z.object({
  email: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
  password: z.string().min(6, {
    message: "Password must be at least 6 characters.",
  }),
  otp: z
  .string()
  .min(6, { message: "OTP Length must be at least 6 characters" })
  .max(6),
});
type Props = {
  success: Boolean;
  fail: Boolean;
};
const Account = () => {
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = React.useState(false);
  const [pop, setPop] = React.useState<Props>({
    success: false,
    fail: false,
  });

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
      otp: "",
    },
  });

  async function onSubmit(data: z.infer<typeof formSchema>) {
    try {
      const submit = await actions.LOGIN_DATA({
        url: "auth/login",
        payload: data,
      });
      if (submit.success === true && submit.data.roles === "user") {
        const role=btoa(submit.data.roles)
        localStorage.setItem("tgw740q89nwe#@@&^wgehdfsgf",role)
        form.reset();
        navigate("/");
        setPop({ success: true, fail: false });
      }
      if (submit.success != true) {
        return setPop({
          success: false,
          fail: true,
        });
      }
    } catch (error) {
      throw new Error("error fetching data");
    }
  }
  return (
    <React.Fragment>
      {pop.success && (
        <PopUp heading="Success" description="Operation Successful" />
      )}
      {pop.fail && (
        <PopUp heading="Error" description="Login failed please try again" />
      )}
      <main className="py-28 sm:py-40 sm:p-5">
      <Form {...form}>
        <Card className="p-8  md:mx-auto w-full md:w-[30rem] lg:w-[35rem]">
          <div className=" w-full mb-5 flex gap-3 justify-between rounded-full">
            <h1 className="text-2xl text-tertiary font-bold text-start">
              {" "}
              Login
            </h1>
            <Home
              onClick={() => navigate("/")}
              className="text-tertiary cursor-pointer font-bold "
            />
          </div>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-6 md:px-5"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-tertiary font-bold">
                    Email
                  </FormLabel>
                  <FormControl>
                    <Input placeholder="Email " {...field} className="w-full" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <Label className="text-tertiary font-bold">Password</Label>
                  <FormControl>
                    <div className="relative">
                      <Input
                        type={passwordShown ? "text" : "password"}
                        placeholder="Enter Password"
                        {...field}
                      />
                      <span
                        className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        {passwordShown ? <Eye /> : <EyeOff />}
                      </span>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
                name="otp"
                render={({ field }) => (
                  <FormItem>
                    <Label className="text-tertiary font-bold">OTP</Label>
                    <FormControl>
                      <div className="relative">
                        <Input placeholder="Enter OTP" {...field} />
                        {/* <span
                          className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                          onClick={togglePasswordVisibility}
                        >
                          {show ? <Eye /> : <EyeOff />}
                        </span> */}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            <div className="grid grid-cols-2 gap-5">
              <Button
                type="submit"
                className=" text-white_100 font-bold text-xl"
              >
                Submit
              </Button>

              <Link to="/signUp">
                <Button type="button" className=" text-white_100 font-bold w-full text-xl">
                  SignUp
                </Button>
              </Link>
            </div>
          </form>
        </Card>
      </Form>
      </main>
    </React.Fragment>
  );
};
export default Account;
