import React from 'react'
import TrialList from './TrialList'
import AddTrial from './AddTrial';
import HeadTabsCoupon from '../Coupons/HeadTabsCoupon';

const MainTrialList
 = () => {

  const [isNew, setIsNew]= React.useState(false);

    const handleNew = ()=>{
        setIsNew(!isNew);
    }

  return (
    <React.Fragment>
      <HeadTabsCoupon>
        <main className='grid grid-cols-1x md:grid-cols-2 gap-5'>
            <div>
                <AddTrial handleNew={handleNew} />
            </div>
            <div>
                <TrialList isNew={isNew} />
            </div>
        </main>
      </HeadTabsCoupon>
    </React.Fragment>
  )
}

export default MainTrialList
