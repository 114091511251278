import * as React from "react"
import { NavigationMenu, NavigationMenuContent, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, NavigationMenuTrigger } from "../../ui/Navigationmenu"
import { cn } from "../../lib/utils"

const data=[
            {
                "stage":"LEGO Spike Prime"
            },
            {
                "stage":"LEGO Mindstorms Mechanical"
            },
            {
                "stage":"LEGO Mindstorms EV3"
            },
            {
                "stage":"LEGO Mindstorms EV3 Vernier Sensors"
            },
        ]

export function PetaLevel1() {
  return (
    <NavigationMenu className="md:ml-40">
      <NavigationMenuList>
        <NavigationMenuItem>
          <div className={`md:w-[38rem] md:h-20 h-16 w-80 border-2 rounded-3xl flex bg-lightblue`}>
            <div className='w-32 border-2 rounded-3xl flex p-1 bg-primary text-white_100'>
            <div className='h-10 w-10 my-auto bg-gray_900 rounded-full'></div>
            <div className='text-center font-semibold my-auto ml-2'>Level 1</div>
            </div>
        <NavigationMenuTrigger className='text-center text-xl font-semibold my-auto ml-2'>Lego</NavigationMenuTrigger>
          </div>
          <NavigationMenuContent className="p-5 space-y-5 bg-white_100 w-72">
          {
                data.map((item,index)=>
                <>
                <div className="space-y-5">
                    <h1 className="md:w-96 text-left">{index+1} . {item.stage}</h1>
                </div>
                </>
                )
            }
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  )
}

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})
ListItem.displayName = "ListItem"
