import React from 'react'
import SummerComp from './SummerComp'
import ConsultComp from './ConsultComp'

const SummerConsult = () => {
  return (
    <React.Fragment>
        <main className='grid grid-cols-1 lg:grid-cols-2 '>
            <div>
                <SummerComp/>
            </div>
            <div className='grid place-content-center'>
                <ConsultComp/>
            </div>
        </main>
    </React.Fragment>
  )
}

export default SummerConsult