import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../../ui/Card';
import { Separator } from '../../ui/Separator';

const data = [
    {
        head_1: "Tera Ice",
        intro: [
            { point: "Beginner level STEM program" },
            { point: "Dedicated to students of age group 5 to 8 yrs" },
            { point: "Introduction to the world of Robotics" }
        ],
        head_2: "Tera Ice Programs",
        programs: [
            { about_program: "Dash and Dot Robots" },
            { about_program: "Ozobot" },
            { about_program: "Lego pneumatics" },
            { about_program: "Simple & Powered Machine" }
        ]
    },
    {
        head_1: "Peta Ice",
        intro: [
            { point: "Intermediate level of the STEM program" },
            { point: "Dedicated to students of age group 9 to 14 yrs" },
            { point: "Practical applications of Robotics" }
        ],
        head_2: "Peta Ice Programs",
        programs: [
            { about_program: "Robomaster DJI" },
            { about_program: "Spike Prime" },
            { about_program: "VEX IQ" },
            { about_program: "Android App Making" }
        ]
    },
    {
        head_1: "Exa Ice",
        intro: [
            { point: "Advanced level STEM program" },
            { point: "Dedicated to students of 14yrs & above" },
            { point: "High-end tech along self customization" }
        ],
        head_2: "Exa Ice Programs",
        programs: [
            { about_program: "Drone Engineering" },
            { about_program: "Arduino 1.0 & 2.0" },
            { about_program: "RC Projects" },
            { about_program: "3D Design & Engineering" }
        ]
    },
    {
        head_1: "Code & Web Design",
        intro: [
            { point: "Introduction to Programming" },
            { point: "Develops critical thinking & solving" },
            { point: "Option to study in class or virtually online" }
        ],
        head_2: "Web Design Programs",
        programs: [
            { about_program: "C & C++" },
            { about_program: "Java & WordPress" },
            { about_program: "HTML & SQL" },
            { about_program: "Python & MongoDB" },
            { about_program: "ASP.net" }
        ]
    },
];

const About_Program = () => {
    return (
        <main className="w-full">
            <h1 className="font-bold text-xl sm:text-3xl bg-white p-5 sm:p-10 text-center">ABOUT OUR PROGRAMS</h1>
            <div className="grid sm:grid-cols-2 lg:grid-cols-4 mx-auto gap-3 px-5 sm:px-0 p-5 sm:gap-8">
                {
                    data.map((item, index) => {
                        const { intro, programs } = item;
                        return (
                            <Card key={index} className="shadow-md  rounded-br-full rounded-bl-full shadow-primary border-primary bg-white">
                                <h1 className="text-primary lg:h-12 text-xl font-bold text-center mt-3 sm:m-3">{item.head_1}</h1>
                                <CardContent>
                                    <ul className="list-disc sm:h-36 md:h-28 lg:h-48 xl:h-36 2xl:h-28 px-3 text-sm sm:text-base">
                                        {
                                            intro.map((e, index) => (
                                                <div key={index} className="flex">
                                                    <li>{e.point}</li>
                                                </div>
                                            ))
                                        }
                                    </ul>
                                    <Separator className="mt-5 h-0.5 bg-secondary" />
                                    <section className="text-center py-3">
                                        <h1 className="text-primary text-xl font-bold my-1 sm:my-3">{item.head_2}</h1>
                                        <div className="gap-3">
                                            <ul className="px-3 text-sm sm:text-base">
                                                {
                                                    programs.map((e, index) => (
                                                        <li key={index}>{e.about_program}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </section>
                                </CardContent>
                            </Card>
                        );
                    })
                }
            </div>
        </main>
    );
}

export default About_Program;
