import React from "react";
import data from "../../../DB/PolicyClasses.json";
import { ArrowBigRightDash } from "lucide-react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../ui/Accordion";
import { Card, CardContent, CardFooter, CardHeader } from "../../ui/Card";

const MobPrivacyUp = () => {
  return (
    <React.Fragment>
      <main className="lg:max-w-screen-2xl w-full lg:mx-auto grid grid-cols-1 lg:p-5 gap-5">
        {data.map((item, index) => {
          const { head, detail, tabs } = item;
          const TABS = tabs?.map((e) => e);
          return (
            <Card key={index} className="w-full mx-auto grid gap-3 border-none text-xs">
              <CardHeader>
                <h1 className="text-lg font-extrabold flex gap-2 place-items-center text-tertiary">
                  <ArrowBigRightDash />
                  {head}
                </h1>
                <p className="px-3">{detail}</p>
              </CardHeader>

              <CardContent>
                {TABS?.map((item, index) => {
                  return (
                    <Accordion key={index} type="single" collapsible className="sm:px-5">
                      <AccordionItem value="item">
                        <AccordionTrigger className="px-2 text-xs text-center border border-primary font-semibold active:text-secondary hover:text-secondary focus:text-secondary">
                          {item.tab}
                        </AccordionTrigger>

                        <AccordionContent className="text-xs p-3 border-l border-r border-b border-primary">
                          {item.desc}
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  );
                })}
              </CardContent>
              <CardFooter>{item.end}</CardFooter>
            </Card>
          );
        })}
      </main>
    </React.Fragment>
  );
};

export default MobPrivacyUp;
