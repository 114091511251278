import React, { useEffect, useState } from "react";
import actions from "../../../actions";
import { ChevronLeft, ChevronRight, X, Download, Share2 } from "lucide-react";
import { Button } from "../../ui/button";

interface GalItem {
  _id: string;
  title: string;
  createdAt: string;
  updatedAt: string;
}
declare interface IGallery {
  _id: string;
  video: string;
  parentId: GalItem;
}
type GroupedItem = {
  title: string;
  items: IGallery[];
};
const GalleryNew: React.FC = () => {
  const [data, setData] = useState<IGallery[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [flatData, setFlatData] = useState<IGallery[]>([]);
  const fetchGallery = async () => {
    const res: IGallery[] = await actions.GET_DATA({
      url: "gallery/videos/get/all",
    });
    setData(res);
    setFlatData(res);
  };
  useEffect(() => {
    fetchGallery();
  }, []);
  const openModal = (index: number) => {
    setCurrentIndex(index);
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  const groupedItems: GroupedItem[] = [];
  if (data.length > 0) {
    const accumulator = data.reduce((acc, item) => {
      if (!acc[item.parentId.title]) {
        acc[item.parentId.title] = [];
      }
      acc[item.parentId.title].push(item);
      return acc;
    }, {} as { [key: string]: IGallery[] });
    groupedItems.push(...Object.entries(accumulator).map(([title, items]) => ({
      title,
      items,
    })));
  }
  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + flatData.length) % flatData.length);
  };
  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % flatData.length);
  };
  const handleShare = () => {
    const imageUrl = flatData[currentIndex].video;
    if (navigator.share) {
      navigator.share({
        title: 'Check out this image',
        url: imageUrl,
      });
    } else {
      alert('Share functionality is not supported on this browser.');
    }
  };
  const handleDownload = async () => {
    const imageUrl = flatData[currentIndex].video;
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = blobUrl;
    link.download = imageUrl.substring(imageUrl.lastIndexOf('/') + 1);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(blobUrl);
  };
  return (
    <React.Fragment>
      <main className="grid place-content-center sm:m-3 p-3">
        <h1 className="text-xl lg:text-4xl mt-5 font-bold rounded-xl tracking-wider text-center bg-tertiary text-white_100 p-2">
          Our Robotics Exhibit...
        </h1>
        <h3 className="lg:text-2xl font-bold tracking-wider text-center text-tertiary p-1">
          Experience Innovation at Our Robotics Exhibit!
        </h3>
        {groupedItems.map(({ title, items }) => (
          <div key={title} className="grid place-items-center mb-2">
            <section className="border m-3 text-primary sm:px-10 p-1 shadow-md shadow-primary">
              <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-gray-800 text-secondary text-center">
                {title}
              </h2>
            </section>
            <div className="grid grid-cols-3 md:grid-cols-4 2xl:grid-cols-6 lg:grid-cols-5 ">
              {items.map((item) => (
                <div key={item._id} className="m-1 overflow-hidden">
                  <img
                    className="sm:w-64 sm:h-44 w-36 h-32 rounded-md duration-700 object-center ease-in-out transform hover:scale-110 cursor-pointer"
                    src={item.video}
                    alt={item.parentId.title}
                    onClick={() => openModal(flatData.indexOf(item))}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
        {modalOpen && (
          <div className="fixed inset-0 bg-white_100 bg-opacity-75 mt-32 flex items-center z-[999l]">
            <div className="grid place-content-center lg:relative">
              <button onClick={prevImage} className="text-tertiary hover:text-primary">
                <ChevronLeft className="lg:size-20 size-10" />
              </button>
            </div>
            <div className="relative bg-white p-5 rounded-lg shadow-lg max-w-3xl mx-auto overflow-hidden">
              <span
                className="absolute top-5 text-primary font-extrabold right-1 text-gray-800 text-4xl cursor-pointer"
                onClick={closeModal}
              >
                <X className="size-8" />
              </span>
              <div className="flex items-center justify-center">
                <img
                  src={flatData[currentIndex].video}
                  className="w-[800px] h-[450px] lg:h-[350px] 2xl:h-[600px]  object-cover"
                  style={{ maxWidth: '90vw', maxHeight: '80vh' }}
                />
              </div>
              <div className="flex justify-between mt-5">
                <button
                  onClick={handleDownload}
                  className="flex items-center text-tertiary hover:text-primary"
                >
                  <Download className="mr-2" /> Download
                </button>
                <button
                  onClick={handleShare}
                  className="flex items-center text-tertiary hover:text-primary"
                >
                  <Share2 className="mr-2" /> Share
                </button>
              </div>
              <div className="grid place-content-center mt-5">
                <Button
                  className="px-4 bg-tertiary text-white_100 font-bold rounded shadow hover:bg-secondary"
                  onClick={closeModal}
                >
                  Back to Gallery
                </Button>
              </div>
            </div>
            <div className="grid place-content-center lg:relative">
              <button onClick={nextImage} className="text-tertiary hover:text-primary">
                <ChevronRight className="lg:size-20 size-10" />
              </button>
            </div>
          </div>
        )}
      </main>
    </React.Fragment>
  );
};
export default GalleryNew;