import React from "react";
import { Input } from "../../ui/Input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl, 
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { Button } from "../../ui/button";
import { Card } from "../../ui/Card";
import { Textarea } from "../../ui/Textarea";

const FormSchema = z.object({
  name: z.string().min(3, {
    message: "Name must be at least 3 characters.",
  }),
  company_name: z.string().min(3, {
    message: "Company name required",
  }),
  country: z.string().min(2,{
    message:"Enter your country"
  }),
  address: z.string().min(2,{
    message:"Enter your Address"
  }),
  town: z.string().min(2,{
    message:"Enter your town"
  }),
  postal_code: z.string().min(2,{
    message:"Enter your Postal Code"
  }),
  phone: z
  .string()
  .min(10, {
    message: "Enter valid Phone number.",
  })
  .max(11, {
    message: "Phone number must be at least 11 characters.",
  }),  email: z.string().email({
    message: "Invalid email address.",
  }),
  add_info: z.string().min(2),
});

const Checkout = () => {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
      company_name:"",
      country:"",
      address: "",
      town:"",
      postal_code:"",
      phone: "",
      email: "",
      add_info:""
    },
  });

  function onSubmit(values: z.infer<typeof FormSchema>) {
  }
  
  return (
    <React.Fragment>
      <main className="">
      <Card className="p-5 m-2 text-white_100 grid  shadow-none mx-auto max-h-full">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-6 rounded-3xl p-5 bg-primary/20"
          >
            <h1 className="text-2xl font-bold text-center text-black_100">Billing Details</h1>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-black_100 ">Name</FormLabel>
                  <FormControl>
                    <Input className="" {...field}  placeholder="Please Enter Name" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid grid-cols-2 gap-5">
                 <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-black_100 ">Email</FormLabel>
                  <FormControl>
                    <Input className=""  {...field} placeholder="Enter Email"/>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
              <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-black_100 ">Phone</FormLabel>
                  <FormControl>
                    <Input className=""  {...field} placeholder="Enter Phone no."/>
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            </div>
            <div className="grid grid-cols-2 gap-5">
            <FormField
              control={form.control}
              name="company_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-black_100 ">Company Name</FormLabel>
                  <FormControl>
                    <Input className=""  {...field}  placeholder="Enter Company Name" />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="country"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-black_100 ">Country</FormLabel>
                  <FormControl>
                    <Input className="" {...field} placeholder="Enter Country"/>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            </div>
            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-black_100 ">Address</FormLabel>
                  <FormControl>
                    <Input className="" placeholder="House no./Street name" {...field} />
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="grid grid-cols-2 gap-5">
            <FormField
              control={form.control}
              name="town"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-black_100 ">Town</FormLabel>
                  <FormControl>
                    <Input className=""  {...field} placeholder="Enter Town"/>
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="postal_code"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-black_100 ">Postal Code</FormLabel>
                  <FormControl>
                    <Input className=""  {...field} placeholder="Enter Postal Code"/>
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
            <FormField
              control={form.control}
              name="add_info"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-black_100 ">Additional Information (Optional)</FormLabel>
                  <FormControl>
                    <Textarea className="" {...field} placeholder="Enter Additional Information"/>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type="submit"
              variant="outline"
              className="flex w-28 mx-auto uppercase bg-primary hover:bg-tertiary font-bold tracking-wider"
            >
              Submit
            </Button>
          </form>
        </Form>
      </Card>
      </main>
    </React.Fragment>
  );
};
export default Checkout;