"use client"
import * as React from "react";
const AnimatedRobot = () => {
    return (
        <div>
            <div className='fixed z-[999] -left-9 bottom-60 h-0'>
                <img className='lg:size-64 md:size-56 size-36' src="https://res.cloudinary.com/pickup/image/upload/v1719228524/pickupbiz_laundary/wgsbm3loiuvbfcwbw9nn.gif" alt="" />
            </div>
            <div className='z-[999] fixed right-0 h-0 bottom-0 hidden md:block'>
                <img className='size-60 lg:animate-robotflyingReverse' src="https://res.cloudinary.com/pickup/image/upload/v1719228601/pickupbiz_laundary/ci5trdb47sisblg4ex1x.gif" alt="" />
            </div>
        </div>
    );
}

export default AnimatedRobot;
