import React from "react";
import AddBlogLayout from "./AddBlogLayout";
import { BlogsAll } from "../BlogsAll";
import HeadTabsSite from "../../HeadTabsSite";

const AddBlogIndex = () => {
  return (
    <React.Fragment>
      <HeadTabsSite>
        <main>
          <AddBlogLayout />
          <BlogsAll />
        </main>
      </HeadTabsSite>
    </React.Fragment>
  );
};

export default AddBlogIndex;
