import React from "react";

const Notes = () => {
    const activitiesData = [
        {
            category: "Activities For Arts and Crafts",
            activities: ["Friendship Bands", "Doodle Pen Arts (Keychain, Shapes, etc.)"],
        },
        {
            category: "Activities For Talents Fostering class",
            activities: ["Spelling Bee", "Board Game Tournament (Scrabble, Chess, Monopoly)", "Creative Group Storytelling", "Rubik’s Cube"],
        },
        {
            category: "Outdoor Activities",
            activities: ["Swimming", "Basketball", "Soccer"],
        },
    ];

    return (
        <div className="mb-8 text-justify leading-snug font-sans">
            {activitiesData.map((category, index) => (
                <div key={index} className="mb-4">
                    <strong className="">{category.category}</strong>
                    <ul className="list-disc  pl-4">
                        {category.activities.map((activity, idx) => (
                            <li key={idx}>{activity}</li>
                        ))}
                    </ul>
                </div>
            ))}
            <p>Pizza Day will be on Fridays</p>
        </div>
    );
};

export default Notes;
