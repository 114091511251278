import React from "react";
import { motion } from "framer-motion";
import data from "../../../../DB/HowItWork.json";
import { Card, CardContent, CardHeader } from "../../../ui/Card";

const HowItem = () => {
  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <React.Fragment>
      <main className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
        {data.map((item, index) => {
          return (
            <motion.div className="flex"
              key={index}
              variants={cardVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 0.2, delay: index * 0.2 }}
              >
              <Card className="h-full m-2 border-l-8 border-b-4 shadow-md shadow-secondary border-secondary">
                <CardHeader>
                  <div className="flex gap-3 items-center text-lg sm:text-2xl font-semibold rounded-xl">
                    
                    <p className="p-1 h-10 w-10 text-center rounded-tr-2xl rounded-bl-2xl bg-secondary text-white_100">{item.id}</p>
                    <p>{item.title}</p>
                  </div>
                </CardHeader>
                <CardContent>
                  <div>
                    <p className="text-sm">{item.desc}</p>
                  </div>
                </CardContent>
              </Card>

            </motion.div>
          );
        })}
      </main>
    </React.Fragment>
  );
};

export default HowItem;

