import React from "react";
import RegistrationForm from "./RegistrationForm";

const MainRegistration = () => {
  return (
    <React.Fragment>
      <main className="bg-cover relative  w-full h-fit  grid place-items-center" style={{backgroundImage : `url(/Imagefolder/ddd.jpg)`}}>
      <div className=" absolute bg-black_100/80  top-0 w-full h-full"/>
        <h1 className="text-center text-white_100 z-10 font-bold text-4xl  py-5 tracking-widest"> Registration Form</h1>
        <section className="w-full z-10 font-bold md:w-10/12 lg:w-8/12">
          <RegistrationForm />
        </section>
      </main>
    </React.Fragment>
  );
};

export default MainRegistration;
