import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../ui/dialog"
import * as React from "react";

type Props = {
  heading: string;
  description: string;
};

const PopUp = (props: Props) => {
  const [show, setShow] = React.useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <React.Fragment>
      {show && (
        <Dialog open >
          <DialogContent>
            <DialogHeader>
              <DialogTitle>{props.heading}</DialogTitle>
              <DialogDescription>{props.description}</DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default PopUp;
