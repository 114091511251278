import * as React from "react";
import data from "../../../DB/PolicyClasses.json";
import { ArrowBigRightDash } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/Tabs";

const PrivacyUp = () => {

  return (
    <React.Fragment>
      <main className="max-w-screen-2xl h-fit px-10 mx-auto ">
        {data.map((item, index) => {
          const { head, detail, tabs } = item;
          const TABS = tabs?.map((e) => e);

          return (
            <div key={index} className="space-y-5">
              <h1 className="text-lg xl:text-xl font-extrabold flex gap-2 place-items-center text-tertiary">
                <ArrowBigRightDash />
                {head}
              </h1>
              <p>{detail}</p>
              <section className="m-5">
                <Tabs defaultValue="registration&payment">
                  <TabsList className="border border-border_grey rounded-none h-14 overflow-x-auto mx-auto w-full lg:w-[100vh]  xl:w-full flex justify-evenly">


                    {TABS.map((item, index) => (
                      <TabsTrigger
                        key={index}
                        value={item.tab}
                        className="text-base text-primary hover:border hover:border-secondary  focus:border-secondary hover:text-secondary focus:text-secondary"
                      >
                        {item.tab}
                      </TabsTrigger>
                    ))}
                  </TabsList>

                  <div className="my-5 h-28 w-full lg:w-[100vh] mx-auto xl:w-full overflow-y-auto border border-border_grey">
                    <TabsContent
                      value="registration&payment"
                      className="px-5 py-2 hidden sm:block "
                    >
                      <div>
                        All students must register and pay for their classes in
                        advance. Registrations will be accepted on a first-come,
                        first-served basis. Payment can be made online through
                        our website or in person at our office. Once payment is
                        received, a confirmation email will be sent to the
                        student.
                      </div>
                    </TabsContent>
                    {TABS.map((item, index) => (
                      <TabsContent
                        key={index}
                        value={item.tab}
                        className="px-5 "
                      >
                        <div>
                          <div className="">{item.desc}</div>
                        </div>
                      </TabsContent>
                    ))}
                  </div>
                </Tabs>
              </section>
              <p className="pb-14">{item.end}</p>
            </div>
          );
        })}
      </main>
    </React.Fragment>
  );
};

export default PrivacyUp;
