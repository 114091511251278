import React, { useEffect, useState } from "react";
import { ICampCoupons } from "../../../../../typings";

const SummerComp = () => {
  const [coupons, setCoupons] = useState<ICampCoupons[]>([]);
  const [error, setError] = useState<string | null>(null);

const ur="https://iceroboapi.pickupbiz.in/api/v1"
  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await fetch(
          `${ur}/campcoupon/all`
        );
  
        if (response.ok) {
          const res = await response.json();
          setCoupons(res?.data);
        } else {
          const errorData = await response.json();
          setError(errorData.message || "Failed to fetch coupons");
        }
      } catch (error) {
        setError("An error occurred while fetching coupons.");
      }
    };
    fetchCoupons();
  }, []);
  
  return (
    <React.Fragment>
      <main className="text-center py-5 sm:p-5 ">
        <div className=" mb-5 sm:m-5 grid gap-3">
          <h2 className="text-primary text-2xl md:text-3xl font-semibold">
            Claim your Discount Coupon Now!
          </h2>
          <p className="text-sm sm:text-base">
            Book your seat as soon as possible for getting maximum discount.
            Offers automatically applied at booking.
          </p>
        </div>
        <div className="grid gap-3 text-sm sm:text-base">
          {coupons.map((item, index) => {
            return (
              <div key={index}>
                <i>
                  {item.month}{" "}
                  <span className="text-secondary">{item.off}%</span> Off - Book
                  By {item.month} {item.date}!
                </i>
              </div>
            );
          })}
        </div>
      </main>
    </React.Fragment>
  );
};

export default SummerComp;
