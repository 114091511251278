import React from "react";
import { Bot } from "lucide-react";
import { Link } from "react-router-dom";
import UserIcon from "./UserIcon";
import BurgerMenuSheet from "./BurgerMenuSheet";

const BurgerMenu = () => {

  return (
    <React.Fragment>
      <main className="w-full grid grid-cols-12">
        <div className="cursor-pointer col-span-8 hover:shadow hover:shadow-tertiary rounded-xl p-2">
          <Link to="/admin/dashboard" className="font-semibold flex gap-3 items-center">
            <Bot className="size-10" />
            <span className="font-semibold">ICE ROBOTICS</span>
          </Link>
        </div>
        <div className="col-span-4 justify-end flex place-items-center">
          <UserIcon />
          <BurgerMenuSheet />
        </div>
      </main>
    </React.Fragment>
  );
};

export default BurgerMenu;