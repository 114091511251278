import * as React from "react";
const RightSection = () => {
  return (
    <div className="grid xl:pb-24 place-items-center -mt-10 md:mt-20 ">
      
      <img
        className="size-80 xl:size-[30rem]  "
        src="https://thestempedia.com/wp-content/uploads/2023/06/Homepage-image0.png"
        alt=""
      />
    </div>
  );
};
export default RightSection;