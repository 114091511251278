import * as React from "react";
import Mains from "./Main";
import Bottom from "./Bottom";
import AnimatedRobot from "./AnimatedRobot";
const Banner = () => {
  return (
    <>
      <div>
        <AnimatedRobot />
        <div
          className="w-full my-3 h-full bg-no-repeat grid place-content-center 2xl:hidden"
          style={{
            backgroundImage:
              "url('https://thestempedia.com/wp-content/uploads/2023/06/Homepage-bg-1.png')",
          }}
        >
          <Mains />
          <Bottom />
        </div>
        <div
          className="w-full my-3 h-full bg-no-repeat 2xl:block hidden "
          style={{
            backgroundImage:
              "url('Imagefolder/Gallery/banner/herobgimg.png')",
          }}
        >
          <Mains />
          <Bottom />
        </div>
      </div>
    </>
  );
};
export default Banner;
