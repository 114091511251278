import React, { useState } from "react";
import GalleryTopicList from "./GalleryTopicList";
import AddGalleryTopic from "./AddGalleryTopic";
import AddGallery from "./AddGallery";
import GalleryTable from "./GalleryTable";
import HeadTabsSite from "../HeadTabsSite";

const Gallery = () => {
  const [isNew, setIsnew] = useState(false);
  const handleNew = () => {
    setIsnew(!isNew);
  };

  return (
    <React.Fragment>
      <HeadTabsSite>
        <main className="grid gap-5 xl:grid-cols-3 md:grid-cols-2 grid-cols-1 ">
          <div className=" shadow-lg shadow-tertiary/55 border border-tertiary/25 rounded-lg">
            <AddGalleryTopic handleNew={handleNew} />
          </div>
          <div className=" shadow-lg shadow-tertiary/55 border border-tertiary/25 rounded-lg">
            <GalleryTopicList isNew={isNew} />
          </div>
          <div className="shadow-lg shadow-tertiary/55 border border-tertiary/25 rounded-lg">
            <AddGallery />
          </div>
        </main>
        <section>
          <GalleryTable />
        </section>
      </HeadTabsSite>
    </React.Fragment>
  );
};

export default Gallery;
