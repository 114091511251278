import React from 'react';
import Notes from './Notes';

function SummerCampInfo() {
    return (
        <React.Fragment>
            <h2 className='text-lg md:text-3xl font-bold text-center  md:p-6 mt-5 font-sans text-primary'>ICE Robotics Summer Camp Information</h2>
        <div className='grid grid-cols-1 md:grid-cols-12'>
        <div className='text-justify leading-snug font-sans col-span-8 space-y-3 mb-10 '>
            <p className=''>
            <strong>Camp Safety:</strong> ICE Robotics has Summer Camp t-shirts that allow for easy visibility of the students joining the camp on a daily basis. The T-Shirt will be given for their keeping. It is advised that the student brings the t-shirt to camp every day during their time at ICE Robotics Summer Camp.
            </p>
            <p>
                <strong>Food:</strong> Students bring their lunch and water. ICE Robotics provides daily healthy snacks (e.g., carrots, bananas, grapes) at a cost of $20.00 per student. Parents, please e-transfer <b> $30.00</b>  at least 3 days before the Summer Camp starts.<b> No peanut-related foods or soda for safety.</b> Thank you!
            </p>
            <h3></h3>
            <p>
                <strong>Transportation:</strong> Seamless Robotics Adventures Await: Enjoy Camps with Transportation Available in Toronto and GTA
            </p>
            <h3></h3>
            <p>
                <strong>Location:</strong> Ignite Your Passion for Robotics: Join Us at Our Thrilling Camps in Toronto and Mississauga!
            </p>
            <h4></h4>
            <p className=''>
                <strong>Mississauga:</strong><br />
                <>Time:</> 09:00 am to 04:00 pm<br />
                <>Address:</> 1280 Cobalt Street Mississauga ON , L5H 4L8
            </p>
        </div>
        <div className='md:ml-10 col-span-4'>
            <Notes/>
        </div>
        </div>
        </React.Fragment>

    );
}

export default SummerCampInfo;
