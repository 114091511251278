import React from "react";
import { Quote } from "lucide-react";
import { Card } from "../../../ui/Card";

const testimonialsData = [
    {
        name: "Mayank Bhatia",
        rating: 5,
        review:
            "Thrilled with my child's experience at ICE Robotics! Enrolled them in Spike Prime, and it exceeded expectations. The cutting-edge curriculum and innovative projects sparked their creativity. Instructors were not just educators but mentors, fostering a passion for robotics. Highly recommend ICE Robotics for parents eager to cultivate their child's interest in technology and innovation. A truly unique and enriching journey!",
    },
    {
        name: "Hina Azhar",
        rating: 5,
        review:
            "ICE is an amazing institute to learn robotics. It’s very creative and innovative idea. My kids learn coding and build robotics, and enjoy all healthy activities organized by ICE. Staff is very dedicated, hard working and friendly. I encourage their efforts and strongly recommend for the kids to take part in it."
    },
    {
        name: "Bindi Padvani",
        rating: 5,
        review:
            "ICE Robotics is amazing and very professional in teaching my kids the skills required in Coding and Programming. They take keen interest in guiding and helping the kids achieve the abilities  required in their future. Kudos to the ICE team."
    },
    {
        name: "Kaazim Patel",
        rating: 5,
        review:
            "The teachers at the institute are very nice, and explain the concept very well.  Spend a lot of time behind their students, and give all sorts of examples until the students get the concept.  Would definitely recommend this institute for learning any sort of high/ school courses, coding, and build robots."
    },
    {
        name: "Ahmed",
        rating: 5,
        review:
            "ICE robotics helped very much in Grade 11 Math and Physics. Their staff are very professional and explained everything in detail so I could understand. I Recommend you to go there if you want any high-school credits or help in any subjects"
    },
    {
        name: "Natalia Vasiuk",
        rating: 5,
        review:
            "My son, grade 1, enjoys the classes very much. Very friendly staff and flexible schdule. Great start of STEM education. Hightly recommmend!"
    },
    {
        name: "Riddhi Jani",
        rating: 5,
        review:
            "Enrolling my child in LEGO WeDo 2.0 at ICE Robotics was a fantastic decision! The engaging curriculum and hands-on activities ignited my child's interest in robotics and STEM. The instructors were skilled and supportive, creating a positive learning environment. I highly recommend ICE Robotics to every parent seeking an enriching and enjoyable robotics experience for their child. A great investment in their future!"
    },
    {
        name: "Amandita Bita",
        rating: 5,
        review:
            "My daughter had a great time at ICE Robotics. The staff is very nice and she learned about robotics and different programs. The staff is very accommodating when sessions need to be rescheduled. I would recommend ICE Robotics to all."
    },
    {
        name: "Kiran Sainani",
        rating: 5,
        review:
            "My 8-year-old grandson had an amazing time at the coding and robotics camp!The instructors were fantastic, and he learned so much while having a blast. It was hands down his favorite camp of the summer.They also incorporated fun activities like swimming, making it a perfect balance of learning and enjoyment. Highly recommend this camp for curious young minds!"
    }
];

const Testimonial = () => {
    return (
        <React.Fragment>
            <main className="bg-">
                <div>
                    <h1 className="md:text-3xl text-xl text-center p-5 tracking-wide  font-bold leading-snug font-sans">What our Customers say</h1>
                </div>
                <div className="grid sm:grid-cols-2  md:grid-cols-3 xl:grid-cols-5 gap-6 p-4">
                    {testimonialsData.map((item, index) => (
                        <Card key={index} className=" border-primary hover:transform  hover:scale-105  duration-500 hover:cursor-pointer  shadow-lg shadow-primary ">
                            <div className="rounded p-4" >
                                <Quote className="text-3xl  mb-3 text-secondary" />
                                <p className="text-xs sm:text-sm font-sans line-clamp-4 hover:line-clamp-none leading-snug">{item.review}</p>
                                <h3 className="text-lg md:text-2xl mt-3 text-tertiary font-bold font-sans leading-snug">
                                    {item.name}
                                </h3>
                                <h3 className="text-2xl mt-3 text-secondary"> {renderStarRating(item.rating)}</h3>
                            </div>
                        </Card>
                    ))}
                </div>
            </main>
        </React.Fragment>
    );
};

const renderStarRating = (rating: number) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
        stars.push(<span key={i}>&#9733;</span>);
    }
    return stars;
};

export default Testimonial;
