import React, { useEffect, useState } from "react";
import { Button } from "../../ui/button";
import { Card } from "../../ui/Card";
import { ICartItem } from "../../../../typings";
import actions from "../../../actions";
import BASE_URL from "../../../config/page";
import { useData } from "../../lib/DataContext";
import Cookies from "js-cookie";
import { Separator } from "../../ui/Separator";
import { X } from "lucide-react";
const Cart = () => {
  const [cartItem, setCartItem] = useState<ICartItem | null>(null);
  const [discount, setDiscount] = useState(0);
  const { fetchData } = useData();
  const fetchCart = async () => {
    const res = await actions.GET_DATA_TOKEN({ url: "cart/all" });
    setCartItem(res);
  };
  const removeCart = async (id: string) => {
    try {
      const token = Cookies.get("token");
      const remove = await fetch(`${BASE_URL}/cart/delete?itemId=${id}`, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "x-access-token": `${token}`,
        },
      });
      const res = await remove.json();

      if (res.success === true) {
        fetchCart();
      } else {
        alert("failed to remove item ");
        window.location.reload();
      }
      if (remove.status === 200) {
        fetchData();
      }
    } catch (error) {
    }
  };
  useEffect(() => {
    fetchCart();
  }, []);
  
  return (
    <React.Fragment>
      <Card className="border-none shadow-primary  ">
        <h1 className="text-2xl font-bold text-center p-1">Your Cart</h1>
        <div className="h-96 border mb-3 border-black_100 rounded-md overflow-y-scroll p-4">
          {cartItem && cartItem.items.length > 0 ? (
            cartItem.items.map((item, index) => (
              <>
                <div className="p-3">
                  <div className="grid grid-cols-12  items-center  ">
                    <h1 className=" line-clamp-1 hover:line-clamp-none  justify-between col-span-11">
                      {" "}{index + 1}
                      {item.name}
                    </h1>
                    <X size={18}  onClick={() => removeCart(item.itemId)} className="col-span-1 p-0 m-0  place-content-center text-primary hover:text-secondary " />
                  </div>
                  <div className="flex justify-between  ">
                    <h1 className="text-[green] font-bold items-center">
                      Price: $ {item.price}
                    </h1>
                    <h1 className="mx-">Qty: {item.quantity}</h1>
                  </div>
                </div>
                <Separator className="bg-gray_200 h-0.5 " />
              </>
            ))
          ) : (
            <div className="text-center text-xl font-semibold">
              Your cart is empty
            </div>
          )}
        </div>
        <div className="border p-4 rounded-md">
          <h2 className="text-lg font-bold mb-4 text-center">Summary</h2>
          <div className="flex justify-between mb-2">
            <span className="text-gray-500">Total Items:</span>
            <span>
              {cartItem
                ? cartItem.items.reduce((acc, item) => acc + item.quantity, 0)
                : 0}
            </span>
          </div>
          <div className="flex justify-between mb-2">
            <span className="text-gray-500">Total Price:</span>
            <span>
              $
              {cartItem
                ? cartItem.items.reduce(
                    (acc, item) => acc + item.price * item.quantity,
                    0
                  )
                : 0}
            </span>
          </div>
          {discount > 0 && (
            <div className="flex justify-between mb-2">
              <span className="text-gray-500">Discount:</span>
              <span>
                $
                {cartItem
                  ? (
                      cartItem.items.reduce(
                        (acc, item) => acc + item.price * item.quantity,
                        0
                      ) *
                      (discount / 100)
                    ).toFixed(2)
                  : 0}
              </span>
            </div>
          )}
          <Separator className="bg-tertiary h-0.5 " />
          <div className="flex justify-between">
            <span className="text-gray-500">Total Price after Discount:</span>
            <span>${cartItem?.total}</span>
          </div>
        </div>
        <Button className="text-white_100 font-bold  flex mx-auto mt-6 uppercase hover:bg-tertiary">
          Place order
        </Button>
      </Card>
    </React.Fragment>
  );
};
export default Cart;
