"use client";
import React from "react";
import Data from "../../../DB/CoursesData.json";
import { MoveDown, MoveRight } from "lucide-react";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "../../ui/hover-card";
import { ScrollArea } from "../../ui/scroll-area";
import { Separator } from "../../ui/Separator";
import { Button } from "../../ui/button";

const CourseTest = () => {
  return (
    <React.Fragment>
      <main className="grid tracking-normal grid-cols-1 lg:max-w-screen-xl mx-auto leading-relaxed gap-5 ">
        {Data.map((item, index) => {
          const { name, head, img, more, title } = item;
          const sub = head?.map((e) => e);
          const More = more?.map((e) => e);
          return (
            <section key={index} className="grid grid-cols-1  md:grid-cols-2  gap-5 p-5 border rounded-3xl border-l-8 border-tertiary">
              <div className="gap-5 grid lg:p-3">
                <h1 className="text-xl font-bold">{name}</h1>
                <img
                  className="h-[15rem] w-full sm:w-[25rem] mx-auto"
                  src={img}
                  alt={item.name}
                />
              </div>
              <div className="text-base p-3">
                <h1 className="font-bold">{title}</h1>
                <div className="grid grid-cols-1 sm:grid-cols-2 h-fit gap-5 my-5">
                  {sub.map((item, index) => {
                    return <p key={index}>{item.points}</p>;
                  })}
                </div>
                {/* <div
                  onClick={() => navigate("/contact")}
                  className="flex md:col-start-2 gap-3  hover:text-tertiary items-center  p-3 hover:cursor-pointer"
                >
                  <h1 className="font-bold text-xl ">Explore & Enroll</h1>
                  <MoveRight size={30} />
                </div> */}
                <div className="cursor-pointer hover:text-secondary">
                  <HoverCard>
                    <HoverCardTrigger asChild>
                      <Button variant="ghost" className="font-bold text-base flex items-center gap-1 text-primary">
                        More
                        <MoveDown size={18} />
                      </Button>
                    </HoverCardTrigger>
                    <HoverCardContent className="w-80 h-56 p-4  cursor-pointer z-[990] bg-tertiary text-white_100 ">
                      <ScrollArea className="h-full w-full">
                        <div className="p-1">
                          <ul className="list-disc pl-2 tracking-wide ml-3 ">
                            {More.map((item, index) => (
                              <React.Fragment key={index}>
                                <li>{item.subPoints}</li>
                                {index < More.length - 1 && <Separator className="my-2" />}
                              </React.Fragment>
                            ))}
                          </ul>
                        </div>
                      </ScrollArea>
                    </HoverCardContent>
                  </HoverCard>
                </div>
              </div>
            </section>
          );
        })}
      </main>
    </React.Fragment>
  );
};
export default CourseTest;