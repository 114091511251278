import * as React from "react";

export const MiddleSection = () => {
  return (
    <React.Fragment>
      <main className="max-w-screen-2xl h-fit mx-auto grid place-items-center font-sans text-center p-3">
        <div>
          <h1 className="font-bold sm:text-4xl text-lg text-primary leading-snug pb-2">
            Transforming Young Minds into Innovators
          </h1>
          <p className="text-base lg:text-lg xl:px-20 leading-snug">
            ICE Robotics is dedicated to revolutionizing education through
            hands-on, accessible, and technology-driven learning. By partnering
            with industry experts, educators, and renowned institutions, we have
            created a student-centered platform that cultivates essential
            skills. Our programs are designed to spark curiosity and build
            confidence, preparing students to succeed in an increasingly
            tech-focused world.
          </p>
        </div>
      </main>
    </React.Fragment>
  );
};

export default MiddleSection;
