import React, { useEffect, useState } from "react";
import { CircleUserRound, LogOut, User, UserCog } from "lucide-react";
import Cookies from "js-cookie";
import actions from "../../../actions";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/Popover";
import { useNavigate } from "react-router-dom";

const UserAccount = () => {
  
  const [check, setCheck] = useState(false);
  const [log_text, setLog_text] = useState("Login");
  const token = Cookies.get("token");

  const navigate = useNavigate();

  useEffect(() => {
    if (token === undefined) {
      setLog_text("login")
      setCheck(false)
    }
    else{
      const Username=localStorage.getItem('userName')
      setLog_text(Username);
      setCheck(true)
    }
  }, [token]);

  async function handleLogout() {
    try {
      const refreshToken = getCookie("refToken");
      if (!refreshToken) {
        return;
      }

      const response = await actions.LOGOUT_DATA({
        url:"auth/logout",
        payload:{refreshToken}
      })
      
      if (response.success === true) {
        deleteCookie("refToken");
        deleteCookie("token");
        navigate("/login");
      }else{
      }
    } catch (error) {
    }
  }

  
  function getCookie(name: string) {
    const cookies = document.cookie.split(";");
    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split("=");
      if (cookieName.trim() === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }

  function deleteCookie(name: string) {
    document.cookie = `${name}=; Max-Age=0; path=/;`;
  }

  return (
    <React.Fragment>
      <main className=" font-bold">
        <Popover>
          <PopoverTrigger>
            <div className={`grid gap-2 font-semibold`}>
              <CircleUserRound className="mx-auto" size={23} />
              <h1
                className={`text-sm font-semibold ${
                  token ? "text-[#00ff00]" : ""
                } `}
              >
                {log_text}
              </h1>
            </div>
          </PopoverTrigger>
          <PopoverContent className="bg-white_100 mx-5 w-fit text-black_100 z-[999] leading-loose p-4 rounded-lg">
            {
              check==true ?
              <>
               <div
              className="flex items-center w-fit gap-3 cursor-pointer"
            onClick={()=> navigate("/login")}
            >
              {/* <User size={20} />
              <h1 >User Login</h1> */}
            </div>
            <div
              className="flex items-center w-fit gap-3 cursor-pointer"
            onClick={()=> navigate("/admin")}
            >
              <UserCog size={20} />
              <h1 className="">Admin Login</h1>
            </div>

            <div
              className="flex items-center w-fit gap-3 cursor-pointer"
              onClick={handleLogout}
            >
              <LogOut size={20} />
              <h1>Log Out</h1>
            </div>
              </>
              :
              <>
               <div
              className="flex items-center w-fit gap-3 cursor-pointer"
            onClick={()=> navigate("/login")}
            >
              <User size={20} />
              <h1 >User Login </h1>
            </div>
            <div
              className="flex items-center w-fit gap-3 cursor-pointer"
            onClick={()=> navigate("/admin")}
            >
              <UserCog size={20} />
              <h1 className="">Admin Login</h1>
            </div>

            <div
              className="flex items-center w-fit gap-3 cursor-pointer"
              onClick={handleLogout}
            >
              {/* <LogOut size={20} />
              <h1>Log Out</h1> */}
            </div>
              </>
            }
           
          </PopoverContent>
        </Popover>
      </main>
    </React.Fragment>
  );
};

export default UserAccount;
