import React from 'react'
import Data from "../../../DB/ProgramFaqData.json"
import { Card, CardHeader, CardTitle } from '../../ui/Card'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../ui/Accordion'

const QuestionAns = () => {
  return (
    <React.Fragment>
        <Card className='tracking-wide border-none shadow-md leading-relaxed sm:p-5'>
            <CardHeader>
                <CardTitle className='text-center text-xl font-bold sm:text-3xl text-primary'>We are happy to answer your questions!</CardTitle>
            </CardHeader>
            {
                Data.map((item,index)=>{
                    return(
                        <Accordion type='single' key={index} collapsible  className=' md:mx-10 px-7 rounded-3xl shadow-tertiary/50 shadow-md border-tertiary/20 border-2 mb-2'>
                            <AccordionItem value='item' >
                                <AccordionTrigger className='text-sm sm:text-lg text-start '>{index+1}. {item.question}</AccordionTrigger>
                                <AccordionContent className='text-sm sm:text-base '>A. {item.answer}</AccordionContent>
                            </AccordionItem>
                        </Accordion>
                    )
                })
            }
        </Card>
    </React.Fragment>
  )
}

export default QuestionAns