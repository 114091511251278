import React from "react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Button } from "../../ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form"
import { Input } from "../../ui/Input"
import { Card } from "../../ui/Card"
import { useState } from "react"
import actions from "../../../actions"
import { Eye, EyeOff, Home } from "lucide-react"
import { Label } from "../../ui/Label"
import PopUp from "../HomeFreedemo/PopUp"
import { useNavigate } from "react-router-dom"

const FormSchema = z.object({
  email: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }).email({
    message: "Invalid email address",
  }),
  password: z.string().min(8, {
    message: "Password must be at least 8 characters.",
  }),
  otp: z
  .string()
  .min(6, { message: "OTP Length must be at least 6 characters" })
  .max(6),
});

type Props = {
  success: boolean
  fail: boolean
}

export function Login() {
    const navigate = useNavigate();

  const [show, setShow]=useState(false)

  const togglePasswordVisibility= ()=>{
    setShow(!show)
  }
  const [pop, setPop] = useState<Props>({ success: false, fail: false });
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      email: "",
      password: "",
      otp: ""
    },
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      const submit = await actions.LOGIN_DATA({
        url: "auth/login",
        payload: data
      });
      
      if (submit && submit.data.roles === "admin") {
        const role=btoa(submit.data.roles)
        localStorage.setItem("tgw740q89nwe#@@&^wgehdfsgf",role);
        sessionStorage.setItem("tgw740q89nwe#@@&^wgehdfsgf",role);
        localStorage.setItem('accesstoken',submit.accessToken)
        form.reset();
        navigate("/dashboard");
        window.location.reload()
        setPop({ success: true, fail: false });
      } else {
        setPop({ success: false, fail: true });
      }
    } catch (error) {
      setPop({ success: false, fail: true });
    }
  }

  return (
    <React.Fragment>
      {pop.success && (
        <PopUp heading="Success" description="Login Successful" />
      )}
      {pop.fail && (
        <PopUp heading="Error" description="Login failed, please try again" />
      )}
      <main className="py-28 sm:py-40 sm:p-5">
        <Card className="w-fit bg-gray_100 sm:w-[25rem] md:w-[30rem] mx-auto p-10">
        <div className=" w-full mb-5 flex gap-3 justify-between rounded-full">
          <h1 className="text-2xl text-tertiary font-bold text-start">Admin Login</h1>
        <Home onClick={()=>navigate("/")} className="text-tertiary cursor-pointer font-bold " />
        </div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="font-sans font-bold text-lg">Email</FormLabel>
                    <FormControl>
                      <Input className="w-full" placeholder="Email" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <Label className="text-tertiary font-bold">Password</Label>
                    <FormControl>
                      <div className="relative">
                        <Input
                          type={show ? "text" : "password"}
                          placeholder="Enter Password"
                          {...field}
                        />
                        <span
                          className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                          onClick={togglePasswordVisibility}
                        >
                          {show ? <Eye /> : <EyeOff />}
                        </span>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="otp"
                render={({ field }) => (
                  <FormItem>
                    <Label className="text-tertiary font-bold">OTP</Label>
                    <FormControl>
                      <div className="relative">
                        <Input placeholder="Enter OTP" {...field} />
                        {/* <span
                          className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                          onClick={togglePasswordVisibility}
                        >
                          {show ? <Eye /> : <EyeOff />}
                        </span> */}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="grid place-content-center grid-cols-2 gap-10">
                <Button className="bg-primary text-white_100 font-semibold" type="submit">Submit</Button>
                <Button className="bg-primary text-white_100 font-semibold" onClick={() => form.reset()}>Clear</Button>
              </div>
            </form>
          </Form>
        </Card>
      </main>
    </React.Fragment>
  );
}
