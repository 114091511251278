import React from 'react';
import actions from '../../../../actions';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../../ui/Accordion';
import { Button } from '../../../ui/button';
import { Card } from '../../../ui/Card';
import { Input } from '../../../ui/Input';

interface Owner {
    roles: string;
    _id: string;
    name?: string;  
    email: string;
    password: string;
    isActivated: boolean;
    refreshTokens: string[];
    createdAt: string;
    updatedAt: string;
    __v: number;
    tokens: string[];
}

interface OrderItem {
    itemId: string;
    name: string;
    quantity: number;
    price: number;
    _id: string;
}

interface Order {
    _id: string;
    owner: Owner;
    items: OrderItem[];
    bill: number;
    createdAt: string;
    updatedAt: string;
}

const TotalOrders = () => {
    const [orders, setOrders] = React.useState<Order[]>([]);
    const [filteredOrders, setFilteredOrders] = React.useState<Order[]>([]);
    const [searchName, setSearchName] = React.useState<string>('');
    const [searchDate, setSearchDate] = React.useState<string>('');
    const [searchBill, setSearchBill] = React.useState<string>('');

    const fetchOrders = async () => {
        try {
            const res = await actions.GET_DATA_TOKEN({ url: 'cart/order/all' });
            setOrders(res);
            setFilteredOrders(res);
        } catch (error) {
            console.error(error);
        }
    }

    React.useEffect(() => {
        fetchOrders();
    }, []);

    const handleFilter = () => {
        let filtered = orders;

        if (searchName) {
            filtered = filtered.filter(order => 
                order.owner?.name?.toLowerCase().includes(searchName.toLowerCase())
            );
        }

        if (searchDate) {
            filtered = filtered.filter(order => order.createdAt.slice(0, 10) === searchDate);
        }

        if (searchBill) {
            const billValue = parseFloat(searchBill);
            if (!isNaN(billValue)) {
                filtered = filtered.filter(order => order.bill === billValue);
            }
        }

        setFilteredOrders(filtered);
    }

    React.useEffect(() => {
        handleFilter();
    }, [searchName, searchDate, searchBill]);

    const clearFilters = () => {
        setSearchName('');
        setSearchDate('');
        setSearchBill('');
        setFilteredOrders(orders);
    }

    return (
        <React.Fragment>
            <main className='grid gap-5 p-5'>
                <h1 className='font-bold text-base md:text-2xl text-center'>Orders List </h1>
                <div className='grid  md:grid-cols-3 grid-cols-2 gap-5'>
                    <Input
                        type='text'
                        placeholder='Filter by Customer Name'
                        value={searchName}
                        onChange={(e) => setSearchName(e.target.value)}
                    />
                    <Input
                        type='date'
                        placeholder='Filter by Date'
                        value={searchDate}
                        onChange={(e) => setSearchDate(e.target.value)}
                    />
                    <Button
                        onClick={clearFilters}
                        className='p-2 bg-tertiary text-white_100 rounded'
                    >
                        Clear
                    </Button>
                </div>
                {
                    filteredOrders?.map((order, index) => {
                        const customerName = order.owner?.name ? order.owner.name : 'Customer name not available';

                        return (
                            <div key={index}>
                                <Card className='p-5 border-none shadow-sm shadow-tertiary'>
                                    <Accordion type='multiple'>
                                        <AccordionItem value={order._id}>
                                            <div className='font-extrabold grid sm:grid-cols-3 gap-5 w-full'>
                                                <div className='text-start'>Customer Name: <span className='text-primary'>{customerName}</span></div>
                                                <div className='text-start '>Total Bill: <span className='text-red_100'>$ {order.bill}</span></div>
                                                <div className='text-start'>Date: <span className='text-secondary'>{order.createdAt.slice(0, 10)}</span></div>
                                            </div>
                                            <AccordionTrigger className=' font-extrabold'>
                                                <h1 className='font-bold text-tertiary '>***All Ordered Products Below***</h1>
                                            </AccordionTrigger>
                                            <AccordionContent className='grid sm:grid-cols-2 xl:grid-cols-3 gap-5'>
                                                {
                                                    order.items.map((item: OrderItem) => (
                                                        <div key={item._id} className=' sm:px-16'>
                                                            <p className='text-secondary font-bold'>Name: {item.name}</p>
                                                            <p>Quantity: {item.quantity}</p>
                                                            <p>Price: {item.price}</p>
                                                        </div>
                                                    ))
                                                }
                                            </AccordionContent>
                                        </AccordionItem>
                                    </Accordion>
                                </Card>
                            </div>
                        )
                    })
                }
            </main>
        </React.Fragment>
    )
}

export default TotalOrders;
