import * as React from "react";
import { AppWindow, GraduationCap, MonitorCheck } from "lucide-react";
import { Separator } from "@radix-ui/react-select";

const CompRight = () => {
  return (
    <React.Fragment>
      <main className="grid grid-cols-1 gap-5">
        <div className="gap-y-7 p-5 md:p-10 lg:py-10 grid place-items-center md:place-content-start">
          <h1 className="text-tertiary text-xl sm:text-2xl md:text-4xl text-center md:text-left font-bold leading-snug">
            Why ICE Robotics...?
          </h1>
          <Separator className="bg-secondary w-20 h-1" />
          
          <p className="grid gap-3 font-sans leading-snug text-base lg:text-md">
            <span className="flex gap-2 font-bold"><GraduationCap />New Age Education</span>
            Have your children be prepared for a rapidly changing future that traditional education can no longer keep up with. STEM (Science, Technology, Engineering, Math) education, of which Robotics is a key component, has assumed increasing importance in the last few years due to its forward-looking curriculum and its ability to combine theory and practical based learning.
          </p>

          <p className="grid gap-3 font-sans leading-snug text-base lg:text-md">
            <span className="flex gap-2 font-bold"><MonitorCheck />Practical Learning</span>
            Your children learn by doing, gaining valuable practical skills. Beyond a functional understanding of Robotics, children also learn soft-skills like teamwork, innovative thinking, leadership, problem-solving, and more.
          </p>

          <p className="grid gap-3 font-sans leading-snug text-base lg:text-md">
            <span className="flex gap-2 font-bold"><AppWindow />It's Fun!</span>
            Engage your children with learning that is also fun. Keep them occupied with a fun-to-learn set of skills and knowledge.
          </p>
        </div>

        <div className="grid items-center justify-center p-4">
          <img
            width={500}
            height={500}
            // quality={100}
            className="w-[60vh] shadow-lg border-secondary border-b-8"
            src="./Imagefolder/freeDemo.jpg"
            alt=""
          />
        </div>
      </main>
    </React.Fragment>
  );
};

export default CompRight;
