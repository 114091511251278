import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";

type Props = {
  heading: string;
  description: string;
};

const PopUp = (props: Props) => {
  const [show, setShow] = React.useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <React.Fragment>
      {show && (
        <Dialog open >
          <DialogContent className="bg-light_green text-white_100">
            <DialogHeader >
              <DialogTitle>{props.heading}</DialogTitle>
              <DialogDescription>{props.description}</DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default PopUp;
