import React from 'react';
import TERAICE from './TERA-ICE';
import PETAICE from './PETA-ICE';
import ProcessInfographic from './ProcessInfographic';

const RodMpIndex = () => {
    return (
        <div>
            <div className="font-mono text-center font-bold lg:text-6xl text-4xl m-10 p-3 rounded-3xl">
                <h1 className="text-primary">Robotics Roadmap</h1>
            </div>
            
            <div>
                <div className="font-sans text-center font-bold lg:text-4xl text-xl m-10 bg-primary p-3 rounded-3xl">
                    <div className="text-white">
                        <h1 className='text-white_100'>TERA-ICE</h1>
                        <h1 className='text-white_100'>(Ages 5 To 8)</h1>
                    </div>
                </div>
                <TERAICE />
            </div>
            
            <div>
                <div className="font-sans text-center font-bold lg:text-4xl text-xl m-10 bg-primary p-3 rounded-3xl">
                    <div className="text-white">
                        <h1 className='text-white_100'>PETA-ICE</h1>
                        <h1 className='text-white_100'>(Ages 9 To 14)</h1>
                    </div>
                </div>
                <PETAICE />
            </div>
            
            <div>
                <div className="font-sans text-center font-bold lg:text-4xl text-xl m-10 bg-primary p-3 rounded-3xl">
                    <div className="text-white">
                        <h1 className='text-white_100'>EXA-ICE</h1>
                        <h1 className='text-white_100'>(Ages 14+)</h1>
                    </div>
                </div>
                {/* <EXAICE /> */}
                <ProcessInfographic />
            </div>
        </div>
    );
};

export default RodMpIndex;
