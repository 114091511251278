import React from "react";
import Checkout from "./Checkout";
import Cart from "./Cart";

const Index = () => {
  return (
    <React.Fragment>
      <main className="lg:max-w-screen-xl sm:p-5  w-full mx-auto ">
        <div className="grid md:grid-cols-10">
          <div className="lg:col-span-7 md:col-span-6">
            <Checkout />
          </div>
          <div className="lg:col-span-3 md:col-span-4 sm:mt-10 m-4">
            <Cart />
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default Index;