import actions from "../../../../actions";
import { Button } from "../../../ui/button";
import { Card } from "../../../ui/Card";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "../../../ui/dialog";
import { ICampCoupons } from "../../../../../typings";
import { Trash2 } from "lucide-react";
import React, { useEffect, useState } from "react";

interface CampListProps {
  isNew: boolean
}

const CampCouponsList = ({ isNew }: CampListProps) => {
  const [campCoupon, setCampCoupon] = React.useState<ICampCoupons[]>([]);
  const [error, setError] = useState<string | null>(null);

    const fetchCampCoupons = async () => {
      try {
        const response = await actions.GET_DATA({ url: "campcoupon/all" });
        setCampCoupon(response)
      } catch (error: any) {
        setError(error.message);
      }
    };
    useEffect(() => {
      fetchCampCoupons();
    }, [isNew]);

  const deleteCampCoupon = async (id: string) => {
    const response = await actions.DELETE_DATA({
      url:"campcoupon/delete",
      params:id,
    })
    if (response.success === true) {
      fetchCampCoupons();
    }
  }

  return (
    <React.Fragment>
        <Card className="p-5 border-none space-y-4 shadow-md shadow-primary">
          <h1 className="text-center xl:text-3xl md:text-2xl text-xl font-bold my-3 xl:my-5 ">Camp Coupons</h1>
          {campCoupon?.map((item, index) => {
            return (
              <Card
                key={index}
                className="text-sm sm:text-base gap-5 sm:gap-5 flex justify-between p-4"
              >
                <div className="">
                  <div className="flex gap-5">
                  <h1 className="font-bold">{index + 1}.</h1>
                  <h1> {item.month}: <span className="text-red_100">{item.off}%</span> off - Book By {item.month} {item.date}</h1>
                  </div>
                </div>
                <div className="col-span-1 col-start-11 grid items-center justify-end ">
                  <Dialog>
                    <DialogTrigger>
                      <Button variant="outline" className="border-none">
                        <Trash2 className="hover:text-red_100 size-6 " />
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="bg-white_100 w-5/6 sm:w-full">
                      <DialogTitle>Confirm Delete</DialogTitle>
                      <DialogDescription className="md:text-lg">
                        Are you sure you want to delete this Camp-Coupon?
                      </DialogDescription>
                      <div className="flex justify-end space-x-2 mt-6">
                        <DialogClose asChild>
                          <Button className="text-white_100">Cancel</Button>
                        </DialogClose>
                        <DialogClose asChild>
                          <Button
                            className="bg-red_100 text-white_100"
                            onClick={() => {
                              deleteCampCoupon(item._id);
                            }}
                          >
                            Delete
                          </Button>
                        </DialogClose>
                      </div>
                    </DialogContent>
                  </Dialog>
                </div>
              </Card>
            );
          })}
        </Card>
    </React.Fragment>
  );
};

export default CampCouponsList;
