import { ChevronsRight } from 'lucide-react';
import * as React from "react";

const AboutMid = () => {
  return (
    <React.Fragment>
      <main className="grid lg:grid-cols-2 gap-10 p-5">
        <div className="grid sm:grid-cols-2 gap-5 text-sm sm:text-base">
          <img
            className="h-full hover:brightness-90 hover:transform hover:transition-transform hover:scale-105 hover:duration-700 shadow-md shadow-secondary rounded-lg"
            src="/Imagefolder/Images/AboutIceMid_img_2.jpg"
            alt="Our Mission"
          />
          <div>
            <h1 className="uppercase my-3 text-xl sm:text-2xl px-5 flex items-center gap-2">
              <ChevronsRight /> Our <b className="text-secondary">Mission</b>
            </h1>
            <p>
              At ICE Robotics we aspire to <i>“brighten tomorrow's future”</i> as we prepare students to build their 21st-century skills through teamwork, creativity, and critical thinking, and create real-world connections.
            </p>
          </div>
        </div>
        <div className="grid sm:grid-cols-2 gap-5 text-sm sm:text-base">
          <img
            className="h-full hover:brightness-90 hover:transform hover:transition-transform hover:scale-105 hover:duration-700 shadow-md shadow-secondary rounded-lg"
            src="/Imagefolder/Images/Aboutus_Img_1.jpg"
            alt="Our Vision"
          />
          <div>
            <h1 className="uppercase my-3 text-xl sm:text-2xl px-5 flex items-center gap-2">
              <ChevronsRight /> Our <b className="text-secondary">Vision</b>
            </h1>
            <p>
              We hope to see every student succeed and become future innovators, getting actively involved in subjects from science to humanities, scientific inquiries, and technical know-hows.
            </p>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default AboutMid;
