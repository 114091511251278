import { deleteData, deleteDataWithToken,deleteDataWithGet, deleteDataWithPost } from "./DELETE.action"
import { fetchChartData, fetchData, fetchDataById,fetchDataWithoutData,fetchDataWithToken } from "./GET.actions"
import { Login, Logout, postData, postWithToken } from "./POST.actions"
import updateData from "./PUT.actions"

const actions = {
    GET_CHART_DATA:fetchChartData,
    GET_DATA:fetchData,
    GET_DATA_WITHOUT_DATA:fetchDataWithoutData,
    GET_DATA_TOKEN:fetchDataWithToken,
    GET_DATA_BY_ID:fetchDataById,
    POST_DATA:postData,
    LOGIN_DATA:Login,
    PUT_DATA:updateData,
    DELETE_DATA: deleteData,
    DELETE_DATA_TOKEN:deleteDataWithToken,
    DELETE_WITH_GET : deleteDataWithGet,
    DELETE_WITH_POST : deleteDataWithPost,
    POST_DATA_TOKEN:postWithToken,
    LOGOUT_DATA:Logout,
}

export default actions