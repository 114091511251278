import * as React from "react";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";

const Mains = () => {
  return (
    <div className="grid xl:grid-cols-5 grid-cols-1 md:grid-cols-5">
      <div className="col-span-3 md:grid-cols-3 ">
        <LeftSection />
      </div>
      <div className="col-span-2 md:grid-cols-2 ">
        <RightSection />
      </div>
    </div>
  );
};
export default Mains;