import actions from "../../../../actions";
import { Card } from "../../../ui/Card";
import {Receipt} from "lucide-react";
import React from "react";

type TodayOrder = {
  totalRevenue: any;
};
type YesterdayOrder = {
  totalRevenue: any;
};
type ThisMonthOrder = {
  totalRevenue: any;
};
type LastMonthOrder = {
  totalRevenue: any;
};
type AllTimeOrder = {
  totalRevenue: any;
};

const DataCards = () => {
  const [todayOrder, setTodayOrder] = React.useState<TodayOrder | null>(null);
  const [yesterdayOrder, setYesterdayOrder] =React.useState<YesterdayOrder | null>(null);
  const [thisMonthOrder, setThisMonthOrder] =React.useState<ThisMonthOrder | null>(null);
  const [lastMonthOrder, setLastMonthOrder] =React.useState<LastMonthOrder | null>(null);
  const [AlltimeOrder, setAllTimeOrder] = React.useState<AllTimeOrder | null>(
    null
  );
  const [error, setError] = React.useState<string | null>(null);

  const fetchTodaysRevenue = async () => {
    try {
      const response = await actions.GET_DATA_WITHOUT_DATA({
        url: "cart/todaysrevenue",
      });
      setTodayOrder(response);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred');
      }
    }
  };
  const fetchYesterdayOrder = async () => {
    try {
      const response = await actions.GET_DATA_WITHOUT_DATA({
        url: "cart/yesterdayrevenue",
      });
      setYesterdayOrder(response);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred');
      }
    }
    
  };
  const fetchThisMonthOrder = async () => {
    try {
      const response = await actions.GET_DATA_WITHOUT_DATA({
        url: "cart/thismonthrevenue",
      });
      setThisMonthOrder(response);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred');
      }
    }
    
  };
  const fetchLastOrder = async () => {
    try {
      const response = await actions.GET_DATA_WITHOUT_DATA({
        url: "cart/lastmonthrevenue",
      });
      setLastMonthOrder(response);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred');
      }
    }
    
  };
  const fetchAlltimeOrder = async () => {
    try {
      const response = await actions.GET_DATA_WITHOUT_DATA({
        url: "cart/alltimerevenuegenerated",
      });
      setAllTimeOrder(response);
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('An unknown error occurred');
      }
    }
    
  };

  React.useEffect(() => {
    fetchTodaysRevenue();
    fetchYesterdayOrder();
    fetchThisMonthOrder();
    fetchLastOrder();
    fetchAlltimeOrder();
  }, []);

  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 2xl:grid-cols-5 gap-2 text-white_100">
      <Card className="card grid grid-cols-12 bg-primary shadow-md border-none text-center p-2 text-white_100">
        <div className="col-span-12 grid">
          {todayOrder ? (
            <div>
              <Receipt size={30} className="mx-auto stroke-1" />
              <div className="text-base text-white_100">Today's Revenue</div>
              <h1>$ {todayOrder?.totalRevenue}</h1>
            </div>
          ) : (
            <p>No orders found</p>
          )}
        </div>
      </Card>

      <Card className="card grid grid-cols-12 bg-secondary shadow-md border-none shadow-$ text-center p-2">
        <div className="col-span-12">
          {yesterdayOrder ? (
            <div>
              <Receipt size={30} className="mx-auto stroke-1" />
              <div className="text-base text-white_100">Yesterdays Revenue</div>
              <h1>$ {yesterdayOrder?.totalRevenue}</h1>
            </div>
          ) : (
            <p>No orders found</p>
          )}
        </div>
      </Card>
      <Card className="card grid grid-cols-12 bg-primary shadow-md border-none shadow-$ text-center p-2">
        <div className="col-span-12">
          {thisMonthOrder ? (
            <div>
              <Receipt size={30} className="mx-auto stroke-1 text-white_100" />
              <div className="text-base text-white_100">This Month Revenue</div>
              <h1>$ {thisMonthOrder?.totalRevenue}</h1>
            </div>
          ) : (
            <p>No orders found</p>
          )}
        </div>
      </Card>
      <Card className="card grid grid-cols-12 bg-secondary shadow-md border-none shadow-$ text-center p-2">
        <div className="col-span-12">
          {lastMonthOrder ? (
            <div>
              <Receipt size={30} className="mx-auto stroke-1" />
              <div className="text-base text-white_100">Last Month Revenue</div>
              <h1>$ {lastMonthOrder?.totalRevenue}</h1>
            </div>
          ) : (
            <p>No orders found</p>
          )}
        </div>
      </Card>
      <Card className="card grid grid-cols-12 bg-primary shadow-md border-none shadow-$ text-center p-2">
        <div className="col-span-12">
          {AlltimeOrder ? (
            <div>
              <Receipt size={30} className="mx-auto stroke-1" />
              <div className="text-base text-white_100">All Time Revenue</div>
              <h1>$ {AlltimeOrder?.totalRevenue}</h1>
            </div>
          ) : (
            <p>No orders found</p>
          )}
        </div>
      </Card>
    </div>
  );
};

export default DataCards;
