import React from "react";

const CampActivitiesTable = () => {
    const campActivitiesData = [
        { time: "9:00 am - 10:10 am", activity: "Tera, Peta and Exa Projects" },
        { time: "10:10 am - 10:20 am", activity: "Snack Break" },
        { time: "10:20 am - 12:00 pm", activity: "Tera, Peta and Exa Projects" },
        { time: "12:00 pm - 12:20 pm", activity: "Lunch" },
        { time: "12:20 pm - 1:05 pm", activity: "Outdoor Activities" },
        { time: "1:05 pm - 1:15 pm", activity: "Break" },
        { time: "1:15 pm - 2:15 pm", activity: "Coding" },
        {
            time: "1:15 pm - 2:30 pm",
            activity: "Experimentation on Tera, Peta and Exa Projects",
        },
        { time: "2:30 pm - 3:15 pm", activity: "Arts and Crafts" },
        { time: "3:15 pm - 4:00 pm", activity: "Talent Fostering Class" },
        { time: "4:00 pm", activity: "End of Day" },
    ];

    return (
        <div className="text-xl text-center mb-4 grid">
            <h1 className="md:text-3xl text-lg text-primary font-bold mb-5">Camp Activities</h1>
            <h1 className="md:text-2xl font-bold text-lg mb-5">Full Day Camp Activities</h1>
            <div className="grid  grid-cols-1 gap-2">
                <table className="table-auto border-collapse border text-center mb-5 w-full">
                    <thead>
                        <tr className="bg-[#FFD580] text-base font-sans">
                            <th className="border text-base leading-snug px-4 py-2 font-sans">Time</th>
                            <th className="border text-base leading-snug px-4 py-2 font-sans">Activities</th>
                        </tr>
                    </thead>
                    <tbody>
                        {campActivitiesData.map((activity, index) => (
                            <tr key={index} className="hover:bg-lightblue">
                                <td className="border text-base leading-snug px-4 py-2 font-sans">{activity.time}</td>
                                <td className="border text-base leading-snug px-4 py-2 font-sans">{activity.activity}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>

    );
};

export default CampActivitiesTable;
