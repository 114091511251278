import React from "react";

const FullDay_HalfDay_Activities = () => {
    const mdata = [
        { time: "9:00 am - 10:45 am", activity: "Robotics Engineering Design" },
        { time: "10:45 am - 11:00 am", activity: "Snack Break" },
        { time: "11:00 am - 12:00 pm", activity: "STEAM Robotics Challenge" },
    ];
    const adata = [
        { time: "1:00 pm – 2:45 pm", activity: "Robotics Engineering Design" },
        { time: "2:45 pm – 3:00 pm", activity: "Snack Break" },
        { time: "3:00 pm – 4:00 pm", activity: "STEAM Robotics Challenge" },
    ];

    return (
        <div className="text-xl text-center mb-4 grid  md:grid-cols-2 grid-cols-1 gap-5">
            <div>
                <h1 className="md:text-2xl font-bold text-lg mb-5">Half Day Morning Camp Activities</h1>
                <div className="grid">
                    <table className=" table-auto border-collapse border text-center mb-5 w-full">
                        <thead>
                            <tr className="bg-[#FFD580] ">
                                <th className="border text-base px-4 py-2 md:overflow-x-auto md:px-2 md:py-1  ">Time</th>
                                <th className="border text-base px-4 py-2 md:overflow-x-auto md:px-2 md:py-1  ">Activities</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mdata.map((item, index) => (
                                <tr key={index} className="hover:bg-lightblue ">
                                    <td className="border text-base px-4 py-2 md:overflow-x-auto md:px-2 md:py-1  ">{item.time}</td>
                                    <td className="border text-base px-4 py-2 md:overflow-x-auto md:px-2 md:py-1  ">{item.activity}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div>
                <h1 className="md:text-2xl font-bold text-lg mb-5">Half Day Afternoon Camp Activities</h1>
                <div className="grid">
                    <table className=" table-auto border-collapse border text-center mb-5 w-full">
                        <thead>
                            <tr className="bg-[#FFD580] ">
                                <th className="border text-base px-4 py-2 md:overflow-x-auto md:px-2 md:py-1  ">Time</th>
                                <th className="border text-base px-4 py-2 md:overflow-x-auto md:px-2 md:py-1  ">Activities</th>
                            </tr>
                        </thead>
                        <tbody>
                            {adata.map((item, index) => (
                                <tr key={index} className="hover:bg-lightblue ">
                                    <td className="border text-base px-4 py-2 md:overflow-x-auto md:px-2 md:py-1  ">{item.time}</td>
                                    <td className="border text-base px-4 py-2 md:overflow-x-auto md:px-2 md:py-1  ">{item.activity}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default FullDay_HalfDay_Activities;
