import React, { useState, useEffect } from "react";
import { Minus, Plus, X } from "lucide-react";
import { Input } from "../../ui/Input";
import { Button } from "../../ui/button";
import actions from "../../../actions";
import { ICartItem } from "../../../../typings";
import BASE_URL from "../../../config";
import Cookies from "js-cookie";
import { useData } from "../../lib/DataContext";
import { loadStripe } from "@stripe/stripe-js";
const MobCart = () => {
  const [cartItem, setCartItem] = useState<ICartItem | null>(null);
  const { fetchData } = useData();
  const fetchCart = async () => {
    const res = await actions.GET_DATA_TOKEN({ url: "cart/all" });
    setCartItem(res);
  };
  const [stripePromise, setStripePromise] = useState<any>(() => {});
  let quantity: number = 0;
  useEffect(() => {
    const key =
      "pk_live_51Pe14q2LTAqtPUxh8WsrMJw4j6bN32cPreUU200hvV0cVk6Q4B7Ma18JXAg9GP5JuizrwvSMfavTCI9ZtKJ1HdoS00vetGE5f8";
      // "pk_test_51PkOigRq8CRKmkutpGzL27MCxjM0k8lpVjrxIMRFp8ZiWJuGAGkC1Iy4MadqJ2VG7PxPTK4eYqt4CEicXMKlN6OO00Hrceb92p";
    if (key) {
      const load = async () => {
        const stripePromise = await loadStripe(key);
        setStripePromise(stripePromise);
      };
      load();
    }
  }, []);
  const addToCart = async (id: string) => {
    quantity++;
    const payload = {
      itemId: id,
      quantity: quantity,
    };
    try {
      const add = await actions.POST_DATA_TOKEN({ url: "cart/add", payload });
      if (add.success === true) {
        fetchCart();
        fetchData();
      } else {
        alert("failed to add item ");
        window.location.reload();
      }
    } catch (error) {
    }
  };
  const decreaseCart = async (id: string) => {
    const payload = {
      itemId: id,
      quantity: 1,
    };
    try {
      const add = await actions.POST_DATA_TOKEN({
        url: "cart/remove",
        payload,
      });
      if (add.success === true) {
        fetchData();
        fetchCart();
      } else {
        alert("failed to remove item ");
        window.location.reload();
      }
    } catch (error) {
    }
  };
  const removeCart = async (id: string) => {
    try {
      const token = Cookies.get("token");
      const remove = await fetch(`${BASE_URL}/cart/delete?itemId=${id}`, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "x-access-token": `${token}`,
        },
      });
      const res = await remove.json();
      if (res.success === true) {
        fetchCart();
      } else {
        alert("failed to remove item ");
        window.location.reload();
      }
      if (remove.status === 200) {
        fetchData();
      }
    } catch (error) {
    }
  };
  useEffect(() => {
    fetchCart();
  }, []);
  const [cartOpen, setCartOpen] = useState(true);
  const [coupon, setCoupon] = useState("");
  const [discount, setDiscount] = useState(0);

  // const handleCheckout = async () => {
  //   const stripe = await stripePromise;
  //   if (!stripe) {
  //     console.error("Stripe failed to initialize");
  //     return;
  //   }
  //   // Prepare the request payload with Stripe's line_items format
  //   const body = {
  //     line_items:
  //       cartItem?.items.map((item) => ({
  //         price_data: {
  //           currency: "cad",
  //           product_data: {
  //             name: item.name,
  //           },
  //           unit_amount: item.price * 100, // Stripe expects the amount in the smallest currency unit (cents for USD)
  //         },
  //         quantity: item.quantity, // Ensure quantity is included
  //       })) || [],
  //   };
  //   const response = await fetch('https://iceroboapi.pickupbiz.in/api/v1/create-checkout-session', {
  //   // const response = await fetch('https://iceroboapi.pickupbiz.in/api/create-checkout-session', {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify(body)
  //   });
  //   const session = await response.json();
  //   const result = await stripe.redirectToCheckout({ sessionId: session.id });
  //   if (result.error) {
  //   }
  // };

  const handleCheckout = async () => {
    try {
      const stripe = await stripePromise;
  
      if (!stripe) {
        console.error("Stripe failed to initialize");
        return;
      }
  
      const body = {
        line_items: cartItem?.items.map((item) => ({
          price_data: {
            currency: "cad",
            product_data: { name: item.name },
            unit_amount: item.price * 100,
          },
          quantity: item.quantity,
        })) || [],
      };
      const token=localStorage.getItem('accesstoken')
      const response = await fetch('https://iceroboapi.pickupbiz.in/api/v1/cart/order/checkout', {
        method: "POST",
        headers: { "Content-Type": "application/json","x-access-token":token },
        body: JSON.stringify(body),
  
      });
  
      if (!response.ok) {
        const errorDetails = await response.text();
        console.error(`Error: ${response.status} - ${errorDetails}`);
        return;
      }
      const session = await response.json();
      window.location.href = session.session_url;
      // const result = await stripe.redirectToCheckout({ sessionId: session.session_url });
      // if (result.error) {
      // }
  
    } catch (error) {
      console.error("Checkout error:", error);
    }
  };

  
  //   const handleCheckout = () => {
  //     const isAuthenticated = true; // Change this based on actual authentication status
  //     if (isAuthenticated) {
  //       router.push("/checkout");
  //     } else {
  //       router.push("/login");
  //     }
  //   };
  const calculateSubtotal = (item: {
    id?: number;
    name?: string;
    price: any;
    quantity: any;
  }) => {
    return item.price * item.quantity;
  };
  const applyCoupon = () => {
    let appliedDiscount = 0;
    switch (coupon) {
      case "SAVE10":
        appliedDiscount = 10;
        break;
      case "50OFF":
        appliedDiscount = 50;
        break;
      default:
        break;
    }
    setDiscount(appliedDiscount);
  };
  const removeCoupon = () => {
    setCoupon("");
    setDiscount(0);
  };
  return (
    <div className="">
      <h1 className="text-2xl font-bold mb-2 text-center">Your Cart</h1>
      {cartOpen && (
        <div className="grid gap-4">
         <section className="grid gap-4 h-80 overflow-y-auto border p-5">
         {cartItem && cartItem.items.length > 0 ? (
            cartItem.items.map((item) => (
              <div key={item._id} className="border border-gray_900/40 p-2 rounded-md ">
                <div className="flex justify-between items-center mb-0.5">
                  <h2 className="font-semibold">{item.name}</h2>
                  <Button
                    variant={"ghost"}
                    className="text-primary hover:text-secondary focus:outline-none p-1"
                    onClick={() => removeCart(item.itemId)}
                  >
                    <X size={16}/>
                  </Button>
                </div>
                <div className="flex justify-between items-center text-sm mb-0.5">
                  <span className="text-gray-500">Price:</span>
                  <span>$ {item.price}</span>
                </div>
                <div className="flex justify-between items-center text-sm mb-0.5">
                  <span className="text-gray-500">Quantity:</span>
                  <div className="flex items-center text-sm">
                    <Button
                      variant={"ghost"}
                      className="hover:text-secondary text-primary text-center p-0"
                      onClick={() => decreaseCart(item.itemId)}
                    >
                      <Minus size={15} className=" border rounded" />
                    </Button>
                    <span className="px-1.5">{item.quantity}</span>
                    <Button
                      variant={"ghost"}
                      className="hover:text-secondary text-primary text-center p-0"
                      onClick={() => addToCart(item.itemId)}
                    >
                      <Plus size={15} className=" border rounded" />
                    </Button>
                  </div>
                </div>
                <div className="flex justify-between items-center text-sm">
                  <span className="text-gray-500">Subtotal:</span>
                  <span>$ {calculateSubtotal(item)}</span>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center text-lg font-semibold">
              Your cart is empty
            </div>
          )}
         </section>
          <div className="border p-4 rounded-md">
            <h2 className="text-lg font-bold mb-4 text-center">Apply Coupon</h2>
            <div className="flex items-center justify-center mb-2 gap-2">
              <Input
                type="text"
                value={coupon}
                onChange={(e) => setCoupon(e.target.value)}
                className="border border-gray-400 p-1 rounded-md mr-2"
                placeholder="Coupon code"
              />
              <Button
                className="bg-tertiary w-36 text-white_100 p-2 rounded-md focus:outline-none"
                onClick={applyCoupon}
              >
                Apply
              </Button>
              {discount > 0 && (
                <Button
                  variant="ghost"
                  className="text-primary hover:text-secondary focus:outline-none"
                  onClick={removeCoupon}
                >
                  <X />
                </Button>
              )}
            </div>
          </div>
          <div className="border p-4 rounded-md">
            <h2 className="text-lg font-bold mb-4 text-center">Summary</h2>
            <div className="flex justify-between mb-2">
              <span className="text-gray-500">Total Items:</span>
              <span>
                {cartItem
                  ? cartItem.items.reduce((acc, item) => acc + item.quantity, 0)
                  : 0}
              </span>
            </div>
            <div className="flex justify-between mb-2">
              <span className="text-gray-500">Total Price:</span>
              <span>
                $
                {cartItem
                  ? cartItem.items.reduce(
                      (acc, item) => acc + item.price * item.quantity,
                      0
                    )
                  : 0}
              </span>
            </div>
            {discount > 0 && (
              <div className="flex justify-between mb-2">
                <span className="text-gray-500">Discount:</span>
                <span>
                  $
                  {cartItem
                    ? (
                        cartItem.items.reduce(
                          (acc, item) => acc + item.price * item.quantity,
                          0
                        ) *
                        (discount / 100)
                      ).toFixed(2)
                    : 0}
                </span>
              </div>
            )}
            <div className="flex justify-between">
              <span className="text-gray-500">Total Price after Discount:</span>
              <span>
                $
                {cartItem
                  ? cartItem.items.reduce(
                      (acc, item) => acc + item.price * item.quantity,
                      0
                    ) -
                    cartItem.items.reduce(
                      (acc, item) => acc + item.price * item.quantity,
                      0
                    ) *
                      (discount / 100)
                  : 0}
              </span>
            </div>
          </div>
          <Button
            className="bg-tertiary text-white_100 p-2 rounded-md mt-4 w-full focus:outline-none"
            onClick={handleCheckout}
          >
            Checkout
          </Button>
        </div>
      )}
    </div>
  );
};
export default MobCart; 