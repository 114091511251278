import React, { useEffect, useState } from "react";
import actions from "../../../../../actions";
import { Input } from "../../../../ui/Input";
import { Button } from "../../../../ui/button";
import { Trash2 } from 'lucide-react';
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "../../../../ui/dialog";

interface IFaqItem {
  _id: string;
  question: string;
  answer: string;
}

interface IFAQ {
  topic: {
    name: string;
  };
  faqs: IFaqItem[];
}

const FaqTable = () => {
  const [data, setData] = useState<IFAQ[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchResults, setSearchResults] = useState<IFaqItem[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage = 10;

  
  useEffect(() => {
    fetchFaq();
  }, []);

  const fetchFaq = async () => {
    try {
      const res: IFAQ[] = await actions.GET_DATA({ url: "faq/topic/all/faq" });
      setData(res);
      setSearchResults(res.flatMap(item => item.faqs));
    } catch (error) {
      // console.error("Error fetching FAQ data:", error);
    }
  };

  const deleteFaq = async (_id: string) => {
    try {
      const deleteResponse = await actions.DELETE_DATA({
        url: `faq/delete`,
        params: _id,
      });
      if (deleteResponse.success) {
        fetchFaq();
      }
    } catch (error) {
      // console.error("Error deleting FAQ:", error);
    }
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    searchFaq(query);
  };

  const searchFaq = (query: string) => {
    const filteredResults = data.flatMap(item =>
      item.faqs.filter(
        (faqItem) =>
          faqItem.question.toLowerCase().includes(query.toLowerCase()) ||
          faqItem.answer.toLowerCase().includes(query.toLowerCase())
      )
    );
    setSearchResults(filteredResults);
    setCurrentPage(1);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = searchResults.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(searchResults.length / itemsPerPage);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <React.Fragment>
      <main className="sm:p-4 text-xs sm:text-sm">
        <h1 className="text-center xl:text-3xl md:text-2xl text-xl font-bold my-3 xl:my-5">FAQs Table</h1>
        <div className="flex justify-between items-center mb-4">
          <Input
            type="search"
            placeholder="Search FAQ"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div className="overflow-x-auto">
          <table className="table-auto w-full border rounded-md shadow-md">
            <colgroup>
              <col  />
              <col className="w-2/12" />
              <col className="w-3/12" />
              <col className="w-3/4" />
              <col className="w-1/12" />
            </colgroup>
            <thead>
              <tr className="bg-tertiary tracking-wide text-sm sm:text-base text-white_100">
                <th className="px-4 py-2 border">Sr.No</th>
                <th className="px-4 py-2 border">Topics</th>
                <th className="px-4 py-2 border">Question</th>
                <th className="px-4 py-2 border">Answer</th>
                <th className="px-4 py-2 border">Delete</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((faqItem, index) => (
                <tr key={`faq-${index}`}>
                  <td className="px-4 py-2 border max-w-xs text-center">
                    {index+1}
                  </td>
                  <td className="px-4 py-2 border max-w-xs">
                    {data.find(item => item.faqs.some(faq => faq._id === faqItem._id))?.topic.name}
                  </td>
                  <td className="px-4 py-2 border max-w-xs">
                    {faqItem.question}
                  </td>
                  <td className="px-4 py-2 border">
                    {faqItem.answer}
                  </td>
                  <td className="px-4 py-2 border text-center">
                    <Dialog>
                    <DialogTrigger>
                      <Button variant="outline" className="border-none">
                        <Trash2 className="hover:text-red_100 size-6 " />
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="bg-white_100 w-5/6 sm:w-full">
                      <DialogTitle>Confirm Delete</DialogTitle>
                      <DialogDescription className="md:text-lg">
                        Are you sure you want to delete this FAQ?
                      </DialogDescription>
                      <div className="flex justify-end space-x-2 mt-6">
                        <DialogClose asChild>
                          <Button className="text-white_100">Cancel</Button>
                        </DialogClose>
                        <DialogClose asChild>
                          <Button
                            className="bg-red_100 text-white_100"
                            onClick={() => {
                              deleteFaq(faqItem._id);
                            }}
                          >
                            Delete
                          </Button>
                        </DialogClose>
                      </div>
                    </DialogContent>
                  </Dialog>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-center items-center mt-4 overflow-auto">
          <Button
            onClick={goToPrevPage}
            disabled={currentPage === 1}
            className="mx-1 px-3 py-1 rounded-md bg-tertiary text-white_100"
          >
            Previous
          </Button>
          {pageNumbers.map((number) => (
            <Button
              key={number}
              className={`mx-1 px-3 py-1 rounded-md ${
                currentPage === number
                  ? "bg-gray_200 text-white"
                  : "bg-secondary text-black"
              }`}
              onClick={() => paginate(number)}
            >
              {number}
            </Button>
          ))}
          <Button
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
            className="mx-1 px-3 py-1 rounded-md bg-tertiary text-white_100"
          >
            Next
          </Button>
        </div>
      </main>
    </React.Fragment>
  );
};

export default FaqTable;
