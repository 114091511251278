import React from "react";
import { Check } from "lucide-react";

const SummerCamp_Info = () => {
  return (
    <React.Fragment>
      <main className="text-white_100">
        <h1 className="font-bold text-lg lg:text-4xl text-white_100 text-center">
          Summer Camps Starting Soon!
        </h1>
        <h3 className="text-sm lg:text-xl text-center font-semibold my-2 sm:mt-6">
          Limited seats are available. Hurry up and Talk to us.
        </h3>
        <div className="text-sm lg:text-xl font-sans sm:mt-10">
          <div className="grid grid-cols-12 sm:gap-1">
            <Check className="col-span-1 text-primary mt-1.5 size-5 sm:size-7" />
            <h4 className="col-span-11 p-0.5">Age Group: 5 or above</h4>
          </div>
          <div className="grid grid-cols-12 sm:gap-1">
            <Check className="col-span-1 text-primary mt-1.5 size-5 sm:size-7" />
            <h4 className="col-span-11 p-0.5">
              Camp Duration: Full or Half-Day options
            </h4>
          </div>
          <div className="grid grid-cols-12 sm:gap-1">
            <Check className="col-span-1 text-primary mt-1.5 size-5 sm:size-7" />
            <h4 className="col-span-11 p-0.5">
              Activities: Robotics projects, coding workshops, art projects,
              swimming, and outdoor games
            </h4>
          </div>
          <div className="grid grid-cols-12 sm:gap-1">
            <Check className="col-span-1 text-primary mt-1.5 size-5 sm:size-7" />
            <h4 className="col-span-11 p-0.5">
              Convenience: Extended care, pick-up, and drop-off services
              available
            </h4>
          </div>
          <div className="grid grid-cols-12 sm:gap-1">
            <Check className="col-span-1 text-primary mt-1.5 size-5 sm:size-7" />
            <h4 className="col-span-11 p-0.5">
              Expertise: Experienced instructors ensuring a safe and engaging
              learning environment
            </h4>
          </div>
        </div>

        <div className="bg-white_100/75 text-black_100 text-center rounded-tl-3xl rounded-br-3xl p-3 sm:px-5 sm:pt-5 m-2 sm:m-5 sm:mt-6 shadow-lg shadow-primary/75">
          <section>
            <span className="font-semibold text-lg sm:text-2xl">
              Book Your Free Consultation Now !!!
            </span>
            <br />
            <i className="text-sm sm:text-base">
              Claim Your Complimentary Consultation Today - Unlock Your
              Potential!
            </i>
          </section>
        </div>
      </main>
    </React.Fragment>
  );
};

export default SummerCamp_Info;
