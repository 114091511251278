import React from "react";
import { Button } from "../../ui/button";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../ui/carousel";
import { Ellipsis } from "lucide-react";
import PAPDimageTop from "./PAPDimageTop";
import PAPDImg from "./PAPDImg";
import { useNavigate } from "react-router-dom";

const data = [
  {
    board: "Toronto District School Board (TDSB):",
    desc: "Professional Activity (PA) Days for Elementary & Secondary Schools",
    points: [
      {
        title: "October 6, 2024",
      },
      {
        title: "November 17, 2024",
      },
      {
        title: "December 8, 2024 (Elementary School Only)",
      },
      {
        title: "January 19, 2024 (Elementary School Only)",
      },
      {
        title: "February 1, 2024 (Secondary School Only)",
      },
      {
        title: "February 16, 2024 ",
      },
      {
        title: "April 19, 2024",
      },
      {
        title: "June 7, 2024 (Elementary School Only)",
      },
      {
        title: "June 27 to 2024  June 28, 2024 (Secondary School Only)",
      },
    ],
  },
  {
    board: "Halton District School Board (HDSB):",
    desc: "Professional Activity (PA) Days for Elementary & Secondary Schools",
    points: [
      {
        title: "September 5, 2024",
      },
      {
        title: "October 6, 2024",
      },
      {
        title: "November 24, 2024",
      },
      {
        title: "January 22, 2024 (Elementary School Only)",
      },
      {
        title: "February 2, 2024 (Secondary School Only)",
      },
      {
        title: "February 16, 2024",
      },
      {
        title: "April 22, 2024",
      },
      {
        title: "June 3, 2024 (Elementary School Only)",
      },
      {
        title: "June 28, 2024 (Secondary School Only )",
      },
    ],
  },
  {
    board: "PEEL DISTRICT SCHOOL BOARD (PDSB)",
    desc: "Professional Activity (PA) Days for Elementary & Secondary Schools",
    points: [
      {
        title: "September 22, 2024 (Elementary School Only)",
      },
      {
        title: "October 6, 2024",
      },
      {
        title: "November 24, 2024",
      },
      {
        title: "January 22, 2024 (Elementary School Only)",
      },
      {
        title: "February 1, 2024 (Secondary School Only)",
      },
      {
        title: "February 16, 2024",
      },
      {
        title: "May 17, 2024",
      },
      {
        title: "June 10, 2024 (Elementary School Only)",
      },
      {
        title: "June 27 to June 28, 2024 (Secondary School Only)",
      },
    ],
  },
];

const PdPa = () => {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <main className="lg:max-w-screen-2xl w-full tracking-wide leading-relaxed mx-auto px-5 sm:p-5">
        <h1 className="text-center text-secondary my-5 text-2xl sm:text-3xl font-bold">PA/PD Days</h1>
        <div className="sm:grid grid-cols-12 gap-5">
          <div className="rounded-3xl xl:w-full mx-auto xl:h-full lg:col-span-4 col-span-12  grid place-content-center">
            <PAPDimageTop />
          </div>
          <div className="lg:col-span-8 col-span-12 grid gap-3 p-5 text-sm sm:text-base">
            <h1 className="font-bold underline italic text-tertiary text-base">
              Keep your child engaged during a PA/PD Day from 9 AM to 3 PM
            </h1>
            <p>
              Children will build a variety of different robotics, based on age
              and interest come and try out what we have to offer!
            </p>
            <p>
              During the PD DAY workshop, children will have the opportunity to
              build and program multiple robots of different sizes and
              complexity. Children will be introduced to our STEM-based
              (Science, Technology, Engineering, and Mathematics) learning
              programs using robot technology! Children will exercise their
              problem-solving and analytical skills while working in an
              atmosphere of creativity and teamwork. Children will create
              real-world applications, explore STEM-based topics beyond the
              classroom, and discover countless career opportunities in related
              fields.
            </p>
            <p>
              Register your children ages from 5 to 14+ for a full day of
              Robotics Camp. This one-day version of our popular programs
              provides a full day of supervised educational activities, robotics
              projects, science experiments, and more! Register Today!
            </p>
            <h1 className="font-bold underline italic text-tertiary text-base">Available Themes !</h1>
            <ul className="list-disc px-5">
              <li>Science & Technology</li>
              <li>Robotics</li>
              <li>Coding, Animation & Game Design</li>
              <li>Digital Design 4 Children</li>
            </ul>
          </div>
        </div>
        <div className=" my-10 ">
          <h1 className="text-center font-bold text-2xl text-secondary my-5 underline underline-offset-4">
            Upcoming PA/PD Day schedule (2024)
          </h1>
          <div className="grid grid-cols-12  ">
            <Carousel className="lg:col-span-7 col-span-12 sm:mx-20">
              <CarouselContent>
                {data.map((item, index) => {
                  const { board, desc, points } = item;
                  const allPoints = points.map((point) => point.title);
                  return (
                    <CarouselItem key={index}>
                      <div className="md:pl-28">
                        <div className="m-0 text-sm sm:text-base">
                          <h1 className="font-bold underline italic text-tertiary text-base">{board}</h1>
                          <p className="my-5">{desc}</p>
                          <ul className="list-disc px-5">
                            {allPoints.map((point, idx) => (
                              <li key={idx}>{point}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </CarouselItem>
                  );
                })}
              </CarouselContent>

              <div className="hidden sm:block">
                {" "}
                <CarouselPrevious />
                <CarouselNext />
              </div>
              <Ellipsis className="grid mx-auto size-10" />
            </Carousel>

            <section className="lg:col-span-5 col-span-12 border-4 border-double border-tertiary/50 grid  p-3 mx-auto mt-5 sm:m-0 place-self-center lg:place-self-end lg:mb-14">
              <PAPDImg/>
            </section>
          </div>
        </div>
        <div className="text-white_100 flex justify-center sm:justify-end gap-4 mb-5 sm:m-0">
          <Button
            className="bg-secondary"
            onClick={() => navigate("/shop")}
          >
            Purchase Course
          </Button>
          <Button
            className="bg-tertiary"
            onClick={() => navigate("/registration")}
          >
            Register Online
          </Button>
        </div>
      </main>
    </React.Fragment>
  );
};

export default PdPa;
