import { Button } from "../../ui/button";
import * as React from "react";

const LeftSection = () => {
  return (
    <div className="grid w-full xl:pr-9 xl:pl-28 xl:mt-16 lg:mt-10 xl:place-content-center p-3 md:p-0 text-center md:pl-5">
      <div className="text-3xl sm:text-4xl md:text-4xl md:text-left text-center xl:text-left xl:text-5xl lg:text-5xl font-serif font-black text-white xl:pt-20 md:pt-0 xl:leading-snug leading-snug md:leading-normal">
        <h1 className="mt-4 lg:-mt-7 text-white_100">
          Next-Gen Learning with STEM, AI, and Robotics!
        </h1>
      </div>
      <div className="mt-7">
        <p className="font-sans text-white_100 font-normal xl:text-xl lg:text-lg text-base md:text-left text-center leading-relaxed py-2 lg:my-7 md:py-0 md:leading-loose">
          ICE Robotics is revolutionizing the way K-12 students learn about
          21st-century skills, AI, and computer education with an exciting and comprehensive approach.
        </p>
      </div>
      <div className="grid xl:grid-cols-4 md:grid-cols-4 grid-cols-2 place-content-center my-4 xl:my-0 md:space-x-10">
        <a href="https://www.youtube.com/@ICERoboticsCA" target="_blank" rel="noopener noreferrer">
          <Button className="bg-secondary hover:bg-secondary/85 xl:my-10 p-6 text-white_100 font-sans text-base md:text-sm xl:text-lg md:font-semibold font-normal xl:rounded-3xl rounded-2xl xl:m-2">
            Watch Video
          </Button>
        </a>
        <a href="#requestForm">
          <Button className="bg-tertiary hover:bg-tertiary/85 xl:my-10 p-6 text-white_100 font-sans text-base xl:text-lg md:text-sm md:font-semibold font-normal xl:rounded-3xl rounded-2xl xl:m-2">
            Book a Demo
          </Button>
        </a>
      </div>
    </div>
  );
};

export default LeftSection;
