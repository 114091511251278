import {
  Card,
  CardContent,
  CardDescription,
  CardTitle,
} from "../../../ui/Card";
import React from "react";
import data from "../../../../DB/RefundPolicy.json";
import { Handshake } from "lucide-react";

const RefundPolicy = () => {
  return (
    <React.Fragment>
      <main className="lg:max-w-screen-2xl w-full grid mx-auto sm:p-5 leading-relaxed">
        <section>
          <h1 className="text-4xl font-bold tracking-wide text-tertiary text-center m-5">
            Refund Policy
          </h1>
        </section>
        <div>
          {data.map((item) => {
            return (
              <>
                <Card className="border shadow-tertiary/75 px-1 sm:px-5 py-4 sm:py-8 m-3 sm:m-5 border-tertiary/25">
                  <CardTitle className="m-2">
                    <div className="flex items-center gap-3 font-bold text-secondary">
                      <Handshake />
                      {item.head}
                    </div>
                  </CardTitle>
                  <CardDescription className="mx-10 sm:ml-12 mt-1 text-sm sm:text-base">
                    {item.detail}
                  </CardDescription>

                  <CardContent>
                    <ul className="mx-4 sm:mx-12 my-4 list-disc text-sm sm:text-base">
                      {item.point.map((item, index) => (
                        <li key={index}>{item.desc}</li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              </>
            );
          })}
        </div>
      </main>
    </React.Fragment>
  );
};

export default RefundPolicy;
