"use client"
import * as React from "react";
import CountUp from "react-countup"
const Bottom = () => {
  return (
    <div className="mt-20">
      <div className="grid xl:grid-cols-4  grid-cols-2 md:grid-cols-4  mx-4 bg-white_100  place-content-evenly xl:mx-40 mb-8 rounded-3xl px-7 xl:-mt-28  shadow-[0_2.8px_2.2px_rgba(0,_0,_0,_0.034),_0_6.7px_5.3px_rgba(0,_0,_0,_0.048),_0_12.5px_10px_rgba(0,_0,_0,_0.06),_0_22.3px_17.9px_rgba(0,_0,_0,_0.072),_0_41.8px_33.4px_rgba(0,_0,_0,_0.086),_0_100px_80px_rgba(0,_0,_0,_0.12)]">
        <div className="grid place-content-center place-items-center m-2">
          <img src="./Imagefolder/uni.png" className="size-24" alt="" />
          <h1 className="flex place-content-center text-2xl lg:text-2xl text-gray-500 font-semibold">
          <CountUp start={0} end={65} duration={4} />+
          
          </h1>{" "}
          <p className="font-serif text-sm text-center sm:text-base xl:text-lg  ">Countries Reached</p>
        </div>{" "}
        <div className="grid place-content-center place-items-center m-2 ">
        <img src="./Imagefolder/school.png"   className="size-24"  alt="" />
          <h1 className="flex place-content-center text-2xl lg:text-2xl text-gray-500 font-semibold ">
          <CountUp start={0} end={2000} duration={4} />+
          </h1>
          <p className="font-serif text-sm text-center sm:text-base xl:text-lg"> Schools</p>
        </div>{" "}
        <div className="grid place-content-center place-items-center m-2 ">
        <img src="./Imagefolder/teachers.png"   className="size-24" alt="" />
          <h1 className="flex place-content-center text-2xl lg:text-2xl text-gray-500 font-semibold">
          <CountUp start={0} end={15000} duration={4} />+
          </h1>{" "}
          <p className="font-serif text-sm text-center sm:text-base xl:text-lg ">Teachers Trained</p>
        </div>{" "}
        <div className="grid place-content-center place-items-center  m-2 ">
        <img src="./Imagefolder/students.png"  className="size-24"  alt="" />
          <h1 className="flex place-content-center text-2xl lg:text-2xl text-gray-500 font-semibold">
          <CountUp start={0} end={1} duration={4} />M+
          </h1>{" "}
          <p className="font-serif text-sm text-center sm:text-base xl:text-lg ">Students Impacted</p>
        </div>
      </div>
    </div>
  );
};
export default Bottom;