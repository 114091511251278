import actions from "../../../../actions";
import { Card } from "../../../ui/Card";
import { CheckCheck, RefreshCw, ShoppingCart, Truck } from "lucide-react";
import React from "react";

type ProcessOrderItem = {
  processingOrders: string;
};

type PendingOrder = {
  pendingOrders: string;
};

type DeliverOrder = {
  deliveredOrders: string;
};

type AllTimeOrder = {
  allTimeOrders: string;
};

const OrderCards = () => {
  const [processOrder, setProcessOrder] =
    React.useState<ProcessOrderItem | null>(null);
  const [pending, setPending] = React.useState<PendingOrder | null>(null);
  const [delivered, setDelivered] = React.useState<DeliverOrder | null>(null);
  const [allTime, setAllTime] = React.useState<AllTimeOrder | null>(null);
  const [error, setError] = React.useState<string | null>(null);

  const fetchProcessOrder = async () => {
    try {
      const response = await actions.GET_DATA({ url: "cart/processingorders" });
      setProcessOrder(response);
    } catch (error: any) {
      setError(error.message);
    }
  };
  // React.useEffect(() => {
  //   fetchProcessOrder();
  //   }, []);

  const fetchPendingOrder = async () => {
    try {
      const response = await actions.GET_DATA({ url: "cart/pendingorders" });
      setPending(response);
    } catch (error: any) {
      setError(error.message);
    }
  };
  // React.useEffect(() => {
  //   fetchPendingOrder();
  //   }, []);

  const fetchDeliverOrder = async () => {
    try {
      const response = await actions.GET_DATA({ url: "cart/deliveredorders" });
      setDelivered(response);
    } catch (error: any) {
      setError(error.message);
    }
  };
  // React.useEffect(() => {
  //   fetchDeliverOrder();
  //   }, []);

  const fetchAllTime = async () => {
    try {
      const response = await actions.GET_DATA_WITHOUT_DATA({
        url: "cart/alltimeorders",
      });
      setAllTime(response);
    } catch (error: any) {
      setError(error.message);
    }
  };
  React.useEffect(() => {
    fetchPendingOrder();
    fetchProcessOrder();
    fetchDeliverOrder();
    fetchAllTime();
  }, []);

  return (
    <div className="grid grid-cols-2 2xl:grid-cols-4 gap-5">
      <Card className="card grid grid-cols-12 gap-3 shadow-md border-none shadow-tertiary text-center p-2">
        <div className=" text-white_100/70 col-span-2 my-3 sm:my-0">
          <Truck size={30} className="mx-auto stroke-1 text-tertiary" />
        </div>
        <div className="col-span-10 ">
          {processOrder ? (
            <div>
              <h1 className="text-base xl:text-lg">
                Processing Orders : {processOrder.processingOrders}
              </h1>
              {/* <h1>{processOrder.processingOrders}</h1> */}
            </div>
          ) : (
            <p>No orders found</p>
          )}
        </div>
      </Card>
      <Card className="card grid grid-cols-12 gap-2 shadow-md border-none shadow-tertiary text-center p-2">
        <div className=" text-white_100/70 col-span-2  my-3 sm:my-0">
          <RefreshCw size={25} className="mx-auto  stroke-1 text-tertiary" />
        </div>
        <div className="col-span-10 ">
          {pending ? (
            <div>
              <h1 className="text-base xl:text-lg">
                Pending Orders : {pending.pendingOrders}
              </h1>
              {/* <h1>{pending.pendingOrders}</h1> */}
            </div>
          ) : (
            <p>No orders found</p>
          )}
        </div>
      </Card>
      <Card className="card grid grid-cols-12 gap-3 shadow-md border-none shadow-tertiary text-center p-2">
        <div className="  text-white_100/70  col-span-2 my-3 sm:my-0">
          <CheckCheck size={30} className="mx-auto stroke-1 text-tertiary" />
        </div>
        <div className="col-span-10">
          {delivered ? (
            <div>
              <h1 className="text-base xl:text-lg">
                Delivered Orders : {delivered.deliveredOrders}
              </h1>
              {/* <h1>{delivered.deliveredOrders}</h1> */}
            </div>
          ) : (
            <p>No orders found</p>
          )}
        </div>
      </Card>
      <Card className="card grid grid-cols-12 gap-2 shadow-md border-none shadow-tertiary text-center p-2">
        <div className=" text-white_100/70 col-span-2 my-3 sm:my-0">
          <ShoppingCart size={30} className="mx-auto stroke-1 text-tertiary" />
        </div>
        <div className="col-span-10">
          {allTime ? (
            <div>
              <h1 className="text-base xl:text-lg">
                Total Orders : {allTime.allTimeOrders}
              </h1>
              {/* <h1>{allTime.allTimeOrders}</h1> */}
            </div>
          ) : (
            <p>No orders found</p>
          )}
        </div>
      </Card>
    </div>
  );
};

export default OrderCards;
