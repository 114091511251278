import * as React from "react";
import { Separator } from "@radix-ui/react-select";

const AiRobotics = () => {
  return (
    <React.Fragment>
      <main>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5 font-sans">
          {/* Image Section */}
          <div className="flex items-center justify-center xl:p-8">
            <img
              src="./Imagefolder/Images/AirRobotics_Pintrest.jpg"
              alt="AI & Robotics"
              className="rounded-xl shadow-lg shadow-primary"
            />
          </div>

          {/* Text Section */}
          <div className="gap-y-5 xl:py-20 grid place-items-center md:place-content-start">
            <h1 className="text-primary text-lg md:text-2xl lg:text-3xl text-center font-bold leading-snug font-sans">
              Robotics & STEM Labs for Schools
            </h1>
            <Separator className="bg-secondary w-20 h-1" />
            <h3 className="font-bold text-center">
              300+ Robotics and STEM Labs Established
            </h3>
            <p className="text-base md:text-center lg:text-lg text-center cursor-not-allowed leading-snug font-sans">
              ICE Robotics Robotics and STEM Labs are enhancing ICT labs in
              schools by integrating hands-on robotics, coding, and engineering
              education into the curriculum for classes 3-12. Students engage in
              project-based learning, gaining essential skills to thrive in a
              technology-driven world.
            </p>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default AiRobotics;
