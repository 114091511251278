import React from "react";

const holidaysData = [
    { date: "July 1, 2024", name: "Canada Day" },
    { date: "August 5, 2024", name: "Civic Holiday" },
];

const HolidaysTable = () => {
    return (
        <React.Fragment>
            <div className="grid">
                <table className="table-auto border-collapse border text-center mb-5 w-full">
                    <thead>
                        <tr className="bg-[#FFD580] text-base font-sans">
                            <th className="border text-base leading-snug px-4 py-2 font-sans">Holidays</th>
                        </tr>
                    </thead>
                    <tbody>
                        {holidaysData.map((holiday, index) => (
                            <tr key={index} className="hover:bg-lightblue">
                                <td className="border text-base leading-snug px-4 py-2 font-sans">{holiday.date}</td>
                                <td className="border text-base leading-snug px-4 py-2 font-sans">{holiday.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </React.Fragment>
    );
};

export default HolidaysTable;
