import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "../../../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../ui/form";
import { Input } from "../../../ui/Input";
import React from "react";
import { Card } from "../../../ui/Card";
import actions from "../../../../actions";

const FormSchema = z.object({
  cost: z.string().min(1, {
    message: "cost must be at least 1 characters in number.",
  }),
  time: z.string().min(1, {
    message: "time must be at least 1 characters in number.",
  }),
  age: z.string().min(1, {
    message: "age must be at least 1 characters in number.",
  }),
});

type AddTrialProps = {
  handleNew: () => void;
};

const AddTrial = ({ handleNew }: AddTrialProps) => {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      cost: "",
      time: "",
      age: "",
    },
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      const submit = await actions.POST_DATA({
        url: "campcoupontrail/add",
        payload: data,
      });
      if (submit.success === true) {
        form.reset();
      }
    } catch (error) {
    }
    handleNew();
  }

  return (
    <React.Fragment>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
          <Card className="p-5 border-none space-y-4 shadow-md shadow-primary">
            <h1 className="text-center xl:text-3xl md:text-2xl text-xl font-bold my-3 xl:my-5 ">
              Add Trial Class
            </h1>
            <FormField
              control={form.control}
              name="cost"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Cost</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter Cost" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="time"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Time</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter Time" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="age"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Age</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter Age" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" className="my-5 bg-tertiary text-white_100">
              Submit
            </Button>
          </Card>
        </form>
      </Form>
    </React.Fragment>
  );
};

export default AddTrial;
