import React from 'react';
import LineChart from './LineChart';
import BarChart from './BarChart';
import PieChart from './PolarArea';

const AnalyticsChats = () => {
    return (
        <div>
            <div className="grid lg:grid-cols-2 p-2 space-y-10 lg:space-y-0">
                <div className="space-y-10 lg:space-y-0">
                    <div className="md:p-5">
                        <h1 className="text-xl bg-[#95c2f0] text-black_100 rounded-lg w-fit px-2 font-semibold ">Line Chart</h1>
                        <h1 className='font-bold text-[#00407d] '>Monthly Revenue</h1>
                        <LineChart />
                    </div>
                    <div className='md:p-5'>
                        <h1 className="text-xl bg-[#95c2f0] text-black_100 rounded-lg w-fit px-2 font-semibold ">Bar Chart</h1>
                        <h1 className=' font-bold text-[#00407d] '>Yearly Revenue </h1>
                        <BarChart />
                    </div>
                </div>
                <div className="">
                    <div className='md:p-5'>
                        <h1 className="text-xl bg-[#95c2f0] text-black_100 rounded-lg w-fit px-2 font-semibold ">Polar Chart</h1>
                        <h2 className='font-bold text-[#00407d] '>Top Performing Products</h2>
                        <PieChart/>             
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AnalyticsChats;
