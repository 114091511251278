import React from "react";
import BreakForm from "./BreakForm";
import BreakFormInfo from "./BreakFormInfo";
import { Label } from "../../../ui/Label";
import BookNow from "./BookNow";
import Activity from "./Activity";
import RoadmapLink from "./RoadMapLink";
import Ice from "../WinterCamps/Ice";

const Break = () => {
  return (
    <React.Fragment>
      <main className="lg:max-w-screen-2xl w-full mx-auto m-5 p-5">
      <div
        className="bg-cover relative h-fit  grid place-items-center"
        style={{ backgroundImage: `url(Imagefolder/Images/marchBreak.jpg)` }}
      >
        <div className="absolute bg-black_100/60 top-0 w-full h-full"></div>
        <section className="grid grid-cols-1 lg:grid-cols-2 w-10/12 gap-6 z-10 m-10">
          <div>
            <Label className="text-xl font-bold text-white_100">
              Fill out the details to get a call back.
            </Label>
            <BreakForm />
          </div>
          <aside className="text-center place-content-center">
            <BreakFormInfo />
          </aside>
        </section>
      </div>
      <div className="my-5">
        <Activity />
      </div>
      <div className="my-5">
        <RoadmapLink />
      </div>
      <div className="my-5" id="booknow">
        <BookNow />
      </div>
      <div className="my-5">
        <Ice />
      </div>
      </main>
    </React.Fragment>
  );
};

export default Break;
