import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { Button } from "../../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { Input } from "../../ui/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/Select";
import { Textarea } from "../../ui/Textarea";
import actions from "../../../actions";
import PopUp from "../PopUp";

const FormSchema = z.object({
  studentFName: z.string().min(2, {
    message: "First Name must be at least 2 characters.",
  }),
  studentLName: z.string().min(2, {
    message: "Last Name must be at least 2 characters.",
  }),
  dob: z.string().refine(
    (value) => {
      const date = new Date(value);
      return !isNaN(date.getTime()); 
    },
    {
      message: "A valid date of birth is required.",
    }
  ),
  gender: z.enum(["male", "female", "other"], {
    required_error: "A gender is required.",
  }),
  address: z
    .string()
    .min(10, {
      message: "Address must be at least 10 characters.",
    })
    .max(160, {
      message: "Address must not be longer than 160 characters.",
    }),
  program: z.string().min(2, {
    message: "A program is required",
  }),
  guardianFName: z.string().min(2, {
    message: "First Name must be at least 2 characters.",
  }),
  guardianLName: z.string().min(2, {
    message: "Last Name must be at least 2 characters.",
  }),
  guardianPhone: z.string().regex(/^\d{10,11}$/, {
    message:
      "Phone number must be between 10 and 11 digits and contain only numbers.",
  }),
  emergencyContactLName: z.string().min(2, {
    message: "First Name must be at least 2 characters.",
  }),
  emergencyContactFName: z.string().min(2, {
    message: "Last Name must be at least 2 characters.",
  }),
  relation: z.string().min(2, {
    message: "Relation must be defined",
  }),
  emergencyContactPhone: z.string().regex(/^\d{10,11}$/, {
    message:
      "Phone number must be between 10 and 11 digits and contain only numbers.",
  }),
});
type Props = {
  success: boolean;
  fail: boolean;
};

const RegistrationForm = () => {
  const [pop, setPop] = React.useState<Props>({
    success: false,
    fail: false,
  });

  const [selectedDate, setSelectedDate] = React.useState<Date | undefined>(
    new Date()
  );

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      studentFName: "",
      studentLName: "",
      dob: new Date().toISOString().split("T")[0], 
      gender: undefined,
      address: "",
      program: "",
      guardianFName: "",
      guardianLName: "",
      guardianPhone: "",
      emergencyContactFName: "",
      emergencyContactLName: "",
      relation: "",
      emergencyContactPhone: "",
    },
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      const submit = await actions.POST_DATA({
        url: "registerStudents/add",
        payload: { ...data, dob: selectedDate },
      });

      if (submit.success === true) {
        form.reset();
        setPop({ success: true, fail: false });
      }
    } catch (error) {
      setPop({ success: false, fail: true });
    }
  }

  const handleNumericInput = (e: React.FormEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value.replace(/\D/g, "");
    e.currentTarget.value = input.slice(0, 11);
  };

  return (
    <React.Fragment>
      {pop.success && (
        <PopUp heading="Success" description="Message Successfully Sent " />
      )}
      {pop.fail && <PopUp heading="Error" description="Message Not Sent " />}

      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="w-full text-white_100 space-y-2 p-5  "
        >
          <h1 className="text-xl font-semibold tracking-wider">Student Info</h1>
          <section className="grid sm:grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="studentFName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input placeholder="First Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="studentLName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Last Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>
          <section className="grid sm:grid-cols-3 gap-4">
            <FormField
              control={form.control}
              name="gender"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Gender</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="bg-white_100 text-gray_900/60">
                        <SelectValue placeholder="Select a Gender" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="male">Male</SelectItem>
                      <SelectItem value="female">Female</SelectItem>
                      <SelectItem value="other">Other</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="dob"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Date of Birth</FormLabel>
                  <FormControl>
                    <Input
                      className="text-gray_900 w-full grid gap-10"
                      type="date"
                      placeholder="Date of Birth"
                      onChange={(e) => {
                        const dateValue = e.target.value;
                        field.onChange(dateValue); 
                        setSelectedDate(new Date(dateValue)); 
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="program"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Program</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="bg-white_100 text-gray_900/60">
                        <SelectValue placeholder="Select a Program" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="tera">Tera Ice</SelectItem>
                      <SelectItem value="peta">Peta Ice</SelectItem>
                      <SelectItem value="exa">Exa Ice</SelectItem>
                      <SelectItem value="code">Code & Web Design</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>
          <FormField
            control={form.control}
            name="address"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Address</FormLabel>
                <FormControl>
                  <Textarea placeholder="Address" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <h1 className="text-xl font-semibold tracking-wider sm:pt-10">
            Parent/Guardian Info
          </h1>
          <section className="grid sm:grid-cols-3 gap-4">
            <FormField
              control={form.control}
              name="guardianFName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input placeholder="First Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="guardianLName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Last Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="guardianPhone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Phone Number</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Phone Number"
                      {...field}
                      onInput={handleNumericInput}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>
          <h1 className="text-xl font-semibold tracking-wider">
            Emergency Contact Info
          </h1>
          <section className="grid sm:grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="emergencyContactFName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>First Name</FormLabel>
                  <FormControl>
                    <Input placeholder="First Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="emergencyContactLName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Last Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Last Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>
          <section className="grid sm:grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="relation"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Relation</FormLabel>
                  <FormControl>
                    <Input placeholder="Relation" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="emergencyContactPhone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Phone</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="Phone"
                      {...field}
                      onInput={handleNumericInput}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>
          <Button type="submit" className=" mt-4">
            Submit
          </Button>
        </form>
      </Form>
    </React.Fragment>
  );
};

export default RegistrationForm;
