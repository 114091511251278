import React from 'react'
import SumCampForm_Info from './SumCampForm_Info'
import SummerCampInfo from './SummerCampInfo'
import WeeklyTable from './WeeklyTable'
import HolidaysTable from './HolidaysTable'
import CampActivitiesTable from './CampActivitiesTable'
import FullDay_HalfDay_Activities from './FullDay_HalfDay_Activities'
import Testimonial from './Testimonial'
import CourseBook from './CourseBook'



const SummerCamp = () => {
    return (
        <React.Fragment>
            <main className='p-5 md:max-w-screen-2xl mx-auto'>
                <SumCampForm_Info />
                <SummerCampInfo />
                <WeeklyTable />
                <HolidaysTable />
                <CampActivitiesTable />
                <FullDay_HalfDay_Activities />
                <CourseBook />
                <Testimonial />
            </main>
        </React.Fragment>
    )
}

export default SummerCamp