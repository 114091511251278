import React from "react";

const WeeklyTable = () => {
    const data = [
        { week: 1, dateRange: "July 2 to July 5, 2024" },
        { week: 2, dateRange: "July 8 to July 12, 2024" },
        { week: 3, dateRange: "July 15 to July 19, 2024" },
    ];
    const mdata = [
        { week: 4, dateRange: "July 22 to July 26, 2024" },
        { week: 5, dateRange: "July 29 to August 2, 2024" },
        { week: 6, dateRange: "August 6 to August 9, 2024" },
    ];
    const ndata = [
        { week: 7, dateRange: "August 12 to August 16, 2024" },
        { week: 8, dateRange: "August 19 to August 23, 2024" },
        { week: 9, dateRange: "August 26 to August 30, 2024" },
    ];

    return (
        <React.Fragment>
            <div className="text-xl text-center mb-4 grid">
                <h1 className="md:text-2xl font-bold text-lg mb-5">Weekly Schedule</h1>
            </div>
            <div className="grid md:grid-cols-3 grid-cols-1 gap-2">
                <table className="table-auto border-collapse border text-center mb-5 w-full">
                    <thead>
                        <tr className="bg-[#FFD580] text-base font-sans">
                            <th className="border text-base leading-snug px-4 py-2 font-sans">WEEK</th>
                            <th className="border text-base leading-snug px-4 py-2 font-sans">DATE RANGE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((week, index) => (
                            <tr key={index} className="hover:bg-lightblue">
                                <td className="border text-base leading-snug px-4 py-2 font-sans">{week.week}</td>
                                <td className="border text-base leading-snug px-4 py-2 font-sans">{week.dateRange}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <table className="table-auto border-collapse border text-center mb-5 w-full">
                    <thead>
                        <tr className="bg-[#FFD580] text-base font-sans">
                            <th className="border text-base leading-snug px-4 py-2 font-sans">WEEK</th>
                            <th className="border text-base leading-snug px-4 py-2 font-sans">DATE RANGE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mdata.map((week, index) => (
                            <tr key={index} className="hover:bg-lightblue">
                                <td className="border text-base leading-snug px-4 py-2 font-sans">{week.week}</td>
                                <td className="border text-base leading-snug px-4 py-2 font-sans">{week.dateRange}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <table className="table-auto border-collapse border text-center mb-5 w-full">
                    <thead>
                        <tr className="bg-[#FFD580] text-base font-sans">
                            <th className="border text-base leading-snug px-4 py-2 font-sans">WEEK</th>
                            <th className="border text-base leading-snug px-4 py-2 font-sans">DATE RANGE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ndata.map((week, index) => (
                            <tr key={index} className="hover:bg-lightblue">
                                <td className="border text-base leading-snug px-4 py-2 font-sans">{week.week}</td>
                                <td className="border text-base leading-snug px-4 py-2 font-sans">{week.dateRange}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </React.Fragment>
    );
};

export default WeeklyTable;