import React from "react";
import FooterComp from "./FooterComp";

const Footer = () => {
  return (
    <React.Fragment>
      <main className="">
        <FooterComp/>
      </main>
    </React.Fragment>
  );
};

export default Footer;
