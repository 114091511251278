import React from "react";
import MobCart from "./MobCart";
import { loadStripe } from "@stripe/stripe-js"; // Load Stripe instance
import { Elements } from "@stripe/react-stripe-js"; // Import Elements provider
import TestCart from "./TestCart";

// Initialize Stripe instance with your publishable key
// const stripePromise = loadStripe("your-publishable-key-here");

const IndexCart = () => {
  return (
    <React.Fragment>
      {/* <Elements stripe={stripePromise}> */}
        <div>
          <div className="sm:hidden">
            <MobCart />
          </div>
          <div className="hidden sm:block">
            <TestCart />
            {/* <MobCart /> */}

            {/* <ViewCart/> */}
          </div>
        </div>
      {/* </Elements> */}
    </React.Fragment>
  );
};

export default IndexCart;
