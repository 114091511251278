import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Data from "../../../DB/NavBarData.json";
import { useLocation, Link } from "react-router-dom"; 
import { Card } from "../../ui/Card";

const NavBarItem = () => {
  const location = useLocation(); 
  const [hoveredMenuIndex, setHoveredMenuIndex] = useState<number | null>(null);
  const [hoveredCardIndex, setHoveredCardIndex] = useState<number | null>(null);

  const handleMenuMouseEnter = (index: number) => {
    setHoveredMenuIndex(index);
  };

  const handleCardMouseEnter = (index: number) => {
    setHoveredCardIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredMenuIndex(null);
    setHoveredCardIndex(null);
  };

  return (
    <React.Fragment>
      <main className="grid  w-full   place-items-center">
        <div className="p-2 justify-center place-content-center flex gap-3 xl:gap-6 2xl:gap-10 lg:gap-4">
          {Data.map((item, index) => {
            const { name, link, head } = item;
            const sub = head?.map((e) => e);

            return (
              <div
                key={index}
                className="relative"
                onMouseEnter={() => handleMenuMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <Link to={link}>
                  <h1
                    className={`${
                      location.pathname === link ? "text-tertiary font-extrabold" : ""
                    } lg:text-sm 2xl:text-base hover:text-tertiary cursor-pointer text-center font-semibold`}
                  >
                    {name}
                  </h1>
                </Link>
                <AnimatePresence>
                  {(hoveredMenuIndex === index || hoveredCardIndex === index) &&
                    head && (
                      <motion.div
                        className="absolute top-full -left-5 my-2 z-20"
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        onMouseEnter={() => handleCardMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <Card className="grid gap-3 z-10 bg-white_100 border p-5">
                          {sub?.map((item, subIndex) => (
                            <div key={subIndex}>
                              <Link to={item.link}>
                                <h1
                                  className={`${
                                    location.pathname === item.link
                                      ? "text-tertiary font-extrabold"
                                      : ""
                                  } whitespace-nowrap text-black_100 text-sm hover:font-bold hover:text-tertiary cursor-pointer`}
                                >
                                  {item.title}
                                </h1>
                              </Link>
                            </div>
                          ))}
                        </Card>
                      </motion.div>
                    )}
                </AnimatePresence>
              </div>
            );
          })}
        </div>
      </main>
    </React.Fragment>
  );
};

export default NavBarItem;
