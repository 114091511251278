import * as React from "react";
import AiRobotics from "./Ai&Robotics";
import AtalTinkering from "./AtalTinkering";
import ImpactProgram from "./ImpactPrograms";
import { Separator } from "@radix-ui/react-select";

const UpgradingEducation = () => {
  return (
    <React.Fragment>
      <main className="lg:max-w-screen-2xl w-full lg:mx-auto grid py-5 lg:p-20 gap-5 font-sans">
        <div className="grid place-items-center gap-5">
          <h1 className="text-lg sm:text-4xl text-center font-bold leading-snug text-primary">
            Upgrading Education with Forward-Thinking Initiatives
          </h1>
          <Separator className="bg-secondary w-40 h-1 mx-auto" />
          <span className="px-4 text-base lg:text-lg text-center leading-snug">
            ICE Robotics has tailored its products, curricula, and services to
            meet the needs of the general public through its innovative
            initiatives in{" "}
            <b>
              schools, government, corporate social responsibility, and impact
              programs
            </b>
            . These programs are designed to achieve desired outcomes and adhere
            to the educational standards established by modern policies around
            the world.
          </span>
        </div>

        <div className="grid gap-10 md:gap-0 p-5">
          <section>
            <AiRobotics />
          </section>
          <section className="my-5">
            <AtalTinkering />
          </section>
          <section className="my-5">
            <ImpactProgram />
          </section>
        </div>
      </main>
    </React.Fragment>
  );
};

export default UpgradingEducation;
