import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Card } from '../../ui/Card';
import { MoveLeft } from 'lucide-react';
import { Button } from '../../ui/button';

const DynamicComponent = () => {
    const [data, setData] = React.useState(null);
    const location = useLocation();
    const itemData = location.state?.itemData;
    React.useEffect(() => {
        if (itemData) {
            setData(itemData.data);
        }
    }, [itemData]);
    if (!data) {
        return <div>Loading...</div>;
    }
    return (
        <main className={`lg:max-w-screen-lg w-full mx-auto md:p-10 p-5 `}>
        <Card className={`p-5 lg:col-start-2 border rounded-3xl border-b-8 border-tertiary`}>
          <div className={`grid md:grid-cols-2 gap-5`}>
            <div className={`p-5`}>
              <img className={`h-full w-full mx-auto hover:transform hover:scale-110 hover:brightness-75 hover:duration-700  bg-primary rounded-xl`} src={data.image} alt={data.name} />
            </div>
            <div className={`leading-loose tracking-wide my-auto `}>
            <h2 className={`xl:text-xl line-clamp-2 md:text-lg text-xl xl:h-14 md:h-12 sm:h-14 text-primary  font-bold text-center`}>{data.name}</h2>
              <h2 className={`text-center font-medium text-base my-3 text-secondary `}>{data.subTitle}</h2>
              <h3 className={`text-center text-3xl font-bold`}>$ {data.price}</h3>
              <ul className={`list-disc  h-fit sm:px-5 px-3`}>
                {data.info.map((item:any, index:number) => {
                  return <li key={index}>{item}</li>;
                })}
              </ul>
            </div>
          </div>
          <div>
            <p className={`my-3 italic text-lg md:p-5 md:px-10 `}>{data.description}</p>
          </div>
          
          <div className={`md:px-10`}>
            <Link to="/shop">
              <Button className={`w-28 text-base bg-tertiary hover:bg-secondary/80 rounded-full text-white_100 `}>
                <MoveLeft size={35} />
              </Button>
            </Link>
          </div>
        </Card>
      </main>
    );
};
export default DynamicComponent;