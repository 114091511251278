import React from "react";
import TopicItem from "./TopicItem";
interface TopicListProps {
  isNew: boolean;
}

const TopicList = ({ isNew }: TopicListProps) => {
 
  return (
    <React.Fragment>
      <main>
        <TopicItem isNew={isNew} />
      </main>
    </React.Fragment>
  );
};

export default TopicList;
