import * as React from "react";
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ArrowUpDown, ChevronDown, MoveUpRight, Trash2 } from "lucide-react";

import { Button } from "../../../ui/button";
import { Checkbox } from "../../../ui/Checkbox";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../ui/dropdown-menu";
import { Input } from "../../../ui/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";
import actions from "../../../../actions";
import { ICategory, IProdData } from "../../../../../typings";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "../../../ui/dialog";
import { useNavigate } from "react-router-dom";
import HeadTabsProduct from "./HeadTabsProduct";

export function ProductList() {
  const navigate = useNavigate();

  const [dynamicRouteData, setDynamicRouteData] = React.useState<any | null>(
    null
  );

  const HandleRoute = async (id: string) => {
    try {
      const req = await fetch(
        `https://iceroboapi.pickupbiz.in/api/v1/items/uniq/${id}`
      );
      const res = await req.json();
      setDynamicRouteData(res);
      navigate("/dashboard/productDetails", { state: { itemData: res } });
    } catch (error) {
      console.error("Error fetching unique item:", error);
    }
  };
  const columns: ColumnDef<IProdData>[] = [
    {
      id: "select",
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected()
              ? true
              : table.getIsSomePageRowsSelected()
              ? "indeterminate"
              : false
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },

    {
      accessorKey: "image",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Product
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => (
        <div>
          <img
            src={row.getValue("image")}
            alt="Product Image"
            className="h-16 w-24 ml-4"
          />
        </div>
      ),
    },

    {
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Product Name
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => <div className="ml-4">{row.getValue("name")}</div>,
    },
    {
      accessorKey: "info",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Product Info
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => (
        <div className="ml-4 line-clamp-4">{row.getValue("info")}</div>
      ),
    },
    {
      accessorKey: "category",
      header: ({ column }) => {
        return (
          <Button
            variant="ghost"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Product category
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        );
      },
      cell: ({ row }) => (
        <div className="ml-5 capitalize">{row.getValue("category")}</div>
      ),
    },
    {
      accessorKey: "createdAt",
      header: "createdAt",
      cell: ({ row }) => {
        const dob = new Date(row.getValue("createdAt"));
        const formattedDate = `${dob.getDate()}/${
          dob.getMonth() + 1
        }/${dob.getFullYear()}`;

        return <div className="capitalize ml-1">{formattedDate}</div>;
      },
    },
    {
      header: "Delete",
      cell: ({ row }) => (
        <div className="capitalize">
          <Dialog>
            <DialogTrigger>
              <Button variant="outline" className="border-none">
                <Trash2 className="hover:text-red_100 size-6 " />
              </Button>
            </DialogTrigger>
            <DialogContent className="bg-white_100 w-5/6 sm:w-full">
              <DialogTitle>Confirm Delete</DialogTitle>
              <DialogDescription className="md:text-lg">
                Are you sure you want to delete this product?
              </DialogDescription>
              <div className="flex justify-end space-x-2 mt-6">
                <DialogClose asChild>
                  <Button className="text-white_100">Cancel</Button>
                </DialogClose>
                <DialogClose asChild>
                  <Button
                    className="bg-red_100 text-white_100"
                    onClick={() => {
                      deleteProduct(row.original._id);
                    }}
                  >
                    Delete
                  </Button>
                </DialogClose>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      ),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const product = row.original;
        return (
          <Button
            onClick={() => HandleRoute(product._id)}
            variant="ghost"
            className="h-8 w-8 p-0 border border-gray_200 "
          >
            <span className="sr-only">Open menu</span>
            <MoveUpRight className="size-6" />
          </Button>
        );
      },
    },
  ];

  const [data, setData] = React.useState<IProdData[]>([]);
  const [catMap, setCatMap] = React.useState<{ [key: string]: string }>({});

  const fetchCategory = async () => {
    const res = await actions.GET_DATA_TOKEN({ url: "items/categories/all" });
    const categoryMap: { [key: string]: string } = {};
    res.forEach((category: ICategory) => {
      categoryMap[category._id] = category.name;
    });
    setCatMap(categoryMap);
  };

  const fetchProductDetails = async () => {
    const res = await actions.GET_DATA({ url: "items/all" });
    const updatedData = res.map((item: IProdData) => ({
      ...item,
      category:
        typeof item.category === "string"
          ? catMap[item.category] || item.category
          : item.category,
    }));
    setData(updatedData);
  };

  React.useEffect(() => {
    fetchCategory();
  }, []);
  React.useEffect(() => {
    if (Object.keys(catMap).length > 0) {
      fetchProductDetails();
    }
  }, [catMap]);

  const deleteProduct = async (_id: string) => {
    try {
      const del = await actions.DELETE_DATA({
        url: `items/delete`,
        params: _id,
      });

      if (del.success === true) {
        fetchProductDetails();
      }
    } catch (error) {
      // console.error("Error deleting detail:", error);
    }
  };

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] = React.useState<
    VisibilityState
  >({});
  const [rowSelection, setRowSelection] = React.useState({});

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <HeadTabsProduct>
      <div className="w-full">
        <div className="flex items-center gap-4 py-4">
          <Input
            placeholder="Search Product"
            value={(table.getColumn("name")?.getFilterValue() as string) ?? ""}
            onChange={(event) =>
              table.getColumn("name")?.setFilterValue(event.target.value)
            }
            className="max-w-sm"
          />
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="ml-auto">
                Columns <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .map((column) => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) =>
                        column.toggleVisibility(!!value)
                      }
                    >
                      {column.id}
                    </DropdownMenuCheckboxItem>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div className="flex items-center justify-end space-x-2 py-4">
          <div className="flex-1 text-sm text-muted-foreground">
            {table.getFilteredSelectedRowModel().rows.length} of{" "}
            {table.getFilteredRowModel().rows.length} row(s) selected.
          </div>
          <div className="space-x-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              Previous
            </Button>
            <Button
              variant="outline"
              size="sm"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </HeadTabsProduct>
  );
}
