
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "../../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { Input } from "../../ui/Input";
import { Card } from "../../ui/Card";
import React from "react";
import { Eye, EyeOff, MoveLeft } from "lucide-react";
import actions from "../../../actions";
import PopUp from "../PopUp";
import { useNavigate } from "react-router-dom";

const FormSchema = z.object({
  name: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
  email: z.string().email({
    message: "Enter a valid email address",
  }),
  password: z.string().min(8, "Password must be at least 8 characters"),
  confirmPassword: z
    .string()
    .min(8, "Password must be at least 8 characters"),
}).refine((data) => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"],
});

type Props = {
  success: Boolean;
  fail: Boolean;
};

const SignUpPage = () => {
  const navigate = useNavigate();

  const [pop, setPop] = React.useState<Props>({
    success: false,
    fail: false,
  });

  const [passwordShown, setPasswordShown] = React.useState(false);
  const [cpasswordShown, setCpasswordShown] = React.useState(false);
  const [QRcode,setQRcode] = React.useState(null)

  const toggleCPasswordVisibility = () => {
    setCpasswordShown(!cpasswordShown);
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      const dataWithRole = { ...data, roles: "user" };
      const submit = await actions.POST_DATA({
        url: "auth/register",
        payload: dataWithRole,
      });
      if (submit.success === true) {
        form.reset();
        if(submit.qrCodeUrl){
          setQRcode(submit.qrCodeUrl)
        }
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setPop({
        success: false,
        fail: true,
      });
    }
  }

  return (
    <React.Fragment>
      {pop.success && (
        <PopUp heading="Success" description="Form Successfully Sent " />
      )}
      {pop.fail && <PopUp heading="Error" description="Form Not Sent " />}
      {
        !!QRcode ? <div>
        <img src={QRcode} alt="QRcode" className="h-80 w-80 " />
      </div> :
      <Form {...form}>
        <Card className="p-5  lg:max-w-screen-md w-[30rem] mx-auto mt-[10rem] ">
          <Button type="button" onClick={() => navigate("/login")}>
            <MoveLeft size={20} />
          </Button>
          <h1 className="text-4xl font-bold text-center my-3 text-primary ">
            Sign Up
          </h1>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 md:px-5">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-tertiary font-bold">Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-tertiary font-bold">E-mail</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter Email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-tertiary font-bold">Password</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Input
                        className="border rounded-lg"
                        type={passwordShown ? "text" : "password"}
                        placeholder="Enter Password"
                        {...field}
                      />
                      <span
                        className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                        onClick={togglePasswordVisibility}
                      >
                        {passwordShown ? (
                          <Eye className="text-xl text-tertiary" />
                        ) : (
                          <EyeOff className="text-xl text-tertiary" />
                        )}
                      </span>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-tertiary font-bold">Confirm Password</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Input
                        className="border rounded-lg"
                        type={cpasswordShown ? "text" : "password"}
                        placeholder="Enter Confirm Password Here ..."
                        {...field}
                      />
                      <span
                        className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                        onClick={toggleCPasswordVisibility}
                      >
                        {cpasswordShown ? (
                          <Eye className="text-xl text-tertiary" />
                        ) : (
                          <EyeOff className="text-xl text-tertiary" />
                        )}
                      </span>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div  className="grid grid-cols-2 gap-5">
            <Button className="text-white_100 font-bold text-xl" type="submit">
              Submit
            </Button>
            <Button
            type="button"
              onClick={()=>navigate("/login")}
              className=" text-white_100 font-bold text-xl"
              >
                Login
              </Button>
            </div>
          </form>
        </Card>
      </Form>
}
    </React.Fragment>
  );
};

export default SignUpPage;
