import React from "react";
import { Phone } from "lucide-react";
import { Link } from "react-router-dom";
const CardInfo = () => {
  return (
    <React.Fragment>
      <main className="space-y-5 sm:pt-10">
        <h1 className="sm:text-xl text-justify lg:text-2xl  text-white_100 leading-relaxed tracking-wide">
          Enter your contact information and we will call you to complete your
          booking.
        </h1>
        <h1 className="sm:text-xl text-justify lg:text-2xl  text-white_100 leading-relaxed tracking-wide">You may choose a Virtual Class or we could help you pick one.</h1>
        <h1 className="sm:text-xl text-justify lg:text-2xl  text-white_100 leading-relaxed tracking-wide">Need help? Give us a call at </h1>
        <section className="flex text-white_100 space-x-3 sm:text-xl lg:text-2xl leading-relaxed tracking-wide items-center">
          <Phone className="size-6 sm:size-7 md:size-8" />
          <Link to="tel:+1 416 619 4555">+1 416 619 4555</Link>{" "}
        </section>
      </main>
    </React.Fragment>
  );
};

export default CardInfo;
