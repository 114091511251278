import { Card, CardContent, CardTitle } from "../../../ui/Card";
import { ICampCoupons, ITrail } from "../../../../../typings";
import React, { useEffect, useState } from "react";

const ConsultComp = () => {
  const [data, setData] = useState<ITrail[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCouponsTrial = async () => {
      try {
        const response = await fetch(
          "https://iceroboapi.pickupbiz.in/api/v1/campcoupontrail/all"
        );

        if (response.ok) {
          const data = await response.json();
          setData(data.data);
        }
      } catch (error: any) {
        setError(error.message);
      }
    };
    fetchCouponsTrial();
  }, []);

  return (
    <React.Fragment>
      <main className=" grid place-items-center ">
        <Card className="rounded-tl-3xl rounded-br-3xl p-3 sm:p-5 m-3 sm:mx-5 border-tertiary/45 shadow-lg shadow-tertiary/55">
          <CardTitle className=" text-center uppercase text-2xl md:text-3xl font-semibold">
            Trial Class
          </CardTitle>
          <CardContent>
            {data.map((item, index) => {
              return (
                <div key={index} className="text-center text-sm space-y-2">
                  <p>
                    <span>Cost : </span> ${item.cost}*{" "}
                  </p>
                  <p>
                    <span>Timing : </span> {item.time} Hour Session{" "}
                  </p>
                  <p>
                    <span>Age Group : </span> {item.age} Years{" "}
                  </p>
                  <p>Objective: Understanding class operations</p>
                </div>
              );
            })}
          </CardContent>
        </Card>
      </main>
    </React.Fragment>
  );
};

export default ConsultComp;
