import * as React from "react"
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "../../ui/Navigationmenu"
import { cn } from "../../lib/utils"
const data=[
            {
                "stage":"LEGO WeDo Adv 2.0"
            },
            {
                "stage":"Simple & Powered Machines"
            },
            {
                "stage":"Renewable Energy"
            },
            {
                "stage":"LEGO Pneumatics"
            },
        ]

export function Level1() {
  return (
    <NavigationMenu className="relative -left-1">
      <NavigationMenuList>
        <NavigationMenuItem>
        <div className="">
        <NavigationMenuTrigger  className={`h-[8rem] w-[8rem] border-2 rotate-45 rounded-xl place-content-center text-center bg-gradient-to-tr from-primary`}>
          <div>
          <h1 className='-rotate-45 font-bold text-xl'>Level 1</h1> 
            <h1 className="font-bold relative left-5 -rotate-45 ">Lego</h1>
          </div>
        </NavigationMenuTrigger>
        </div>
          <NavigationMenuContent className="p-5 space-y-5 bg-white_100 w-72">
          {
                data.map((item,index)=>
                <>
                <div className="space-y-5">
                    <h1 className="font-serif text-xl font-bold lg:w-72 text-left">{index+1} . {item.stage}</h1>
                </div>
                </>
                )
            }
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  )
}

const ListItem = React.forwardRef<
  React.ElementRef<"a">,
  React.ComponentPropsWithoutRef<"a">
>(({ className, title, children, ...props }, ref) => {
  return (
    <li>
      <NavigationMenuLink asChild>
        <a
          ref={ref}
          className={cn(
            "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
            className
          )}
          {...props}
        >
          <div className="text-sm font-medium leading-none">{title}</div>
          <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">
            {children}
          </p>
        </a>
      </NavigationMenuLink>
    </li>
  )
})
ListItem.displayName = "ListItem"
