import * as React from "react";
import { Card } from "../../ui/Card";

const Award = () => {
  const awardsData = [
    { imageUrl: "./Imagefolder/Images/ci1.png", text: "Best Free Education Software" },
    { imageUrl: "./Imagefolder/Images/ci1.png", text: "XR Startup Program Grand Challenge Among Top 4 Startups" },
    { imageUrl: "./Imagefolder/Images/ci1.png", text: "TGS100 Emerging 100 Startups Globally" },
    { imageUrl: "./Imagefolder/Images/ci1.png", text: "Dynamic use of Artificial Intelligence in Education" },
  ];

  return (

    <React.Fragment>
      <main className="w-full grid place-items-center ">
        <div className="grid place-items-center sm:mt-8 ">
          <h1 className="text-2xl sm:text-4xl text-center font-bold my-6 sm:m-6 text-primary">Awards and Recognitions</h1>
          <hr className="w-28 bg-secondary text-white_100 h-1.5" />
          <p className="text-base md:text-lg leading-relaxed p-2 sm:p-5 w-full text-justify">
            Leading the Way, our impact is recognized globally with Prestigious
            Awards and Accolades.
          </p>
        </div>
        <div className="grid xl:grid-cols-4 sm:grid-cols-2 grid-cols-2 gap-2 sm:gap-10">
          {awardsData.map((item, index) => (
            <div key={index} className="">
              <div className="relative rounded-xl top-10 ">
                <img
                  src={item.imageUrl}
                  alt="Award Image"
                  className="h-32 sm:h-40 w-32 sm:w-40 object-cover border-2 border-secondary rounded-full"
                />
              </div>
              <Card className=" bg-secondary rounded-xl h-32 sm:h-40 w-32 sm:w-40 text-base grid text-center px-[0.30rem] mt-4 place-content-center text-white_100">{item.text}</Card>
            </div>
          ))}
        </div>
      </main>
    </React.Fragment>
  );
};

export default Award;