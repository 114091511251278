import { Phone } from "lucide-react";
import * as React from "react";
import NavBarItem from "./NavBarItem";
import UserAccount from "./UserAccount";
import CartSidebar from "../VirtualClasses/CartSideBar";
import { useNavigate } from "react-router-dom";

const NavBarInfo = () => {
  const  navigate = useNavigate()  


  return (
    <React.Fragment>
      <main className="grid grid-cols-12 px-1">
        <div
          onClick={() => navigate("/")}
          className="md:col-span-1 lg:col-span-2 p-2"
        >
          <img
            className=" lg:p-4 cursor-pointer 2xl:h-24 mx-auto"
            src="http://res.cloudinary.com/dn49bytnn/image/upload/v1730974340/gdqa059pbzoj7enxjdrn.png"
            alt="Ice Robotic Logo"
          />
        </div>
        <div className="lg:col-span-8 md:col-span-9 place-content-center">
          <NavBarItem />
        </div>
        <div className="flex text-white_100  py-4 xl:col-span-2 md:col-span-2 gap-2 xl:gap-5  justify-end">
          <div className="flex flex-col items-center gap-3 p-2 2xl:p-5  cursor-pointer">
            <a href="tel:(416) 619-4555" className=" p-0">
              <Phone size={20} className="mx-auto " />
            </a>
            <h1 className="font-semibold text-sm m-0">Call</h1>
          </div>

          <div className="grid gap-2 p-2 2xl:p-5 cursor-pointer">
            <CartSidebar />
          </div>
          <div className="grid gap-2 p-2 2xl:p-5 cursor-pointer">
            <UserAccount />
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default NavBarInfo;
