"use client";
import React, { useState, useEffect } from "react";
import { Minus, Plus, X } from "lucide-react";
import { Input } from "../../ui/Input";
import { Button } from "../../ui/button";
import actions from "../../../actions";
import { ICartItem } from "../../../../typings";
import BASE_URL from "../../../config";
import Cookies from "js-cookie";
import { useData } from "../../lib/DataContext";

import { loadStripe } from "@stripe/stripe-js";

import { useNavigate } from "react-router-dom";

const TestCart = () => {
  const [cartItem, setCartItem] = useState<ICartItem | null>(null);
  const [coupon, setCoupon] = React.useState<string>("");
  const { fetchData } = useData();
  const fetchCart = async () => {
    const res = await actions.GET_DATA_TOKEN({ url: "cart/all" });
    setCartItem(res);
  };

  const [stripePromise, setStripePromise] = useState<any>(() => {});

  let quantity: number = 0;

    useEffect(() => {
      const key =
      // "pk_test_51PkOigRq8CRKmkutpGzL27MCxjM0k8lpVjrxIMRFp8ZiWJuGAGkC1Iy4MadqJ2VG7PxPTK4eYqt4CEicXMKlN6OO00Hrceb92p";
        "pk_live_51Pe14q2LTAqtPUxh8WsrMJw4j6bN32cPreUU200hvV0cVk6Q4B7Ma18JXAg9GP5JuizrwvSMfavTCI9ZtKJ1HdoS00vetGE5f8";      
      // console.log(key, "key");
      if (key) {
        const load = async () => {
          const stripePromise = await loadStripe(key);
          // console.log("key", stripePromise);
          setStripePromise(stripePromise);
        };
        load();
      }
    }, []);

  const addToCart = async (id: string) => {
    quantity++;
    const payload = {
      itemId: id,
      quantity: quantity,
    };
    try {
      const add = await actions.POST_DATA_TOKEN({ url: "cart/add", payload });
      if (add.success === true) {
        fetchCart();
        fetchData();
      } else {
        alert("failed to add item ");
        window.location.reload();
      }
    } catch (error) { }
  };

  const decreaseCart = async (id: string) => {
    const payload = {
      itemId: id,
      quantity: 1,
    };
    try {
      const add = await actions.POST_DATA_TOKEN({
        url: "cart/remove",
        payload,
      });
      if (add.success === true) {
        fetchData();
        fetchCart();
      } else {
        window.location.reload();
      }
    } catch (error) {
    }
  };

  const removeCouponCode = async () => {
    try {
      const res = await actions.GET_DATA_TOKEN({ url: "cart/removeCoupon" });
      if (res) {
        fetchData();
        fetchCart();
      }
    } catch (error) {
    }
  };

  const applyCoupon = async () => {
    const payload = {
      name: coupon,
    };
    try {
      const add = await actions.POST_DATA_TOKEN({
        url: "cart/applyCoupon",
        payload,
      });
      if (add.success === true) {
        setCoupon("");
        fetchData();
        fetchCart();
      }
    } catch (error) {
    }
  };

  const removeCart = async (id: string) => {
    try {
      const token = Cookies.get("token");
      const remove = await fetch(`${BASE_URL}/cart/delete?itemId=${id}`, {
        method: "DELETE",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "x-access-token": `${token}`,
        },
      });
      const res = await remove.json();

      if (res.success === true) {
        fetchCart();
      } else {
        alert("failed to remove item ");
        window.location.reload();
      }
      if (remove.status === 200) {
        fetchData();
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchCart();
  }, []);

  //   const router = useRouter();
  const [cartOpen, setCartOpen] = useState(true);
  const [cartItems, setCartItems] = useState([
    { id: 1, name: "Product 1", price: 10, quantity: 2 },
    { id: 2, name: "Product 2", price: 15, quantity: 1 },
    { id: 3, name: "Product 3", price: 20, quantity: 3 },
  ]);

  const [discount, setDiscount] = useState(0);

  // const handleCheckout = () => {
  //   const isAuthenticated = true;
  //   if (isAuthenticated) {
  //     navigate("/checkout");
  //   } else {
  //     navigate("/login");
  //   }
  // };

  // const handleCheckout = async () => {
  //   // Get the total amount for all items in the cart
  //   const totalAmount = cartItem?.total || 0; // Assuming total is already calculated in your backend

  //   // Call your backend to create a Stripe Payment Link
  //   try {
  //     const res = await fetch("https://checkout.stripe.com/pay/cs_test_a1LCz7l1hOQ6LrUYcyQ4IoQOCs0MuXxB38YwGB9XDdki4JmGQzRny8iXyD", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         amount: totalAmount, // Send total amount for cart items
  //         currency: "usd", // Adjust currency if necessary
  //       }),
  //     });

  //     const data = await res.json();

  //     if (data.success && data.paymentLinkUrl) {
  //       // Redirect to Stripe Payment Link
  //       window.location.href = data.paymentLinkUrl;
  //     } else {
  //       alert("Failed to create payment link");
  //     }
  //   } catch (error) {
  //     console.error("Error during checkout:", error);
  //     alert("An error occurred during checkout");
  //   }
  // };

  const handleCheckout = async () => {
  try {
    const stripe = await stripePromise;

    if (!stripe) {
      console.error("Stripe failed to initialize");
      return;
    }

    const body = {
      line_items: cartItem?.items.map((item) => ({
        price_data: {
          currency: "cad",
          product_data: { name: item.name },
          unit_amount: item.price * 100,
        },
        quantity: item.quantity,
      })) || [],
    };
    const token=localStorage.getItem('accesstoken')
    const response = await fetch('https://iceroboapi.pickupbiz.in/api/v1/cart/order/checkout', {
      method: "POST",
      headers: { "Content-Type": "application/json","x-access-token":token },
      body: JSON.stringify(body),

    });

    if (!response.ok) {
      const errorDetails = await response.text();
      console.error(`Error: ${response.status} - ${errorDetails}`);
      return;
    }
    const session = await response.json();
    window.location.href = session.session_url;

  } catch (error) {
    console.error("Checkout error:", error);
  }
};

  const calculateSubtotal = (item: {
    id?: number;
    name?: string;
    price: any;
    quantity: any;
  }) => {
    return item.price * item.quantity;
  };

  // const applyCoupon = () => {
  //   let appliedDiscount = 0;
  //   switch (coupon) {
  //     case "SAVE10":
  //       appliedDiscount = 10;
  //       break;
  //     case "50OFF":
  //       appliedDiscount = 50;
  //       break;
  //     // Add more cases for additional coupons
  //     default:
  //       break;
  //   }
  //   setDiscount(appliedDiscount);
  // };

  const removeCoupon = () => {
    setCoupon("");
    setDiscount(0);
  };

  return (
    <div className=" ">
      <h1 className="text-2xl font-bold mb-4">Your Cart</h1>
      {cartOpen && (
        <div className="grid gap-4">
          {cartItem && cartItem.items.length > 0 ? (
            <>
              <div className="border rounded-md overflow-y-scroll text-base">
                <table className="w-full">
                  <thead className="rounded-md sticky top-0">
                    <tr className="bg-tertiary text-white_100 grid grid-cols-12 ">
                      <th className="py-2 col-span-1">Sr.</th>
                      <th className="py-2 col-span-4">Name</th>
                      <th className="py-2 col-span-2">Price</th>
                      <th className="py-2 col-span-2">Quantity</th>
                      <th className="py-2 col-span-2">SubTotal</th>
                      <th className="py-2 col-span-1"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {cartItem.items.map((item,index) => (
                      <tr key={item._id} className="text-center grid grid-cols-12 justify-center items-center ">
                        <td className="py-1.5 col-span-1">{index+1}</td>
                        <td className="text-start py-1.5 col-span-4 text-sm">{item.name}</td>
                        <td className="py-1.5 col-span-2">$ {item.price}</td>
                        <td className="py-0 col-span-2 flex items-center justify-center">
                          <Button
                            variant={"ghost"}
                            className="hover:text-secondary text-primary text-center px-1"
                            onClick={() => decreaseCart(item.itemId)}
                          >
                            <Minus size={14} className="border rounded" />
                          </Button>
                          <span className="mx-1">{item.quantity}</span>
                          <Button
                            variant={"ghost"}
                            className="hover:text-secondary text-primary text-center px-1"
                            onClick={() => addToCart(item.itemId)}
                          >
                            <Plus size={14} className="border rounded" />
                          </Button>
                        </td>
                        <td className="py-1.5 col-span-2">
                          $ {calculateSubtotal(item)}
                        </td>
                        <td className="py-1.5 col-span-1">
                          <button
                            className="py-1 text-primary hover:text-secondary focus:outline-none grid align-middle"
                            onClick={() => removeCart(item.itemId)}
                          >
                            <X size={18}/>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="text-center text-xl font-semibold">
              Your cart is empty
            </div>
          )}
          <div className="w-full grid border p-4 rounded-md">
            <h2 className="text-lg font-bold mb-4">Apply Coupon</h2>
            <div className="grid grid-cols-3 gap-5">
              <div className="grid col-span-2">
                <Input
                  type="text"
                  value={coupon}
                  onChange={(e) => setCoupon(e.target.value)}
                  className="border border-gray-400 p-1 rounded-md w-full"
                  placeholder="Enter coupon code"
                />
              </div>
              <div className="flex justify-center md:justify-end">
                <button
                  className="bg-tertiary text-white_100 w-56 p-2 rounded focus:outline-none"
                  onClick={applyCoupon}
                >
                  Apply
                </button>
                {cartItem?.coupon?.name &&
                  cartItem.coupon.discount !== null && (
                    <Button
                      variant="ghost"
                      className="text-primary hover:text-secondary focus:outline-none"
                      onClick={removeCouponCode}
                    >
                      <X size={20} />
                    </Button>
                  )}
              </div>
            </div>
          </div>
          {cartItem?.coupon?.name && cartItem.coupon.discount !== null && (
            <div className="w-full grid border p-4 rounded-md  ">
              <h2 className="text-lg font-bold mb-4">Coupon Applied</h2>
              <div className="grid grid-cols-3 gap-5">
                <div className="grid col-span-2">
                  <p className="text-lg font-bold">{cartItem?.coupon?.name}</p>
                  <p className="text-lg font-bold">
                    {cartItem?.coupon?.discount * 100}%
                  </p>
                </div>
              </div>
            </div>
          )}
          <div className="w-full">
            <table className="bg- w-full">
              <thead>
                <tr className="bg-">
                  <th className="p-2 justify-start grid">Summary</th>
                  <th className="p-2"></th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b">
                  <td className="py-2 px-4">Total Items</td>
                  <td className="py-2 px-4">
                  <span>
                {cartItem
                  ? cartItem.items.reduce((acc, item) => acc + item.quantity, 0)
                  : 0}
              </span>
                  </td>
                </tr>
                <tr className="border-b">
                  <td className="py-2 px-4">Total Price</td>
                  <td className="py-2 px-4">${cartItem?.bill}</td>
                </tr>
                {discount > 0 && (
                  <tr className="border-b">
                    <td className="py-2 px-4">Discount</td>
                    <td className="py-2 px-4">
                      $
                      {cartItem
                        ? (
                          cartItem.items.reduce(
                            (acc, item) => acc + item.price * item.quantity,
                            0
                          ) *
                          (discount / 100)
                        ).toFixed(2)
                        : 0}
                    </td>
                  </tr>
                )}
                {cartItem?.coupon?.name &&
                  cartItem.coupon.discount !== null && (
                    <tr className="border-b">
                      <td className="py-2 px-4">Total Price after Discount</td>
                      <td className="py-2 px-4">${cartItem?.total}</td>
                    </tr>
                  )}
              </tbody>
            </table>
            <Button
              className="bg-tertiary text-white_100 py-2 px-4 rounded mt-4 focus:outline-none"
              onClick={handleCheckout}
            >
              Checkout
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TestCart;