import * as React from "react";
import { Card } from "../../ui/Card";

const CTest = () => {
  const data = [
    {
      img: "./Imagefolder/NewImages/kidwithrobo.jpg",
      name: "Stem (Science, Technology, Engineering, Math)",
    },
    {
      img: "./Imagefolder/NewImages/kiddesign.jpg",
      name: "Artificial Intelligence",
    },
    {
      img: "./Imagefolder/NewImages/roboarm_design.jpg",
      name: "AR & VR Tech",
    },
    {
      img: "./Imagefolder/NewImages/happykid.jpg",
      name: "Biomimetic Robot",
    },
    {
      img: "./Imagefolder/NewImages/robocar.jpg",
      name: "Coding - Graphical & Python",
    },
    {
      img: "./Imagefolder/NewImages/kiddesigning.jpg",
      name: "Internet of Things (IoT)",
    },
    {
      img: "./Imagefolder/NewImages/workingrobo.jpg",
      name: "Machine Learning",
    },
    {
      img: "./Imagefolder/NewImages/girlholdingrobo1.jpg",
      name: "Robotics",
    },
  ];

  return (
    <React.Fragment>
      <main className="max-w-screen-2xl h-fit mx-auto grid place-content-center">
        <div className="grid place-items-center mt-4 sm:mt-8">
          <h1 className="text-xl sm:text-4xl text-center font-bold mt-6 sm:m-6 text-primary">
            Empowering Kids with the Right Future Skill
          </h1>
          <hr className="w-28 bg-secondary h-1 m-2 rounded-lg" />
          <p className="text-base leading-relaxed text-center p-2 sm:p-5 text-gray-600">
            With the hands-on approach to Coding, Robotics, and STEM, we
            cultivate a generation of innovators by providing the 21st-century
            skills to unleash the true potential of young minds.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 sm:grid-cols-2 gap-6 p-4">
          {data.map((item, index) => {
            return (
              <div key={index} className="grid items-center transition-all hover:shadow-lg">
                <Card className="p-3 relative bg-white shadow-md rounded-lg hover:bg-gray-100">
                  <div className="w-full overflow-hidden">
                    <img
                      className="rounded-xl w-full hover:scale-110 transform transition-transform duration-700 ease-in-out"
                      src={item.img}
                      alt={item.name}
                      width={260}
                      height={210}
                    />
                  </div>
                  <div className="text-center mt-2">
                    <h2 className="font-sans text-center pb-3 text-base font-semibold text-gray-700">
                      {item.name}
                    </h2>
                  </div>
                </Card>
              </div>
            );
          })}
        </div>
      </main>
    </React.Fragment>
  );
};

export default CTest;