import React from "react";
import NavBarInfo from "./NavBarInfo";
import { DataProvider } from "../../lib/DataContext";
import BurgerMenu from "./BurgerMenu";

const NavBar = () => {
  return (
    <React.Fragment>
      <section className="bg-primary ">
        <main className="hidden pt-1  lg:block text-white_100">
          <DataProvider>
            <NavBarInfo />
          </DataProvider>
        </main>
        <div className="lg:hidden bg-primary text-white_100 w-full sticky top-0 z-[990]">
          <BurgerMenu />
        </div>
      </section>
    </React.Fragment>
  );
};

export default NavBar;
