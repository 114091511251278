import React from 'react'
import HowItem from './HowItem'

const HowItWork = () => {
  return (
    <React.Fragment>
        <main className="lg:max-w-screen-2xl w-full sm:px-3 mt-10 sm:mt-20 mx-auto">
            <div className='text-center'>
                <h1 className="text-center text-xl sm:text-4xl font-bold tracking-wider">HOW IT WORKS!!</h1>
            </div>
            <div className='sm:mt-5'>
                <HowItem/>
            </div>
            <div className="mt-10 md:mt-16 tracking-tighter">
                <a href="/roadMap">
                    <h1 className="text-tertiary hover:underline hover:underline-offset-4 text-base sm:text-4xl font-semibold text-center">
                        Navigate the future with our Robotics Roadmap: Click here to embark on a journey through cutting-edge innovation and transformative technology.
                    </h1>

                </a>
            </div>
        </main>
    </React.Fragment>
  )
}

export default HowItWork