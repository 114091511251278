import * as React from 'react';
import Genius from './Genius';
import { Separator } from '@radix-ui/react-select';

const OurGeniuses = () => {
  return (
    <React.Fragment>
      <main className="lg:max-w-screen-2xl w-full lg:mx-auto p-5 lg:p-20 grid gap-5">
        <div className="grid grid-cols-1 md:grid-cols-2 place-items-center gap-5">
          <div className="grid place-items-center xl:p-10">
            <Genius />
          </div>

          <div className="gap-y-5 grid place-items-center text-center">
            <h1 className="text-primary text-2xl sm:text-4xl font-bold">
              OUR GENIUSES AT WORK...!
            </h1>
            <Separator className="bg-secondary w-20 h-1" />

            <p className="text-tertiary text-xl sm:text-2xl font-bold">
              Take a peek inside a wonderworld
            </p>
            <p className="text-sm sm:text-base">
              A crucial step in teaching high school students how to plan to solve a problem in science, technology, engineering, and math is to teach them how to plan to solve…
            </p>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default OurGeniuses;
