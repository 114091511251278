import React from 'react'
import MiddleSection from '../Innovators/MiddleSection'
import Events from '../Innovators/Events'
import UpgradingEducation from '../upgradingEducation.tsx'
import FreeDemo from '../HomeFreedemo'
import CTest from '../robo/CTest'
import Award from '../robo/Award'
import OurGeniuses from '../OurGenius'
import Banner from '../Banner/Index'

const MainHome = () => {
  return (
    <React.Fragment>
        <Banner />
        <main className="lg:max-w-screen-2xl mx-auto p-2 m-5">
        <MiddleSection />
        <Events />
        <UpgradingEducation />
        <FreeDemo />
        <CTest />
        <Award />
        <OurGeniuses />
      </main>
    </React.Fragment>
  )
}

export default MainHome