"use client";
import React from "react";
import { Input } from "../../../ui/Input";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../ui/Select";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../ui/form";
import { Button } from "../../../ui/button";
// import styles from "../styles/MarchBreak.module.css";
import actions from "../../../../actions";
import PopUp from "../../PopUp";

// Define the form schema
const FormSchema = z.object({
  name: z.string().min(2, {
    message: "Enter your name",
  }),
  email: z.string().email({ message: "Invalid Email" }),
  city: z.string().min(2, {
    message: "Enter city name",
  }),
  phone: z
  .string()
  .regex(/^\d{10,11}$/, {
    message: "Phone number must be between 10 and 11 digits and contain only numbers.",
  }),
  campType: z.enum(["March Break"], {
    required_error: "A camp type is required.",
  }),
});
const handleNumericInput = (e: React.FormEvent<HTMLInputElement>) => {
  const input = e.currentTarget.value.replace(/\D/g, "");
  e.currentTarget.value = input.slice(0, 11); // Limits to 11 characters
};
type Props = {
  success: boolean;
  fail: boolean;
};

const BreakForm: React.FC = () => {
  const [pop, setPop] = React.useState<Props>({
    success: false,
    fail: false,
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
      email: "",
      city: "",
      // phone: "",
      campType: "March Break", 
    },
  });

    async function onSubmit(data: z.infer<typeof FormSchema>) {
    try {
      const submit = await actions.POST_DATA({
        url: "camp/add",
        payload: data,
      });

      if (submit.success === true) {
        form.reset();
        setPop({ success: true, fail: false });
      }
    } catch (error) {
    }
  }
  return (
    <React.Fragment>
      {pop.success && (
        <PopUp heading="Success" description="Message Successfully Sent" />
      )}
      {pop.fail && <PopUp heading="Error" description="Message Not Sent" />}
      <main className="mt-10">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className=" w-full xl:w-3/4 space-y-6 ">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-white_100 font-bold ">Full Name</FormLabel>
                  <FormControl>
                    <Input placeholder="FullName" {...field} />
                  </FormControl>
                  <FormMessage className="text-red_100" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-white_100 font-bold">Email</FormLabel>
                  <FormControl>
                    <Input placeholder="Email" {...field} />
                  </FormControl>
                  <FormMessage className="text-red_100" />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-white_100 font-bold">City</FormLabel>
                  <FormControl>
                    <Input placeholder="City" {...field} />
                  </FormControl>
                  <FormMessage className="text-red_100" />
                </FormItem>
              )}
            />
           <FormField
                  control={form.control}
                  name="phone"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="font-bold text-base text-white_100">Phone Number</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter Phone number"
                          {...field}
                          onInput={handleNumericInput} // Limits input to numbers
                        />
                      </FormControl>
                      <FormMessage className="text-red_100" />
                    </FormItem>
                  )}
                />
            <FormField
              control={form.control}
              name="campType"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-white_100 font-bold">March Break</FormLabel>
                  <FormControl>
                    <Select 
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      value={field.value}
                    >
                      <SelectTrigger >
                        <SelectValue placeholder="March Break" className="text-[#000000] " />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          <SelectLabel>March Break</SelectLabel>
                          <SelectItem value="March Break">March Break</SelectItem>
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage className="text-red_100" />
                </FormItem>
              )}
            />
            <Button type="submit" variant="outline" className="bg-secondary p-4 text-white_100 font-bold border-none flex mx-auto text-xl">
              Submit
            </Button>
          </form>
        </Form>
      </main>
    </React.Fragment>
  );
};

export default BreakForm;
