import React, { useEffect, useRef, useState } from "react";
import { Chart, ChartData, ChartOptions, registerables } from "chart.js";
import actions from "../../../../actions";
Chart.register(...registerables);

const PieChart: React.FC = () => {
  const [prodData, setProdData] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);

  const fetchLastMonthProd = async () => {
    try {
      const response = await actions.GET_CHART_DATA({url : "cart/revenue/last-month-by-product"})
      const result = response;
      // console.log(result, "lastmonthProduct");
      const revenues = result.map((item: any) =>
        typeof item.totalRevenue === "number" ? item.totalRevenue : 0
      );
      // console.log(revenues, "total last month revenue");
      const prodName = result.map((item: any) => item.productName);
      // console.log(prodName, "productName in Last month ");
      setProdData(revenues);
      setLabels(prodName);
    } catch (error) {
      console.error("Failed to fetch revenue data:", error);
    }
  };

  React.useEffect(() => {
    fetchLastMonthProd();
  }, []);

  const chartRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (chartRef.current && prodData.length > 0 && labels.length > 0) {
      if ((chartRef.current as any).chartInstance) {
        (chartRef.current as any).chartInstance.destroy();
      }

      const context = chartRef.current.getContext("2d");
      if (!context) return;
      const chartData: ChartData<"polarArea", number[], string> = {
        labels: labels,
        datasets: [
          {
            label: "$",
            data: prodData,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
            ],
            borderColor: [
              "rgba(255, 0, 54)",
              "rgba(0, 64, 125)",
              "rgba(255, 181, 0)",
              "rgba(0, 112, 113)",
              "rgba(85, 0, 255)",
            ],
            borderWidth: 1,
          },
        ],
      };

      const options: ChartOptions<"polarArea"> = {
        responsive: true,
        scales: {
          x: {},
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1000,
            },
          },
        },
        plugins: {
          legend: {
            labels: {
              usePointStyle: true, 
              pointStyle: 'rect', 
              boxWidth: 10,        
              boxHeight: 10,      
            },
          },
        },
      };

      const newChart = new Chart(context, {
        type: "polarArea",
        data: chartData,
        options,
      });

      (chartRef.current as any).chartInstance = newChart;
    }
  }, [prodData, labels]);

  return (
    <div>
      <div className="">
        <canvas ref={chartRef} />
      </div>
    </div>
  );
};

export default PieChart;
