import * as React from "react";
import AboutTop from "./AboutTop";
import AboutMid from "./AboutMid";
import AboutBottom from "./AboutBottom";

const About = () => {
  return (
    <React.Fragment>
      <main className="lg:max-w-screen-2xl w-full lg:mx-auto grid p-5 gap-3">
        <section className="relative bg-cover w-full h-fit grid place-items-center" style={{backgroundImage: `url(/Imagefolder/contact.jpg)`}} >
          <div className="absolute bg-black_100/80 top-0 w-full h-full" />
          <div>
            <AboutTop />
          </div>
        </section>
        <div>
          <AboutMid />
        </div>
        <div>
          <AboutBottom />
        </div>
      </main>
    </React.Fragment>
  );
};

export default About;
